/* Old Style */

//colors
$wild_sand: #f4f4f4;
$silver:  silver;
$color_shark_approx: #2b2828;
$color_pohutukawa_approx: #bd0217;
$color_log_cabin_approx: #222;
$color_tuatara_approx: #383839;
$color_concord_approx: #7c7c7c;
$color_mountain_mist_approx: #989898;
$color_mercury_approx: #e1e4e7;
$color_ghost_approx: #c5cbd0;
$color_gray_approx: #7f7f7f;
$white: #fff;
$color_athens_gray_approx: #ecedf1;
$concrete: #f2f2f2;
$black: #000;
$mercury: #e5e5e5;
$color_silver_sand_approx: #c2c2c2;
$color_alto_approx: #dadada;
$color_nobel_approx: #b2b2b2;
$color_black_haze_approx: #f7f7f7;
$color_ironside_gray_approx: #676767;
$black_12: rgba(0,0,0,.12);
$color_silver_approx: #bebebe;
$color_nevada_approx: #606b84;
$color_new_york_pink_approx: #df7b81;
$color_danube_approx: #63a7d7;
$color_sandal_approx: #ac9371;
$black_15: rgba(0,0,0,.15);
$black_5: rgba(0,0,0,.05);
$color_fuscous_gray_approx: #4f4f4f;
$black_3: rgba(0,0,0,.03);
$color_bon_jour_approx: #e2e2e2;
$color_spring_wood_approx: #f8f6ed;
$color_celeste_approx: #cdcdcd;
$color_horizon_approx: #558fa3;
$color_cornflower_approx: #98d0f1;
$color_tropical_blue_approx: #c3e0f2;
$gray:  gray;
$color_governor_bay_approx: #3432d1;
$color_pumice_approx: #c7c7c7;
$border: #D2D2D2;
H {}
//fonts
$font_0: Helvetica Neue;
$font_1: Segoe UI;
$font_2: sans-serif;
$font_3: serif;
$font_4: Helvetica Neue Regular;
$font_5: Arial;
$font_6: Helvetica Neue Bold;
$font_7: Helvetica Neue Semibold;
$font_8: Georgia;
$font_9: Times New Roman;
$font_10: Times;
$font_11: proxima-nova;
$font_12: als_rublregular;
$font_13: Open Sans Regular;
$font_14: Open Sans Bold;
$font_15: Helvetica Neue Test;


//urls
$url_0: url(../img/sprite.png);
$url_1: url(../img/basic/alpha.gif);
$url_2: url(../img/rating/star-red-full.svg);
$url_3: url(../img/rating/star-red-empty.svg);
$url_4: url(../img/rating/star-red-half.svg);
$url_5: url(../img/rating/star-black-full.svg);
$url_6: url(../img/rating/star-black-empty.svg);
$url_7: url(../img/rating/star-black-half.svg);
$url_8: url(../img/ulmarker.png);
$url_9: url(../img/plus.svg);
$url_10: url(../img/minus.svg);
$url_11: url(../img/arr.png);
$url_12: url(../img/arr-down.png);
$url_13: url(../img/arr-up.png);
$url_14: url(../img/basket.svg);
$url_15: url(../img/truck.png);
$url_16: url(../img/larr.png);
$url_17: url(../img/i-search.png);
$url_18: url(fonts/rouble-webfont.eot);
$url_19: url(fonts/rouble-webfont.eot?#iefix);
$url_20: url(fonts/rouble-webfont.woff);
$url_21: url(fonts/rouble-webfont.ttf);
$url_22: url(fonts/rouble-webfont.svg#als_rublregular);
$url_23: url(../img/rating.svg);
$url_24: url(../img/bg.png);
$url_25: url(../img/mul.svg);



@mixin rotate($val) {
  -webkit-transform: rotate($val);
  -moz-transform: rotate($val);
  -ms-transform: rotate($val);
  -o-transform: rotate($val);
  transform: rotate($val); }

//@extend-elements
//original selectors
//.clearfix:after, .clearfix:before
%extend_1 {
  display: table;
  content: "";
  line-height: 0; }


//original selectors
//a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video
%extend_2 {
  margin: 0;
  padding: 0;
  border: 0; }
//vertical-align: baseline
//font: inherit
//font-size: 100%


//original selectors
//sub, sup
%extend_3 {
  position: relative;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  font-size: 55%; }


//original selectors
//button, input, select, textarea
%extend_4 {
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
  font-size: 100%; }


//original selectors
//button, input[type=submit]
%extend_5 {
  *overflow: visible;
  //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
  border-radius: 0;
  cursor: pointer; }


//original selectors
//input[type=checkbox], input[type=radio]
%extend_6 {
  //If you use compass, instead of the line below you could use + box-sizing($bs)
  box-sizing: border-box;
  padding: 0;
  *width: 13px;
  *height: 13px;
  margin: 5px 0 0;
  line-height: normal; }


//original selectors
//h1, h2, h3, h4, h5, h6
%extend_7 {
  margin: 0 0 7px;
  color: $color_log_cabin_approx;
  font-weight: 700; }


//original selectors
//.grid:after, .grid:before
%extend_8 {
  display: table;
  content: "";
  line-height: 0; }


//original selectors
//.thumbnail-caption:after, .thumbnail-caption:before
%extend_9 {
  display: table;
  content: "";
  line-height: 0; }


//original selectors
//.btnNext, .btnPrev
%extend_10 {
  padding: 0;
  width: 14px;
  height: 16px;
  border: 0;
  background: transparent $url_0 no-repeat 0 0; }


//original selectors
//.btnNextBig, .btnPrevBig
%extend_11 {
  padding: 0;
  width: 44px;
  height: 44px;
  border: 0;
  background: transparent $url_0 no-repeat 0 0;
  background-position: 0 -90px;
  color: $white; }


//original selectors
//input[type=email], input[type=number], input[type=password], input[type=text], select, textarea
%extend_12 {
  display: inline-block;
  margin: 0;
  padding: 3px 10px;
  width: 184px;
  height: 23px;
  border: 1px solid $concrete;
  color: $black;
  font-family: $font_0, $font_1, $font_3;
  font-size: 13px;
  vertical-align: top;
  //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
  border-radius: 2px;
  //If you use compass, instead of the line below you could use + box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: inset 0 0 5px 0 $black_12; }


//original selectors
//.form-item.elastic input, .form-item.elastic select, .form-item.elastic textarea
%extend_13 {
  width: 100%;
  height: 31px;
  //If you use compass, instead of the line below you could use + box-sizing($bs)
  box-sizing: border-box; }


//original selectors
//.form-itemSelect .selectbox .trigger:after, .form-itemSelect .selectbox .trigger:before
%extend_14 {
  display: block;
  position: absolute;
  content: "";
  font-size: 1px;
  line-height: 0;
  text-indent: -3000px; }


//original selectors
//.form-itemRadio .form-label:after, .form-itemRadio .form-label:before
%extend_15 {
  position: absolute;
  content: "";
  //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
  border-radius: 50%; }


//original selectors
//.form-itemSlider .slider, .form-itemSlider .slider *
%extend_16 {
  display: block;
  cursor: default;
  //If you use compass, instead of the line below you could use + box-sizing($bs)
  box-sizing: border-box;
  //If you use compass, instead of the line below you could use + user-select($select)
  user-select: none; }


//original selectors
//.form-itemSlider .slider-scale li, .form-itemSlider .slider-scale li:first-child
%extend_17 {
  list-style: none;
  display: block;
  position: relative;
  float: left;
  border: 0;
  margin: 0;
  padding: 0;
  width: 25%;
  height: 12px;
  font: 10px/12px $font_4, $font_5, $font_3;
  text-align: right; }


//original selectors
//.form-itemSlider .slider-scale li:after, .form-itemSlider .slider-scale li:before, .form-itemSlider .slider-scale li:first-child:after, .form-itemSlider .slider-scale li:first-child:before
%extend_18 {
  display: block;
  position: absolute;
  bottom: -5px;
  right: 0;
  margin: 0 -1px 0 0;
  width: 2px;
  height: 2px;
  background: $black;
  content: "";
  font-size: 1px;
  line-height: 2px; }


//original selectors
//.form-itemSlider .slider-scale li:first-child span, .form-itemSlider .slider-scale li span
%extend_19 {
  display: block;
  position: absolute;
  z-index: 2;
  top: 0;
  right: -25px;
  width: 50px;
  text-align: center; }


//original selectors
//.paginator a, .paginator span
%extend_20 {
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid $color_mercury_approx;
  color: $color_mountain_mist_approx;
  line-height: 16px;
  text-align: center;
  text-decoration: none;
  //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
  border-radius: 16px; }


//original selectors
//.paginator .active a, .paginator a:hover
%extend_21 {
  border-color: $color_pohutukawa_approx;
  background-color: $color_pohutukawa_approx;
  color: $white!important; }


//original selectors
//.paginator .next a, .paginator .prev a
%extend_22 {
  width: 14px;
  height: 14px;
  border-color: transparent!important;
  background-color: transparent!important;
  background-image: $url_0;
  background-repeat: no-repeat; }


//original selectors
//.graphScale:before, .graphScale span
%extend_23 {
  display: block;
  height: 14px;
  //If you use compass, instead of the line below you could use + box-sizing($bs)
  box-sizing: border-box; }


//original selectors
//.blockWhite:after, .blockWhite:before
%extend_24 {
  display: block;
  position: absolute;
  bottom: -10px;
  width: 0;
  height: 0;
  border: solid;
  border-color: $color_mountain_mist_approx transparent transparent;
  content: ""; }


//original selectors
//.blockGray:after, .blockGray:before
%extend_25 {
  display: block;
  position: absolute;
  bottom: -10px;
  width: 0;
  height: 0;
  border: solid;
  border-color: $color_mountain_mist_approx transparent transparent;
  content: ""; }


//original selectors
//.blockProduct:after, .blockProduct:before
%extend_26 {
  display: table;
  content: "";
  line-height: 0; }


//original selectors
//.blockRating:after, .blockRating:before
%extend_27 {
  display: table;
  content: "";
  line-height: 0; }


//original selectors
//.blockRating td, .blockRating th
%extend_28 {
  padding: 8px 10px 7px;
  min-height: 18px;
  height: 18px;
  background: 0;
  border: solid $color_mercury_approx;
  border-width: 1px 1px 0 0;
  text-align: left;
  vertical-align: top;
  font-weight: 400; }


//original selectors
//td.blockRating-itemParams, th.blockRating-itemParams
%extend_29 {
  padding-right: 0;
  padding-left: 0;
  min-width: 23px;
  width: 23px;
  max-width: 23px;
  border-right-style: dotted;
  text-align: center; }


//original selectors
//td.blockRating-itemInfo, th.blockRating-itemInfo
%extend_30 {
  padding-top: 10px;
  padding-right: 3px;
  padding-left: 3px;
  min-width: 23px;
  border-right-style: dotted;
  font-size: 11px;
  text-align: center; }


//original selectors
//th.blockRating-itemInfo, th.blockRating-itemParams
%extend_31 {
  position: relative;
  padding-top: 0;
  padding-bottom: 10px;
  border-top: 0; }


//original selectors
//.teasersNarrow .teasers-meta >a, .teasersNarrow .teasers-meta >span
%extend_32 {
  display: none;
  margin: 0;
  width: 50%;
  vertical-align: top; }


//original selectors
//.starBlack, .starRed
%extend_33 {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 17px;
  height: 17px;
  background-repeat: no-repeat, no-repeat;
  background-size: 100% 100%, 0 0;
  text-indent: -9999px;
  font-size: 0;
  font-color: rgba(255,255,255,0);
  vertical-align: middle; }


//original selectors
//.sizeS >.starBlack, .sizeS >.starRed, .starBlack.sizeS, .starRed.sizeS
%extend_34 {
  width: 14px;
  height: 14px;
  background-size: 14px 14px; }


//original selectors
//.sizeL >.starBlack, .sizeL >.starRed, .starBlack.sizeL, .starRed.sizeL
%extend_35 {
  width: 21px;
  height: 21px;
  background-size: 21px 21px; }


//original selectors
//.popup, .popupBackground
%extend_36 {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }


//original selectors
//td.blockRating-itemPrice, th.blockRating-itemPrice
%extend_37 {
  padding-top: 10px;
  min-width: 47px;
  text-align: center; }


//original selectors
//.article-text ul li, .rich-content ul li
%extend_38 {
  list-style-type: none;
  line-height: 20px;
  margin: 0 0 0 24px;
  padding: 0 0 6px 0;
  list-style-image: $url_8; }


//original selectors
//.thumbnail-caption:after, .thumbnail-caption:before
@mixin extend_39 {
  display: table;
  content: "";
  line-height: 0; }


//original selectors
//.btnNext, .btnPrev
@mixin extend_40 {
  padding: 0;
  width: 14px;
  height: 16px;
  border: none;
  background: transparent $url_0 no-repeat 0 0; }


//original selectors
//.btnNextBig, .btnPrevBig
@mixin extend_41 {
  padding: 0;
  width: 46px;
  height: 46px;
  border: none;
  background: transparent $url_0 no-repeat 0 0;
  background-position: 0 -90px;
  color: $white;
  line-height: 44px; }



.clearfix {
  *zoom: 1;
  &:after {
    @extend %extend_1;
    clear: both; }

  &:before {
    @extend %extend_1; } }


a {
  @extend %extend_2;
  cursor: pointer;
  color: $color_pohutukawa_approx;
  text-decoration: underline;
  text-decoration: none;
  &:link {
    cursor: pointer; }

  &:visited {
    cursor: pointer; }

  &:active {
    outline: 0; }

  &:focus {
    outline: 0;
    text-decoration: none; }

  &:hover {
    outline: 0;
    text-decoration: none; }

  .icon {
    cursor: pointer; }

  .iconRating {
    margin-right: 4px; }

  .iconRefresh {
    margin: -2px 9px 0 0; } }


abbr {
  @extend %extend_2; }

acronym {
  @extend %extend_2; }

address {
  @extend %extend_2;
  font-style: normal; }

applet {
  @extend %extend_2; }

article {
  @extend %extend_2;
  display: block;
  &.static-content {
    margin-bottom: 150px; }

  ul {
    list-style-position: inside;
    list-style: none; } }


aside {
  @extend %extend_2;
  display: block; }

audio {
  @extend %extend_2; }

b {
  @extend %extend_2;
  font-weight: 700; }

big {
  @extend %extend_2; }

blockquote {
  @extend %extend_2;
  quotes: none;
  width: 175px;
  margin: 0 30px 15px 0;
  padding: 10px 0 0;
  position: relative;
  top: 6px;
  line-height: 16px;
  font-size: 12px;
  border-top: 4px solid $color_pohutukawa_approx;
  color: $color_concord_approx;
  &:after {
    content: '';
    content: none; }

  &:before {
    content: '';
    content: none; } }


canvas {
  @extend %extend_2; }

caption {
  @extend %extend_2; }

center {
  @extend %extend_2; }

cite {
  @extend %extend_2; }

code {
  @extend %extend_2; }

dd {
  @extend %extend_2; }

del {
  @extend %extend_2; }

details {
  @extend %extend_2;
  display: block; }

dfn {
  @extend %extend_2; }

div {
  @extend %extend_2; }

dl {
  @extend %extend_2; }

dt {
  @extend %extend_2; }

em {
  @extend %extend_2;
  font-style: italic; }

embed {
  @extend %extend_2; }

fieldset {
  @extend %extend_2;
  margin: 0 2px;
  padding: .35em .625em .75em;
  border: 1px solid $silver; }

figcaption {
  @extend %extend_2;
  display: block; }

figure {
  @extend %extend_2;
  display: block;
  margin: 0; }

footer {
  @extend %extend_2;
  display: block; }

form {
  @extend %extend_2;
  margin: 0; }

h1 {
  @extend %extend_2;
  @extend %extend_7;
  &.title {
    clear: both; } }


h2 {
  @extend %extend_2;
  @extend %extend_7;
  margin: 0 0 15px;
  font-size: 20px;
  font-weight: 700!important;
  + .teasers-item {
    padding-top: 0;
    border-top: 0; } }


h3 {
  @extend %extend_2;
  @extend %extend_7;
  color: $color_tuatara_approx;
  font-family: $font_0, $font_1, $font_3;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700!important; }

h4 {
  @extend %extend_2;
  @extend %extend_7;
  font-size: 12px;
  line-height: 17px; }

h5 {
  @extend %extend_2;
  @extend %extend_7;
  font-size: 10px;
  line-height: 16px; }

h6 {
  @extend %extend_2;
  @extend %extend_7;
  line-height: 16px;
  font-size: 9px; }

header {
  @extend %extend_2;
  display: block; }

hgroup {
  @extend %extend_2;
  display: block; }

html {
  @extend %extend_2;
  line-height: 1; }

i {
  @extend %extend_2;
  font-style: italic; }

iframe {
  @extend %extend_2; }

img {
  @extend %extend_2;
  max-width: 100%;
  height: auto;
  border: 0;
  vertical-align: top; }

ins {
  @extend %extend_2; }

kbd {
  @extend %extend_2; }

label {
  @extend %extend_2; }

legend {
  @extend %extend_2;
  *margin-left: -7px;
  padding: 0;
  border: 0;
  white-space: normal; }

li {
  @extend %extend_2; }

mark {
  @extend %extend_2;
  background: transparent;
  color: $color_mountain_mist_approx; }

menu {
  @extend %extend_2;
  display: block; }

nav {
  @extend %extend_2;
  display: block; }

object {
  @extend %extend_2; }

ol {
  @extend %extend_2;
  margin-bottom: 17px;
  list-style-position: inside; }

output {
  @extend %extend_2; }

p {
  @extend %extend_2;
  margin-bottom: 10px;
  line-height: 20px;
  + .title {
    padding-top: 16px;
    border-width: 1px 0; } }


pre {
  @extend %extend_2;
  margin: 0 0 13px; }

q {
  @extend %extend_2;
  quotes: none;
  &:after {
    content: '';
    content: none; }

  &:before {
    content: '';
    content: none; } }


ruby {
  @extend %extend_2; }

s {
  @extend %extend_2; }

samp {
  @extend %extend_2; }

section {
  @extend %extend_2;
  display: block; }

small {
  @extend %extend_2;
  font-size: 80%; }

span {
  @extend %extend_2; }

strike {
  @extend %extend_2; }

strong {
  @extend %extend_2;
  font-weight: 700; }

sub {
  @extend %extend_2;
  @extend %extend_3;
  bottom: -.25em; }

summary {
  @extend %extend_2; }

sup {
  @extend %extend_2;
  @extend %extend_3;
  top: -.5em; }

table {
  @extend %extend_2;
  border-spacing: 0;
  border-collapse: collapse;
  margin: 0 0 17px;
  width: 100%;
  td {
    padding: 10px;
    text-align: left; }

  th {
    padding: 10px;
    text-align: left;
    font-weight: 700; }

  &.blockRating-table tr:nth-child(odd) > td {
    background: $white; }

  tr th.blockRating-itemFilter {
    padding: 10px;
    background-color: $concrete;
    vertical-align: top; }

  &.windows-table tr:first-child {
    border-bottom: 1px solid $gray; } }


tbody {
  @extend %extend_2; }

td {
  @extend %extend_2;
  &.blockRating-itemProduct {
    max-width: 202px; }

  &.blockRating-itemParams {
    @extend %extend_29;
    img {
      margin-top: 3px; } }


  &.blockRating-itemInfo {
    @extend %extend_30; }

  &.blockRating-itemPrice {
    @extend %extend_37;
    text-align: right; }

  &.blockRating-itemRating {
    min-width: 80px;
    text-align: center; } }


tfoot {
  @extend %extend_2; }

th {
  @extend %extend_2;
  &.blockRating-itemProduct {
    max-width: 202px; }

  &.blockRating-itemParams {
    @extend %extend_29;
    @extend %extend_31;
    cursor: pointer;
    img {
      margin-top: 3px; } }


  &.blockRating-itemInfo {
    @extend %extend_30;
    @extend %extend_31;
    cursor: pointer; }

  &.blockRating-itemPrice {
    @extend %extend_37;
    cursor: pointer; }

  &.blockRating-itemRating {
    min-width: 80px;
    text-align: center;
    cursor: pointer; }

  &.blockRating-itemFilter .filter {
    margin: 0 0 5px; } }


thead {
  @extend %extend_2; }

time {
  @extend %extend_2;
  span {
    white-space: nowrap; } }


tr {
  @extend %extend_2;
  &:first-child th {
    &.blockRating-itemInfo {
      position: relative;
      border-top: 1px solid $color_mercury_approx; }

    &.blockRating-itemParams {
      position: relative;
      border-top: 1px solid $color_mercury_approx; } } }



tt {
  @extend %extend_2; }

u {
  @extend %extend_2; }

ul {
  @extend %extend_2;
  margin-bottom: 17px;
  list-style-position: inside;
  &.rating-legend {
    list-style: none;
    li {
      display: inline-block;
      padding-right: 1.5em;
      font-size: 12px;
      line-height: 20px;
      > span {
        margin-right: 4px;
        margin-top: -3px; } } } }




var {
  @extend %extend_2; }

video {
  @extend %extend_2;

  active: {
    outline: 0; }

  focus: {
    outline: 0; }

  hover: {
    outline: 0; } }

hr {
  display: block;
  overflow: hidden;
  margin: 22px 0 21px;
  width: 100%;
  height: 1px;
  border: 0;
  background: $wild_sand; }

button {
  @extend %extend_4;
  line-height: normal;
  @extend %extend_5;
  &:focus {
    outline: 0; }

  .icon {
    cursor: pointer; }

  .iconDel {
    margin-right: 6px; } }


input {
  @extend %extend_4;
  line-height: normal;
  &:focus {
    outline: 0; }

  &:-moz-placeholder {
    color: $color_mountain_mist_approx!important;
    text-overflow: ellipsis; }

  &:-ms-input-placeholder {
    color: $color_mountain_mist_approx!important;
    text-overflow: ellipsis; } }


select {
  @extend %extend_4;
  @extend %extend_12;
  padding: 4px 3px 3px 10px;
  width: 264px;
  height: 31px; }

textarea {
  @extend %extend_4;
  overflow: auto;
  vertical-align: top;
  resize: vertical;
  padding: 7px 10px;
  height: auto;
  resize: none;
  line-height: 15px;
  @extend %extend_12;
  &:focus {
    outline: 0;
    outline: 0; }

  &:-moz-placeholder {
    color: $color_mountain_mist_approx!important;
    text-overflow: ellipsis; }

  &:-ms-input-placeholder {
    color: $color_mountain_mist_approx!important;
    text-overflow: ellipsis; } }


input[type=submit] {
  @extend %extend_5; }

input[type=checkbox] {
  @extend %extend_6; }

input[type=radio] {
  @extend %extend_6; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

input::-moz-focus-inner {
  padding: 0;
  border: 0; }

.h3fresh {
  margin: 0 0 15px;
  font-size: 20px; }

.txtRedDark {
  color: $color_pohutukawa_approx!important; }

.txtVertical {
  position: relative;
  display: inline-block;
  bottom: -7px;
  max-width: 20px;
  white-space: nowrap;
  span {
    opacity: 0; } }


.txtVertical-inner {
  display: block;
  writing-mode: lr-bt;
  font-weight: 700!important;
  @include rotate(270deg); }

.titleDotted {
  border-style: dotted; }

.linkSelf {
  color: $color_mountain_mist_approx;
  border-bottom: 1px dashed $color_mountain_mist_approx;
  &:hover {
    border-bottom-color: transparent!important; } }


.grid {
  *zoom: 1;
  position: relative;
  margin-left: -20px;
  padding: 0;
  &:after {
    @extend %extend_8;
    clear: both; }

  &:before {
    @extend %extend_8; }

  [class*=clmn] {
    display: block;
    float: left;
    //If you use compass, instead of the line below you could use + box-sizing($bs)
    box-sizing: border-box;
    margin-left: 20px;
    width: 100%; }

  .clmnDotted {
    border: dotted $color_mercury_approx;
    border-width: 0 1px;
    height: 1620px; } }


.grid4 {
  margin-left: 0;
  min-height: 436px;
  border-top: 1px solid $color_mercury_approx;
  [class*=clmn] {
    margin: 0;
    padding: 0 20px;
    width: 245px;
    border-width: 0 0 0 1px;
    &:first-child {
      padding-left: 0;
      width: 224px;
      border-width: 0; } } }



.gridMain {
  .clmnA {
    width: 700px; }

  .clmnB {
    width: 240px;
    padding-bottom: 30px; }

  .clmnC {
    margin: 0;
    padding-bottom: 10px;
    width: 482px; }

  .clmnD {
    padding: 0 20px 30px;
    width: 198px;
    height: 100%; } }


.right {
  float: right; }

.thumbnail {
  display: block;
  padding: 0 0 17px;
  width: 340px;
  .thumbnail-image {
    text-align: center;
    img {
      margin: 0 auto;
      padding-top: 10px;
      padding-bottom: 15px; } }


  &.right {
    padding-left: 20px; } }


.thumbnail-image {
  display: block;
  position: relative;
  overflow: hidden;
  width: 340px;
  //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
  border-radius: 2px;
  img {
    display: block;
    margin: 0;
    //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
    border-radius: 2px; } }


.thumbnail-caption {
  *zoom: 1;
  padding: 18px 0;
  border-bottom: 1px dotted $color_mercury_approx;
  font-size: 13px;
  line-height: 15px;
  &:after {
    @extend %extend_9;
    clear: both; }

  &:before {
    @extend %extend_9; } }


.thumbnail-meta {
  font: 11px/15px $font_4, $font_5, $font_3; }

.btnS {
  font-size: 10px;
  line-height: 29px;
  padding: 0 3px;
  min-width: 23px; }

.btnRed {
  border: 1px solid $color_pohutukawa_approx;
  color: $color_pohutukawa_approx;
  &:hover {
    border-color: $color_pohutukawa_approx;
    color: $color_pohutukawa_approx; } }


.btnHint {
  margin: 0;
  padding: 0;
  width: 13px;
  height: 13px;
  border: 0;
  background: $color_mercury_approx;
  color: $color_mountain_mist_approx;
  font: 700 10px/13px $font_5, $font_2;
  //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
  border-radius: 6px;
  &:hover {
    color: $color_pohutukawa_approx; } }


.btnNext {
  @extend %extend_10;
  background-position: -284px -32px;
  &:hover {
    background-position: -284px -122px; } }


.btnPrev {
  @extend %extend_10;
  background-position: -268px -32px;
  &:hover {
    background-position: -268px -122px; } }


.btnNextBig {
  @extend %extend_11;
  background-position: -46px -136px; }

.btnPrevBig {
  @extend %extend_11;
  background-position: 0 -136px; }

.iconView {
  background-position: -220px 0;
  width: 14px;
  height: 14px; }

.iconSearch {
  width: 14px;
  height: 14px;
  background-position: -188px -16px; }

.iconMore {
  width: 14px;
  height: 14px;
  background-position: -204px -96px; }

.iconClose {
  width: 21px;
  height: 21px;
  background: transparent url(../img/sprite.png) no-repeat;
  background-position: -109px -25px;
  display: block; }

.iconRating {
  width: 15px;
  height: 18px;
  background-position: -154px -100px;
  display: inline-block;
  position: relative;
  overflow: visible;
  background: transparent url(../img/sprite.png) no-repeat 0 0;
  vertical-align: middle; }

.iconRefresh {
  background-position: -312px -80px;
  width: 14px;
  height: 14px; }

.iconDel {
  display: inline-block;
  position: relative;
  overflow: visible;
  background: transparent url(../img/sprite.png) no-repeat 0 0;
  vertical-align: middle; }

input[type=number] {
  @extend %extend_12;
  &:focus {
    outline: 0; } }

input::-webkit-input-placeholder {
  color: $color_mountain_mist_approx!important;
  text-overflow: ellipsis; }

textarea::-webkit-input-placeholder {
  color: $color_mountain_mist_approx!important;
  text-overflow: ellipsis; }

input::-moz-placeholder {
  color: $color_mountain_mist_approx!important;
  text-overflow: ellipsis; }

textarea::-moz-placeholder {
  color: $color_mountain_mist_approx!important;
  text-overflow: ellipsis; }

.form {
  position: relative;
  font-size: 13px;
  line-height: 15px; }

.form-item {
  display: inline-block;
  position: relative;
  z-index: 1;
  margin: 0 7px 20px 0;
  vertical-align: top;
  //If you use compass, instead of the line below you could use + text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  text-shadow: none;
  &.s {
    input {
      width: 40px; }

    .selectbox .text {
      width: 13px; } }


  &.elastic {
    width: 100%;
    margin-right: 0;
    input {
      @extend %extend_13; }

    select {
      @extend %extend_13; }

    textarea {
      @extend %extend_13;
      height: auto; }

    .selectbox {
      width: 100%; } }


  &.xs input {
    width: 34px;
    padding-right: 5px;
    padding-left: 5px;
    text-align: center; } }


.form-itemRadio {
  padding: 0 0 0 29px;
  input {
    position: absolute;
    top: 3px;
    left: 0;
    width: 13px;
    height: 13px;
    opacity: 0;
    &:checked + .form-label {
      color: $color_mountain_mist_approx;
      &:before {
        border-color: $color_mercury_approx; }

      &:after {
        display: block; } } }



  .form-label {
    padding: 0;
    &:after {
      @extend %extend_15;
      display: none;
      z-index: 3;
      top: 12px;
      left: 6px;
      width: 7px;
      height: 7px;
      background: $color_mercury_approx; }

    &:before {
      @extend %extend_15;
      display: block;
      z-index: 2;
      top: 6px;
      left: 0;
      width: 15px;
      height: 15px;
      border: 2px solid $color_mercury_approx;
      background: $white; } } }



.form-itemCheckboxSmall {
  margin-right: 17px;
  padding: 0 0 0 24px;
  input {
    position: absolute;
    top: 3px;
    left: 0;
    width: 13px;
    height: 13px;
    opacity: 0;
    &:checked + .form-label:after {
      display: block; } }


  .form-label {
    padding: 0;
    line-height: 20px;
    &:after {
      position: absolute;
      content: "";
      display: none;
      z-index: 3;
      top: 5px;
      left: 2px;
      width: 10px;
      height: 10px;
      background: transparent $url_0 no-repeat 0 0;
      background-position: -300px 0; }

    &:before {
      position: absolute;
      content: "";
      display: block;
      z-index: 2;
      top: 3px;
      left: 0;
      width: 10px;
      height: 10px;
      border: 2px solid $color_mountain_mist_approx;
      background: $white;
      //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
      border-radius: 2px; } } }



.form-itemSlider {
  position: relative;
  margin: 0 7px 20px 0;
  padding: 15px 0 0;
  height: 16px;
  width: 100%;
  .slider {
    @extend %extend_16;
    position: relative;
    background-color: $color_silver_approx;
    background-image: linear-gradient(180deg, $color_silver_approx 0, $color_mercury_approx);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffbebebe', endColorstr='#ffe1e4e7', GradientType=0);
    * {
      @extend %extend_16; }

    a {
      position: absolute;
      z-index: 1;
      &:nth-child(2) {
        background: inherit!important; } }


    &.horizontal {
      width: 100%;
      height: 6px;
      a {
        height: 100%;
        right: 0; }

      div {
        margin: -2px 0 0 -5px; } }


    div {
      height: 10px;
      width: 10px;
      //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
      border-radius: 5px;
      background: $color_pohutukawa_approx; } }


  .slider-scale {
    position: absolute;
    top: -2px;
    width: 100%;
    li {
      @extend %extend_17;
      &:first-child {
        @extend %extend_17;
        &:after {
          @extend %extend_18;
          bottom: -13px; }

        &:before {
          @extend %extend_18; }

        span {
          @extend %extend_19; } }


      &:after {
        @extend %extend_18;
        bottom: -13px; }

      &:before {
        @extend %extend_18; }

      span {
        @extend %extend_19; } } } }




.form-label {
  display: inline-block;
  padding: 0 3px 0 0;
  color: $color_mountain_mist_approx;
  font: 11px/31px $font_7, $font_5, $font_3;
  vertical-align: top;
  cursor: default; }

.form-itemSearch {
  input {
    padding-right: 31px; }

  .btn {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    width: 31px;
    border-color: transparent; } }


.tableData {
  font-size: 12px;
  line-height: 20px;
  td {
    padding: 0;
    &:first-child {
      width: 47%; } } }



.divider {
  margin-bottom: 15px;
  margin-top: 15px;
  clear: both;
  display: block;
  position: relative;
  height: 0;
  border-bottom: 1px solid $color_mercury_approx;
  font-size: 1px;
  line-height: 0;
  text-indent: -30000px;
  &:after {
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 1px;
    background: $white;
    content: "";
    font-size: 1px;
    line-height: 1px;
    text-indent: -30000px; } }


.dividerDotted {
  border-style: dotted;
  &:after {
    display: none; } }


.paginator {
  display: inline-block;
  margin: 0 0 20px;
  font: 11px/16px $font_6, $font_5, $font_3;
  //If you use compass, instead of the line below you could use + user-select($select)
  user-select: none;
  li {
    display: inline-block;
    position: relative;
    padding: 0 2px 0 0;
    vertical-align: middle;
    list-style: none; }

  a {
    @extend %extend_20;
    &:hover {
      @extend %extend_21; } }


  span {
    @extend %extend_20; }

  .next {
    padding-left: 9px;
    a {
      @extend %extend_22;
      background-position: -252px -32px;
      &:hover {
        background-position: -252px -48px; } } }



  .prev {
    padding-right: 11px;
    a {
      @extend %extend_22;
      background-position: -236px -32px;
      &:hover {
        background-position: -236px -48px; } } }



  .active a {
    @extend %extend_21;
    cursor: default; } }


.tooltip {
  display: none;
  position: absolute;
  z-index: 11;
  top: 0;
  left: 0;
  padding: 3px 5px;
  max-width: 170px;
  border: 1px solid $color_mercury_approx;
  background: $concrete;
  font: 11px/13px $font_8, $font_9, $font_10, $font_3;
  cursor: default;
  //If you use compass, instead of the line below you could use + box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 5px 20px 0 $black_15;
  &:before {
    display: block;
    position: absolute;
    top: -5px;
    left: 7px;
    width: 9px;
    height: 5px;
    content: "";
    background: transparent $url_0 no-repeat 0 0;
    background-position: -90px -44px; } }


tooltip2 {
  display: none;
  position: absolute;
  z-index: 11;
  top: 0;
  left: 0;
  padding: 3px 5px;
  max-width: 170px;
  border: 1px solid $color_mercury_approx;
  background: $concrete;
  font: 11px/13px $font_8, $font_9, $font_10, $font_3;
  cursor: default;
  //If you use compass, instead of the line below you could use + box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: 0 5px 20px 0 $black_15; }

.graphScale {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 14px;
  vertical-align: middle;
  //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
  border-radius: 2px;
  &:before {
    //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
    border-radius: 2px;
    position: absolute;
    content: "";
    width: 100%;
    border: 1px solid $color_mountain_mist_approx;
    background: $color_mercury_approx;
    @extend %extend_23; }

  span {
    //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
    border-radius: 2px;
    @extend %extend_23;
    position: relative;
    z-index: 4;
    padding: 0 2px 0 0;
    width: 0;
    overflow: hidden;
    border: 1px solid $color_pohutukawa_approx;
    background: $color_pohutukawa_approx;
    color: $white;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    &.bad {
      border: 1px solid $color_tuatara_approx;
      background: $color_tuatara_approx; }

    &.good {
      border: 1px solid $color_pohutukawa_approx;
      background: $color_pohutukawa_approx; } } }



.nav {
  display: block;
  margin: 0;
  white-space: nowrap;
  position: relative; }

.navPages {
  display: inline-block;
  padding: 0 20px 0 30px;
  max-width: 525px;
  white-space: normal;
  a {}

  .btn {
    position: absolute;
    top: 8px;
    margin: 0; }

  .btnPrev {
    left: -2px; }

  .btnNext {
    right: -2px; } }


.blockWhite {
  position: relative;
  margin: 0 -30px 60px;
  padding: 16px 30px 20px;
  border: solid $concrete;
  border-width: 1px 0;
  background: $white;
  &:after {
    @extend %extend_24;
    right: 0;
    border-width: 10px 10px 0 0; }

  &:before {
    @extend %extend_24;
    left: 0;
    border-width: 10px 0 0 10px; } }


.blockGray {
  position: relative;
  margin: 0 -30px 16px;
  padding: 16px 30px 20px;
  background: $color_mercury_approx;
  &:after {
    @extend %extend_25;
    right: 0;
    border-width: 10px 10px 0 0; }

  &:before {
    @extend %extend_25;
    left: 0;
    border-width: 10px 0 0 10px; } }

.blockCompare {
  *zoom: 1;
  position: relative;
  margin: 0 0 20px 0;
  box-sizing: content-box;
  &:before {
    display: table;
    content: "";
    line-height: 0; }
  &:after {
    display: table;
    content: "";
    line-height: 0;
    clear: both; }
  table {
    margin: 0;
    font-size: 12px;
    line-height: 18px;
    tr {
      background: 0; } }
  thead, tbody {
    position: relative; }
  th, td {
    padding: 5px 20px 6px 20px;
    min-height: 18px;
    height: 18px;
    min-width: 136px;
    width: 136px;
    max-width: 136px;
    background: 0;
    border: solid #e1e4e7;
    border-width: 0 0 0 1px;
    text-align: left;
    font-weight: normal; }
  th {
    padding: 16px 20px 16px 20px;
    font-family: "Helvetica Neue Bold",Arial,serif;
    font-size: 14px;
    line-height: 18px;
    &:first-child {
      border-left: none; } }
  td:first-child {
    border-left: none; }
  &__title {
    font-size: 100%;
    line-height: 16px;
    margin: 0 0 7px; } }

.blockCompare-sidebar {
  position: relative;
  float: left;
  margin-bottom: -1px;
  width: 250px;
  overflow: hidden;
  th, td {
    font-family: "Helvetica Neue Bold", Arial, serif; } }

.blockCompare-scroll {
  position: relative;
  margin-bottom: -1px;
  margin-left: 250px;
  width: 707px;
  overflow: hidden;
  border: solid #e1e4e7;
  border-width: 0 0 0 1px;
  table {
    width: auto; }
  th img, td img {
    margin-top: 3px;
    max-height: 116px; } }

.blockCompare-head {
  width: 958px;
  border: solid #e1e4e7;
  border-width: 1px;
  background: #fff;
  &:after {
    display: block;
    position: absolute;
    right: 0;
    bottom: -15px;
    left: 0;
    height: 15px;
    content: "";
    -webkit-box-shadow: inset 0 15px 10px -10px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 15px 10px -10px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 15px 10px -10px rgba(0, 0, 0, 0.05); }
  .blockCompare-sidebar {
    width: 209px;
    padding: 107px 20px 6px 20px; }
  .blockCompare-scroll {
    min-height: 190px; }
  .filter {
    margin: 0; } }

.blockCompare-body {
  width: 958px;
  border: solid #e1e4e7;
  border-width: 1px;
  th, td {
    border-bottom-width: 1px; } }

.blockCompare-itemProduct {
  padding-bottom: 1px !important;
  img {
    margin-bottom: 3px; } }

.blockCompare-itemDel {
  padding-bottom: 16px !important;
  .divider {
    margin-bottom: 8px; }
  a {
    color: #4f4f4f;
    font: (11px / 15px) Georgia, "Times New Roman", Times, serif; }
  .icon {
    margin-top: -3px; } }

.blockCompare-itemScore {
  color: #bd0217; }

.blockCompare th.blockCompare-itemNarrow {
  font-size: 95%;
  padding: 6px 20px 6px 20px; }

.filterText {
  font-size: 11px;
  li {
    margin-bottom: 6px;
    font-family: "Helvetica Neue Bold",Arial,serif;
    color: #4f4f4f;
    &:first-child {
      margin-top: 0;
      margin-bottom: 11px;
      padding-bottom: 0;
      border-bottom: 0; }
    &.active a {
      color: #989898;
      border-bottom-color: #989898 !important;
      cursor: default; } }
  a {
    font-family: Georgia,"Times New Roman",Times,serif;
    border-bottom: 1px dashed #bd0217;
    &:hover {
      border-bottom-color: transparent !important; } } }

.blockError {
  margin-top: -5px;
  font-size: 13px;
  h3 {
    margin-bottom: 12px; } }


.blockProduct {
  *zoom: 1;
  position: relative;
  min-height: 281px;
  &:after {
    @extend %extend_26;
    clear: both; }

  &:before {
    @extend %extend_26; } }


.blockRating {
  *zoom: 1;
  position: relative;
  margin: 0;
  &:after {
    @extend %extend_27;
    clear: both; }

  &:before {
    @extend %extend_27; }

  table {
    margin: 0;
    font: 11px/15px $font_6, $font_5, $font_3;
    tr {
      background: 0; } }


  td {
    @extend %extend_28;
    &:last-child {
      border-right-width: 0; } }


  th {
    @extend %extend_28;
    padding-top: 5px!important;
    padding-bottom: 8px;
    vertical-align: bottom;
    &:last-child {
      border-right-width: 0; }

    &.blockRating-subCategory {
      padding-left: 0;
      font-weight: 700;
      font-size: 110%;
      cursor: pointer; } }


  .form-itemCheckboxSmall {
    margin: 0 0 0 -10px; }

  .form-label {
    padding-top: 2px;
    color: $color_tuatara_approx;
    font-family: $font_6, $font_5, $font_3;
    font-size: 11px;
    line-height: 15px; } }


.blockRating-head {
  background: $white;
  &.active {
    border-bottom: 1px solid $color_mercury_approx;
    &:after {
      display: block;
      position: absolute;
      right: 0;
      bottom: -15px;
      left: 0;
      height: 15px;
      content: "";
      //If you use compass, instead of the line below you could use + box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
      box-shadow: inset 0 15px 10px -10px $black_5; } } }



.teasers {
  margin: 0;
  font-size: 13px;
  line-height: 15px;
  &.inline {
    margin-left: -20px;
    h2 {
      margin-left: 20px;
      font-size: 16px;
      color: $color_log_cabin_approx!important; }

    .teasers-item {
      display: inline-block;
      margin-left: 20px;
      vertical-align: top; } }


  > .divider {
    display: none; }

  + .divider {
    margin-bottom: 20px; } }


.teasers-item {
  margin: 0 0 17px;
  padding: 20px 0 0;
  border-top: 1px solid $color_mercury_approx;
  list-style: none;
  &:first-child {
    padding-top: 0;
    border-top: 0; }

  &.hover h3 a {
    color: $color_pohutukawa_approx; } }


.teasers-image {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0 0 14px;
  width: 100%;
  max-height: 307px;
  //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
  border-radius: 2px;
  img {
    display: block;
    margin: 0 auto;
    //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
    border-radius: 2px; } }


.teasers-txt {
  margin: 0 0 7px;
  font-size: 12px; }

.teasers-meta {
  padding: 7px 0 0;
  border-top: 1px dotted $color_mercury_approx;
  color: $color_mountain_mist_approx;
  font-size: 11px;
  a {
    color: $color_fuscous_gray_approx;
    text-decoration: none; }

  time {
    color: $color_tuatara_approx;
    span {
      color: $color_mountain_mist_approx; } } }



.teasers-views {
  display: none;
  white-space: nowrap;
  color: $color_mountain_mist_approx!important;
  .iconView {
    margin-right: 3px; } }


.teasers-divider {
  display: none;
  position: relative;
  margin: 0 15px;
  &:before {
    content: "•";
    color: $color_fuscous_gray_approx;
    font-size: 12px; } }


.teasersNarrow {
  margin: 0 0 20px;
  .teasers-itemVideo {
    margin: 0 -21px;
    padding: 16px 21px 0; }

  .teasers-title {
    font-size: 14px;
    line-height: 18px; }

  .teasers-meta {
    overflow: hidden;
    > a {
      @extend %extend_32;
      + span {
        text-align: right; } }


    > span {
      @extend %extend_32;
      + span {
        text-align: right; } } }



  .teasers-category {
    display: block!important;
    float: left; }

  .teasers-views {
    display: block!important;
    float: left; }

  .teasers-image {
    height: 116px; } }


.teasersImgLeft {
  .teasers-item {
    margin: 0;
    padding-bottom: 16px;
    min-height: 164px; }

  .teasers-image {
    float: left;
    margin: 0;
    width: 215px;
    height: 160px; }

  .teasers-descrip {
    margin: 0 0 0 235px; }

  &.s {
    .teasers-item {
      min-height: 120px; }

    .teasers-image {
      width: 160px;
      height: 116px; }

    .teasers-descrip {
      margin: 0 0 0 180px; }

    .teasers-title {
      font-size: 14px;
      line-height: 18px; } }


  &.inline {
    .teasers-item {
      padding-top: 0;
      padding-bottom: 6px;
      width: 465px;
      border: 0; }

    > .divider {
      display: block;
      margin: 0 0 20px 20px;
      padding: 10px 0 0; } } }



.teasersBorder {
  .teasers-item {
    margin: 0 0 20px;
    padding: 15px 15px 6px;
    border: 1px solid $color_mercury_approx; }

  &.s {
    .teasers-item {
      min-height: 110px; }

    .teasers-image {
      float: left;
      margin: 0;
      width: 90px;
      height: 116px; }

    .teasers-descrip {
      margin: 0 0 0 110px; }

    .teasers-title {
      font-size: 13px;
      line-height: 16px;
      color: $color_pohutukawa_approx!important; } }


  &.inline .teasers-item {
    width: 295px; } }


.teasersGray {
  padding: 12px 20px 0;
  margin: 0 0 15px;
  background: $concrete;
  .teasers-item {
    padding-bottom: 10px; }

  .more {
    border-top-color: $color_ghost_approx; }

  .teasers-meta {
    border-top-color: $color_ghost_approx; } }


.social-likes {
  padding: 20px 0 0; }

.gallery {
  position: relative;
  overflow: hidden;
  .caroufredsel_wrapper {
    margin-bottom: 0!important; }

  .thumbnail-image {
    min-height: 208px; } }


.gallery-navEasy {
  position: absolute;
  z-index: 2;
  top: 278px;
  left: 2%;
  padding: 0;
  width: 96%;
  //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
  border-radius: 2px;
  li {
    display: block;
    float: left;
    width: 50%;
    list-style: none;
    text-align: right;
    vertical-align: top;
    &:first-child {
      text-align: left; } }


  .btn {
    margin: 0; } }


.gallery-paginator {
  display: none!important;
  position: absolute;
  z-index: 2;
  top: 100px;
  left: 0;
  width: 100%;
  line-height: 19px;
  text-align: center;
  a {
    display: inline-block;
    margin: 0 2px;
    width: 15px;
    height: 15px;
    overflow: hidden;
    border: 2px solid $color_mountain_mist_approx;
    background: transparent $url_1 repeat 0 0;
    text-indent: -3000px;
    vertical-align: middle;
    cursor: pointer;
    //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
    border-radius: 15px;
    &:hover {
      border-color: $color_mercury_approx; }

    &.selected {
      margin: 0 8px;
      width: 7px;
      height: 7px;
      border: 0;
      background: $color_mercury_approx;
      cursor: default; } } }



.galleryPopup {
  width: 980px;
  .gallery-paginator {
    display: block!important;
    top: 560px; }

  .thumbnail {
    padding: 0;
    width: 980px; }

  .thumbnail-image {
    position: relative;
    overflow: hidden;
    width: 980px;
    height: 615px;
    background: $white;
    line-height: 610px;
    //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
    border-radius: 0;
    img {
      display: inline-block;
      vertical-align: middle;
      //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
      border-radius: 0; } }


  .thumbnail-caption {
    padding-left: 20px;
    padding-right: 20px;
    border: 0;
    color: $white; }

  .thumbnail-label {
    float: left;
    width: 50%; }

  .thumbnail-meta {
    float: right;
    width: 50%;
    text-align: right;
    padding-top: 0; }

  .gallery-list > li {
    width: 980px; } }


.banner {
  display: block;
  margin: 0 0 20px; }

.bycenter {
  margin: 0 auto; }

.banner240 {
  width: 240px; }

.contacts {
  font-size: 14px;
  line-height: 20px;
  h3 {
    margin-bottom: 20px;
    padding: 15px 0;
    font-family: $font_7, $font_5, $font_3; }

  p {
    margin-bottom: 19px;
    padding: 0 0 15px;
    border-bottom: 1px dotted $color_mercury_approx; }

  a {
    text-decoration: none; }

  dt {
    padding: 0 0 4px; }

  dd {
    padding: 0 0 11px; } }


.filter {
  margin: 0 0 16px;
  li {
    list-style: none;
    &:first-child {
      margin-top: -6px;
      margin-bottom: 14px;
      padding-bottom: 16px;
      border-bottom: 1px solid $color_mercury_approx; } }


  .form-itemRadio {
    margin: 0; }

  .form-label {
    line-height: 29px; } }


.filterRating {
  min-width: 285px;
  .form-itemSearch {
    float: left;
    width: 70%; }

  .form-itemSelect {
    float: right;
    width: 27%; } }


.filterRating-slider {
  margin: 0 0 20px;
  .form-itemSlider {
    display: block;
    width: auto;
    margin: 0 170px 0 60px; }

  > .form-label {
    display: block;
    float: left;
    width: 50px;
    line-height: 15px;
    padding-top: 8px; } }


.filterRating-fields {
  float: right;
  margin-right: -7px;
  text-align: right;
  .form-item {
    margin-right: 4px; } }


.filterRating-footer {
  position: relative;
  padding: 10px 0 0;
  border: solid $white;
  border-width: 1px 0 0;
  &:before {
    position: absolute;
    top: -2px;
    width: 100%;
    height: 1px;
    background: $color_mercury_approx;
    content: ""; }

  .right {
    margin-right: 74px; } }


.comments {
  margin: 0 0 20px;
  border: 1px solid $color_mercury_approx; }

.comments-wrap {
  position: relative;
  padding: 16px 20px 20px;
  color: $color_fuscous_gray_approx;
  font-size: 12px;
  line-height: 14px;
  //If you use compass, instead of the line below you could use + box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: inset 0 90px 60px -60px $black_3;
  h3 {
    margin: 0 0 23px;
    font-size: 14px;
    line-height: 18px;
    sup {
      color: $color_pohutukawa_approx; } }


  + .comments-wrap {
    padding-top: 27px;
    padding-bottom: 0; } }


.comments-item {
  padding-top: 27px;
  border-top: 1px solid $color_mercury_approx;
  &:first-child {
    padding-top: 0;
    border-top: 0; } }


.comments-author {
  float: right;
  width: 180px;
  font-size: 13px;
  line-height: 15px;
  .userpic {
    float: left;
    margin-right: 19px; } }


.comments-msg {
  margin-right: 200px;
  padding: 0 0 7px;
  font-size: 13px;
  line-height: 15px; }

.comments-add {
  .form-itemSubmit {
    margin: 0; }

  .btn {
    margin-bottom: 0; } }


.comments-meta {
  margin-right: 200px;
  padding: 7px 0 25px;
  border-top: 1px dotted $color_mercury_approx;
  font: 11px/15px $font_8, $font_9, $font_10, $font_3;
  span {
    color: $color_mountain_mist_approx; } }


.comments-divider {
  position: relative;
  margin: 0 8px;
  &:before {
    content: "/";
    color: $color_fuscous_gray_approx;
    font-size: 9px; } }


.comment-reply {
  padding: 27px 0 0 40px;
  border-top: 1px solid $color_mercury_approx; }

.tabs-content {
  padding: 6px 0 0;
  line-height: 17px;
  h2 {
    color: $color_tuatara_approx; }

  h3 {
    line-height: 18px; }

  .h3comments {
    margin: 0 0 15px;
    font-size: 20px; } }


.tabs-pane {
  .thumbnail {
    width: auto; }

  .thumbnail-image {
    width: auto; } }


.account {
  position: relative; }

.userpic {
  display: inline-block;
  margin: 0 7px 11px 0;
  vertical-align: top;
  white-space: nowrap;
  > img {
    display: block;
    margin: 0 auto;
    //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
    border-radius: 2px; } }

.userpic + .userData-list {
  margin-top: -5px; }

.userpicXl {
  width: 100%;
  margin: 0 0 20px 0; }

.userData {
  margin: 0 0 20px 0;
  .date {
    margin: 0;
    border: 0; } }

.userData-list {
  position: relative;
  margin: 0 0 19px 0;
  overflow: hidden;
  border-bottom: 1px solid #e1e4e7;
  font-family: "Helvetica Neue Bold",Arial,serif;
  font-size: 11px;
  dt {
    padding: 16px 0 3px 0;
    border-top: 1px dotted #e1e4e7;
    color: #989898;
    &:first-child {
      padding-top: 0;
      border-top: 0; } }
  dd {
    padding: 0 0 17px 0; } }

.starBlack {
  @extend %extend_33;
  &.sizeS {
    @extend %extend_34; }

  &.sizeL {
    @extend %extend_35; }

  &.full {
    background-image: $url_5; }

  &.empty {
    background-image: $url_6; }

  &.half {
    background-image: $url_7; } }


.starRed {
  @extend %extend_33;
  &.sizeS {
    @extend %extend_34; }

  &.sizeL {
    @extend %extend_35; }

  &.full {
    background-image: $url_2; }

  &.empty {
    background-image: $url_3; }

  &.half {
    background-image: $url_4; } }


.more {
  position: relative;
  padding: 15px 0 16px;
  border-top: 1px solid $color_mercury_approx;
  font: 11px/15px $font_7, $font_5, $font_3;
  &:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: $white;
    content: "";
    font-size: 1px;
    line-height: 1px; }

  a {
    color: $color_pohutukawa_approx;
    text-decoration: none;
    white-space: nowrap; }

  .paginator {
    margin-right: 28px;
    a {
      color: $color_mountain_mist_approx; } } }



.popupBackground {
  z-index: 1998;
  overflow: hidden;
  background: $black;
  opacity: .7;
  @extend %extend_36; }

.popup {
  @extend %extend_36;
  z-index: 1999;
  overflow: auto;
  overflow-y: auto;
  .popup-dialog {
    width: 372px; } }


.popup-dialog {
  margin-left: auto;
  margin-right: auto;
  width: auto;
  padding: 20px;
  z-index: 2000; }

.popup-content {
  position: relative;
  background: $white; }

.popup-close {
  position: absolute;
  z-index: 4;
  top: 20px;
  right: 20px;
  font-size: 1px;
  line-height: 0; }

.popupGallery {
  .popup-dialog {
    width: 980px; }

  .popup-content {
    background: $color_tuatara_approx; } }


.sidebar {
  .iconMore {
    background-position: -204px -80px; }

  h3 a:hover {
    color: $color_pohutukawa_approx; }

  .more a {
    color: $color_pohutukawa_approx; } }


.rich-content {
  padding-bottom: 1em;
  li {
    text-indent: 0;
    margin-left: 0;
    padding-bottom: 1px; }

  table {
    font-size: 80%;
    padding: 8px;
    border-collapse: collapse;
    &.custom-table {
      border: 0;
      font-weight: 700;
      tr {
        > td {
          border: 0;
          border-top: 1px solid $color_bon_jour_approx;
          background: $white!important;
          padding: 6px; }

        &:first-child {
          border: 0;
          border-top: 1px solid $color_celeste_approx; }

        &:hover td {
          border: 0;
          background-color: $color_spring_wood_approx!important; } } }



    tr {
      > td {
        border: 1px solid $color_horizon_approx;
        padding: 6px;
        &:nth-child(odd) {
          background: $color_cornflower_approx; }

        &:nth-child(even) {
          background: $color_tropical_blue_approx; } }


      > th {
        &:nth-child(odd) {
          background: $color_cornflower_approx; }

        &:nth-child(even) {
          background: $color_tropical_blue_approx; } } } }




  h2 {
    border: 0;
    padding-bottom: 0;
    clear: none;
    margin-bottom: 10px; }

  h3 {
    font-size: 17px;
    padding-top: 15px; }

  figcaption {
    display: none; }

  .thumbnail {
    padding-bottom: 0;
    cursor: pointer; }

  a {
    color: $color_pohutukawa_approx;
    text-decoration: underline;
    &:hover {
      color: $color_pohutukawa_approx;
      text-decoration: none; }

    &:visited {
      color: $color_governor_bay_approx;
      text-decoration: underline; } }


  .thumbnail-image:before {
    display: block;
    position: absolute;
    right: 10px;
    bottom: 20px;
    width: 17px;
    height: 17px;
    background: transparent $url_0 no-repeat 0 0;
    background-position: -90px -25px;
    content: "";
    cursor: pointer; }

  ul li {
    @extend %extend_38; } }


.h1title {
  margin: 1px 0 15px;
  font-family: $font_11;
  text-transform: uppercase;
  border-bottom: 1px solid $color_pumice_approx;
  padding-bottom: 10px;
  letter-spacing: 1px;
  line-height: 31px;
  font-weight: 700;
  h1 {
    color: inherit;
    padding: 0;
    margin: 0;
    border: none;
    padding-right: 10px; }

  a.right {
    color: $color_pohutukawa_approx;
    font-size: 11px;
    line-height: 31px;
    font-weight: 700; } }


.clickable {
  cursor: hand;
  cursor: pointer; }

.article-text {
  .thumbnail-image {
    width: auto;
    &:before {
      display: block;
      position: absolute;
      right: 10px;
      bottom: 20px;
      width: 17px;
      height: 17px;
      background: transparent $url_0 no-repeat 0 0;
      background-position: -90px -25px;
      content: "";
      cursor: pointer; } }


  a {
    color: $color_pohutukawa_approx;
    text-decoration: underline;
    &:hover {
      color: $color_pohutukawa_approx;
      text-decoration: none; }

    &:visited {
      color: $color_governor_bay_approx;
      text-decoration: underline; } }


  ul li {
    @extend %extend_38; } }


.h3fresh-teaser {
  font-weight: 700;
  display: inline-block;
  margin: 0 0 7px; }

.h3popular {
  margin-left: 20px; }

.active > a {
  text-decoration: none;
  border-bottom-color: transparent; }

.thumbnail-label + .thumbnail-meta {
  padding-top: 10px; }

.form-itemSelect .selectbox {
  margin: 0;
  padding: 0;
  height: 31px;
  border: 1px solid $color_mercury_approx;
  background: $white;
  color: $color_mountain_mist_approx;
  font: 11px/29px $font_6, $font_5, $font_3;
  vertical-align: top;
  cursor: default;
  //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
  border-radius: 2px;
  //If you use compass, instead of the line below you could use + box-sizing($bs)
  box-sizing: border-box;
  //If you use compass, instead of the line below you could use + user-select($select)
  user-select: none;
  .select {
    float: none!important; }

  .text {
    display: block;
    position: relative;
    overflow: hidden;
    margin-right: 40px;
    margin-left: 10px;
    white-space: nowrap;
    cursor: default; }

  .trigger {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 29px;
    cursor: pointer;
    &:after {
      @extend %extend_14;
      display: block;
      position: absolute;
      top: 13px;
      left: 10px;
      width: 0;
      height: 0;
      border: 5px solid;
      border-color: $color_mercury_approx transparent transparent; }

    &:before {
      @extend %extend_14;
      top: 4px;
      left: -1px;
      width: 1px;
      height: 21px;
      background: $color_mercury_approx; } }


  .dropdown {
    top: 31px;
    left: 0;
    margin: 0 0 0 -1px;
    min-width: 100%;
    max-height: 145px!important;
    overflow-y: auto;
    overflow-x: hidden;
    border: solid $color_mercury_approx;
    border-width: 1px;
    background: $white;
    line-height: 18px;
    ul {
      margin: 0;
      list-style: none; }

    li {
      margin: 0;
      padding: 0 25px 0 10px;
      white-space: nowrap;
      &:hover {
        background-color: $color_pohutukawa_approx;
        color: $white; }

      &.sel {
        display: none; } } } }




.form-itemRed input:checked + .form-label {
  color: $color_pohutukawa_approx;
  &:before {
    border-color: $color_pohutukawa_approx; }

  &:after {
    background: $color_pohutukawa_approx; } }


.form-itemPurple input:checked + .form-label {
  color: $color_nevada_approx;
  &:before {
    border-color: $color_nevada_approx; }

  &:after {
    background: $color_nevada_approx; } }


.form-itemPink input:checked + .form-label {
  color: $color_new_york_pink_approx;
  &:before {
    border-color: $color_new_york_pink_approx; }

  &:after {
    background: $color_new_york_pink_approx; } }


.form-itemBlue input:checked + .form-label {
  color: $color_danube_approx;
  &:before {
    border-color: $color_danube_approx; }

  &:after {
    background: $color_danube_approx; } }


.form-itemBrown input:checked + .form-label {
  color: $color_sandal_approx;
  &:before {
    border-color: $color_sandal_approx; }

  &:after {
    background: $color_sandal_approx; } }


.formBlock .form-item {
  display: block; }

#overlay-registration {}
.overlay-header {
  text-align: left;
  padding: 20px 0px 20px 0px; }

.gallery-list > li {
  display: block;
  position: relative;
  float: left;
  margin: 0;
  padding: 0;
  width: 340px;
  list-style: none; }

.sizeS > {}
.starBlack {
  @extend %extend_34;

  .starRed {
    @extend %extend_34; } }


.sizeL > {}
.starBlack {
  @extend %extend_35;

  .starRed {
    @extend %extend_35; } }


.popupCenter .popup-dialog {
  position: absolute;
  top: 50%;
  left: 50%; }

.teasers-descrip > h3 {
  display: inline-block; }

.rating-legend li:before {
  content: none!important; }

.static-content ul li {
  list-style-type: none;
  line-height: 20px;
  margin: 0;
  padding: 0 0 6px 24px;
  background: $url_8 no-repeat 5px 9px; }

.blockRating-itemProduct a:hover {
  text-decoration: underline; }

.validation-summary-errors ul {
  list-style-type: none;
  padding-left: 14px;
  li {
    color: $color_pohutukawa_approx; } }

.blockGoods h2, .blockNews h2, .blockPopular h2 {
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  font-family: proxima-nova, sans-serif;
  text-transform: uppercase;
  color: #343434;
  padding: 13px 0 0;
  margin: 0 0 14px;
  text-shadow: none;
  letter-spacing: 0.5pt; }

.btn-login {
  display: block;
  background: #bd0217;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  font-family: proxima-nova;
  text-transform: uppercase;
  padding: 9px 20px 8px 13px;
  border: 0; }

.mainContentWrapper {
  margin: 0 0 20px;
  box-shadow: 0 2px 0 #f7f7f7; }

.blockGoods {
  clear: both;
  border-top: 1px solid #ccc;
  .block-header {
    border-bottom: 4px solid #bd0217;
    margin: 0 -1px;
    padding: 0 0 0 1px; }
  .block-content {
    border-left: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
    border-bottom: 1px solid #d2d2d2;
    padding: 15px 15px 0 0;
    margin: 0 -1px;
    .blockGoods-row {
      width: 192px;
      &:first-child {
        width: 176px; } } } }

.blockNews {
  width: 560px;
  float: left;
  margin: 0 16px 0 0;
  border-top: 1px solid #ccc; }

.blockPopular {
  width: 384px;
  float: left;
  border-top: 4px solid #bd0217;
  h2 {
    padding: 10px 0 0; } }

.block-main {
  p {
    font-size: 12px;
    line-height: 17px;
    margin: 0; }
  h3 {
    font-size: 13px;
    line-height: 15px;
    margin: 0 0 4px;
    font-weight: 700;
    a {
      color: #bd0217;
      &:hover {
        text-decoration: underline; } } } }

.lnk-allnews {
  float: right;
  font-size: 13px;
  font-weight: 700;
  margin: 10px 0 0;
  &:hover {
    text-decoration: underline; } }

.i-rarr {
  float: right;
  width: 5px;
  height: 8px;
  background: url(../img/i-rarrow.png) no-repeat;
  background-size: cover;
  margin: 5px 0 0 10px; }

.blockNews-cats {
  border-bottom: 1px solid #ccc;
  margin: 0 0 15px;
  padding: 0 0 15px;
  .blockNews-row {
    float: left;
    width: 176px;
    margin: 0 0 0 16px;
    &:first-child {
      margin: 0; } }
  .blockNews-row-pic {
    margin: 0 0 9px; } }

.blockNews-catTitle {
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 10px;
  a {
    color: #000;
    font-family: proxima-nova, Segoe UI; } }

.i-popular {
  float: left;
  display: block;
  width: 12px;
  height: 12px;
  background: url(../img/i-popular.png) no-repeat;
  background-size: cover;
  margin: 0 10px 0 13px; }

.blockPopular-list {
  padding: 0 40px 0 16px;
  h3 {
    margin-top: -3px; } }

.blockPopular-row {
  margin: 0 0 20px; }

.blockPopular-row-pic {
  float: left;
  margin: 0 15px 0 0;
  padding: 3px 0 0; }

.blockGoods-row-pic {
  padding: 0 0 0 16px;
  margin: 0 0 8px; }

.blockGoods-row-txt {
  padding: 0 0 5px 16px; }

.blockGoods-catTitle {
  border-top: 1px solid #eaeaea;
  font-size: 11px;
  padding: 9px 0 0;
  width: 176px;
  margin: 0 0 0 16px;
  a {
    color: #8a8a8a;
    &:hover {
      text-decoration: underline; } } }

.mainSlider {
  margin: 0 0 17px; }

.mainSlider-list {
  width: 960px;
  height: 253px;
  overflow: hidden;
  position: relative;
  z-index: 0;
  .item {
    float: left;
    margin: 0 0 0 16px;
    &:first-child {
      margin: 0; } } }

.bigadsslider-wrap {
  position: absolute; }

.title-article {
  font-family: Helvetica neue, Segoe UI, Arial, sans-serif;
  text-transform: none;
  letter-spacing: 0;
  font-size: 20px; }

.c:after {
  content: ".";
  display: block;
  clear: both;
  overflow: hidden;
  height: 0;
  visibility: hidden; }

.blockNews-list .blockNews-row {
  margin: 0 0 18px; }

.form-socbtns a {
  float: left;
  margin: 0 0 0 14px; }

#lnk-reg:hover {
  text-decoration: underline; }

img.clickable {
  margin-bottom: 20px;
  margin-top: 10px; }


.thumbnail-image {
  display: block;
  position: relative;
  overflow: hidden;
  width: 340px;
  //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
  border-radius: 2px; }

.thumbnail-caption {
  *zoom: 1;
  padding: 18px 0;
  border-bottom: 1px dotted $color_mercury_approx;
  font-size: 13px;
  line-height: 15px;
  &:after {
    @include extend_39;
    clear: both; }

  &:before {
    @include extend_39; } }


.thumbnail-meta {
  font: 11px/15px $font_13, $font_5, $font_3; }

.btnS {
  font-size: 10px;
  line-height: 29px;
  padding: 0 3px;
  min-width: 23px; }

.btnRed {
  border: 1px solid $color_pohutukawa_approx;
  color: $color_pohutukawa_approx;
  &:hover {
    border-color: $color_pohutukawa_approx;
    color: $color_pohutukawa_approx; } }


.btnHint {
  margin: 0;
  padding: 0;
  width: 13px;
  height: 13px;
  border: none;
  background: $color_mercury_approx;
  color: $color_mountain_mist_approx;
  font: 700 10px/13px $font_5, $font_2;
  //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
  border-radius: 6px;
  &:hover {
    color: $color_pohutukawa_approx; } }


.btnNext {
  @include extend_40;
  background-position: -284px -32px;
  &:hover {
    background-position: -284px -122px; } }


.btnPrev {
  @include extend_40;
  background-position: -268px -32px;
  &:hover {
    background-position: -268px -122px; } }


.btnNextBig {
  @include extend_41;
  background-position: -46px -136px; }

.btnPrevBig {
  @include extend_41;
  background-position: 0 -136px; }

a {
  .icon {
    cursor: pointer; }

  .iconRating {
    margin-right: 4px; }

  .iconRefresh {
    margin: -2px 9px 0 0; }

  &.arr {
    padding-right: 15px;
    background: $url_11 right 4px no-repeat; } }

.iconView {
  background-position: -220px 0;
  width: 14px;
  height: 14px; }

.iconSearch {
  width: 14px;
  height: 14px;
  background-position: -188px -16px; }

.iconMore {
  width: 14px;
  height: 14px;
  background-position: -204px -96px; }

.iconRating {
  width: 15px;
  height: 18px;
  background-position: -154px -100px; }

.iconRefresh {
  background-position: -312px -80px;
  width: 14px;
  height: 14px; }

.iconDel {
  width: 15px;
  height: 15px;
  background-position: -171px 0; }


@font-face {
  font-family: $font_12;
  src: $url_18;
  src: $url_19 format('embedded-opentype'), $url_20 format('woff'), $url_21 format('truetype'), $url_22 format('svg');
  font-weight: 400;
  font-style: normal; }

.navPages {
  font-family: $font_11;
  font-weight: 700;
  width: auto; }

.h1title {
  h1 {
    border: none;
    color: inherit;
    margin: 0;
    padding: 0 10px 0 0; }

  &.without-underline {
    border-bottom: none;
    margin-bottom: 0;
    padding: 0;
    min-height: 47px;
    padding-top: 3px;
    h1 {
      margin: 0;
      color: $black;
      font-size: 26px;
      font-family: $font_11;
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: none; } }


  a.right.rating-icon {
    font-size: 14px;
    font-weight: 700;
    color: $color_pohutukawa_approx;
    line-height: normal;
    padding-top: 11px;
    text-transform: none;
    letter-spacing: normal;
    font-family: $font_0, $font_1, $font_2;
    span {
      background-image: $url_23;
      background-position: 0 -3px;
      background-repeat: no-repeat;
      background-size: 14px 14px;
      width: 14px;
      height: 12px;
      margin-right: 7px; } } }



.product-gallery {
  width: 395px;
  margin-right: 15px;
  border: none;
  border-top: 1px solid $mercury;
  float: left;
  .product-gallery-thumbnails {
    width: 50px;
    padding-top: 25px;
    float: left;
    margin-bottom: 0;
    overflow: hidden;
    height: 300px;
    li {
      border: none;
      list-style: none;
      margin-bottom: 15px;
      text-align: center;
      a {
        opacity: .6;
        img {
          max-height: 50px;
          max-width: 50px; } } } }




  .product-gallery-img {
    float: right;
    width: 330px;
    height: 300px;
    display: table;
    text-align: center;
    vertical-align: middle;
    .container {
      display: table-cell;
      text-align: center;
      vertical-align: middle; } } }



.clear {
  height: 0;
  overflow: hidden;
  clear: both; }

.product-sidebar {
  width: 290px;
  float: right;
  .product-rating {
    display: table;
    background-color: $wild_sand;
    width: 100%;
    .cell {
      display: table-cell;
      vertical-align: middle;
      padding: 0 15px;
      &.text {
        line-height: 1.5em; }

      &.number {
        background-color: $color_pohutukawa_approx;
        color: $white;
        font-size: 43px;
        width: 78px;
        height: 61px;
        padding: 0;
        text-align: center;
        font-weight: 700;
        font-family: $font_11; }

      b {
        display: block;
        font-size: 15px;
        font-family: $font_11; }

      a {
        font-size: 14px; } } }



  .product-info {
    background: $url_24 0 0 repeat-x;
    padding-top: 9px;
    font-size: 13px;
    .feature-list {
      list-style: none;
      padding: 0 0 0 35px;
      background: $url_25 0 2px no-repeat;
      background-size: 16px 16px;
      margin-bottom: 16px;
      min-height: 19px;
      &.plus {
        background: $url_9 0 2px no-repeat;
        background-size: 16px 16px;
        min-height: 19px; }

      &.minus {
        background: $url_10 0 2px no-repeat;
        background-size: 16px 16px;
        min-height: 19px; }

      li {
        margin: 5px 0; } } } }




.stars-block {
  width: 193px;
  margin: 0 0 15px 15px;
  .header {
    height: 3px;
    overflow: hidden;
    background-color: $color_pohutukawa_approx; }

  .content {
    border-left: 1px solid $color_silver_sand_approx;
    border-right: 1px solid $color_silver_sand_approx;
    border-bottom: 1px solid $color_silver_sand_approx;
    .title {
      height: 27px;
      padding: 8px 0 0 15px;
      font-size: 15px;
      font-weight: 700;
      border: none;
      border-bottom: 1px solid $mercury;
      margin: 0 0 4px;
      font-family: $font_11; }

    .row {
      display: table;
      width: 100%;
      height: 28px;
      font-size: 13px;
      &:last-child {
        margin-bottom: 4px; }

      .name {
        display: table-cell;
        vertical-align: middle;
        padding: 0 0 0 15px; }

      .value {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        width: 40px; } } } }




.shops2 {
  margin: 0 0 5px;
  position: relative;
  margin-bottom: 15px;
  .shops-header {
    height: 3px;
    overflow: hidden;
    .red {
      height: 3px;
      overflow: hidden;
      width: 175px;
      float: left;
      background-color: $color_pohutukawa_approx; }

    .gray {
      height: 1px;
      overflow: hidden;
      width: 520px;
      float: right;
      background-color: $color_alto_approx;
      margin-top: 1px; } }


  .shops-content {
    margin-top: 13px;
    .where-to-buy {
      display: block;
      font-size: 18px;
      padding-left: 38px;
      background: $url_14 3px top no-repeat;
      background-size: 16px 16px;
      margin: 4px 0 10px;
      font-family: $font_11;
      font-weight: 700; }

    .sort-shops {
      font-size: 12px;
      color: $color_nobel_approx;
      float: right;
      margin-top: -3px;
      a {
        color: $color_pohutukawa_approx;
        display: inline-block;
        padding-right: 20px;
        &.active {
          color: $black; } } }



    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        width: 48%;
        float: left;
        .table {
          width: 100%;
          height: 25px;
          .cell {
            vertical-align: middle;
            &.priceRUR {
              width: 90px;
              text-align: right;
              color: $color_pohutukawa_approx; }

            &.delivery {
              width: 63px;
              color: $color_nobel_approx;
              padding-left: 18px;
              background: $url_15 left 9px no-repeat;
              span {
                font-size: 12px; } } } }




        b {
          float: right; }

        &:nth-child(2n + 1) {
          margin-right: 4%; } } }



    .region {
      color: $color_pohutukawa_approx;
      background: $url_12 no-repeat scroll 100%;
      padding-right: 15px; }

    .regions {
      background: $url_17 no-repeat scroll 10px $color_black_haze_approx;
      background-size: 12px 12px;
      padding-left: 30px; } }


  .shops-footer {
    margin-top: 7px;
    .gray {
      height: 1px;
      overflow: hidden;
      background-color: $mercury;
      width: 520px;
      float: left;
      margin-top: 7px;
      margin-top: 8px;
      width: 533px; }

    a {
      padding-right: 20px;
      display: block;
      float: right;
      font-size: 13px;
      font-size: 12px; }

    .down {
      background: $url_12 right 6px no-repeat; }

    .up {
      background: $url_13 right 6px no-repeat; }

    .market {
      position: absolute;
      left: 0;
      bottom: 1px;
      padding: 0 15px 0 0;
      background: $white;
      color: $color_nobel_approx; } } }



#delim {
  float: right;
  width: 100%;
  margin: 5px 0 0 5px;
  font-size: 12px; }

.table {
  display: table;
  .cell {
    display: table-cell; } }


.back-to-overview {
  top: -10px;
  padding-left: 15px;
  background: $url_16 left 3px no-repeat;
  display: inline-block;
  position: relative;
  margin-bottom: 10px;
  font-size: 13px; }

.preloader {
  width: 64px;
  height: 64px;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

#regionChooseContainer {
  border: 1px solid $color_alto_approx;
  font-family: $font_0, $font_1, $font_2;
  .region-cell {
    cursor: pointer;
    color: $color_ironside_gray_approx; }

  table {
    margin: 15px 15px 15px 6px; }

  td {
    padding: 7px 0 5px 10px;
    width: 100px; }

  &:after {
    content: "";
    position: absolute;
    border-left: 7px solid rgba(255,241,190,0);
    border-right: 7px solid rgba(255,241,190,0);
    border-bottom: 7px solid $white;
    left: 87px;
    top: -6px; }

  &:before {
    content: "";
    position: absolute;
    border-left: 7px solid rgba(113,101,58,0);
    border-right: 7px solid rgba(113,101,58,0);
    border-bottom: 7px solid $color_pumice_approx;
    left: 87px;
    top: -7px; } }


.ui-autocomplete {
  font-family: $font_0, $font_5, $font_2; }

.shops-content {
  font-family: $font_15, $font_5, $font_2; }

.your-region {
  font-size: 12px;
  padding-bottom: 5px; }

.thumbnail-label + .thumbnail-meta {
  padding-top: 10px; }

button .icon {
  cursor: pointer; }

table.windows-table {
  font-size: 12px;
  tr > td {
    border: 0;
    padding: 6px 2px 6px 4px;
    background: $color_athens_gray_approx;
    &:first-child {
      background: $color_athens_gray_approx;
      font-weight: 700;
      width: auto; } } }

.text-container h2 {
  font-family: $font_11;
  font-weight: 700;
  font-size: 23.5px;
  color: $black; }

#ratingSorter.active {
  cursor: default; }

#shopSorter.active {
  cursor: default; }

div.regions-container {
  padding: 0 15px 15px; }

input.regions {
  border-color: $color_alto_approx;
  //If you use compass, instead of the line below you could use + border-radius($radius, $vertical-radius)
  border-radius: 0; }

.archiveAndTests-header {

  & .title {
    width: 150px;
    float: left;
    line-height: 35px; }

  & .form-item {
    float: right; } }

.params__row2 {
  display: table-row;
  padding-top: 20px; }

.params__type2 {
  font-weight: 700;
  position: relative;
  width: 320px;
  margin-top: 20px;
  span {
    position: relative;
    z-index: 2;
    padding-right: 3px;
    background-color: white; }
  &:after {
    position: relative;
    display: block;
    content: '';
    height: 1px;
    left: 0;
    righ: 0;
    margin-top: -5px;
    border-bottom: 1px dotted #afafb0;
    overflow: hidden; } }

.params__desc2 {
  width: 200px;
  display: table-cell;
  vertical-align: bottom;
  padding-top: 20px; }

.params__dots2 {
  padding-top: 11px; }

.offers-filter {
  margin-top: -3px;
  font-size: 19px;
  font-family: proxima-nova;
  &__row {
    display: flex; }
  &__col {
    flex: 0 0 50%; }
  &__title {
    margin-bottom: 10px; }
  &__list {
    display: flex;
    flex-wrap: wrap; }
  &__list_memory &__check {
    margin: 0 10px 10px 0; }
  &__list_memory &__input_active {
    background-color: #faf1f2 !important;
    border: 1px solid #f57b8a !important;
    box-shadow: 0 0 5.8px 0.2px rgba(189, 2, 23, 0.18);
    font-weight: 700; }
  &__list_memory &__value {
    min-width: 70px;
    height: 32px;
    padding: 0 5px;
    background: white;
    border: 1px solid $border;
    border-radius: 2px;
    text-align: center;
    line-height: 32px; }
  &__list_memory &__check.disabled &__value {
    &:before {
      transform: translate(-50%,-50%) rotate(23deg); }
    &:after {
      transform: translate(-50%,-50%) rotate(68deg); } }
  &__list_colors &__check {
    margin: 3px 6px 10px 0; }
  &__list_colors &__value_active {
    box-shadow: 0 0 0 1px white, 0 0 0 3px rgba(#f57b8a,.5); }
  &__list_colors &__value {
    min-width: 26px;
    min-height: 26px;
    border-radius: 4px; }
  &__check {
    user-select: none;
    cursor: pointer; }
  &__check.disabled &__value {
    cursor: default;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      top: 50%;
      left: 50%;
      background: black; }
    &:before {
      width: 100px;
      height: 1px;
      transform: translate(-50%,-50%) rotate(45deg); }
    &:after {
      width: 1px;
      height: 100px;
      transform: translate(-50%,-50%) rotate(45deg); } }
  &__check.disabled.white &__value {
    &:before,
    &:after {
      background: white; } }
  &__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }
  &__value {
    display: block;
    position: relative;
    transition: all .2s;
    overflow: hidden;
    &[style="background-color: white;"],
    &[style="background-color: white"],
    &[style="background-color: #ffffff;"],
    &[style="background-color: #ffffff"],
    &[style="background-color: #fff"] {
      border: 1px solid $border; } }
  &_sm &__title {
    margin-bottom: 5px; }
  &_sm &__list_colors &__check {
    margin: 0 5px 5px 0;
    //+m
 }    //  margin: 0 8px 8px 0
  &_sm &__list_colors &__value {
    min-width: 16px;
    min-height: 16px;
    //+m
    //  min-width: 19px
 }    //  min-height: 19px
  &_sm &__list_colors &__input:checked + &__value {
    box-shadow: 0 0 0 1px white, 0 0 0 2px rgba(#f57b8a,.5); } }
