#imagelightbox {
  cursor: pointer;
  position: fixed;
  z-index: 1999;
  -ms-touch-action: none;
  touch-action: none;
  position: relative; }

#imagelightbox-loading {
  position: absolute;
  z-index: 2003;
  top: 50%;
  left: 50%;
  border: 1px solid #ccc;
  border-left-color: #000;
  border-radius: 974px;
  -o-border-radius: 974px;
  -ms-border-radius: 974px;
  -webkit-border-radius: 974px;
  -moz-border-radius: 974px;
  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 1px solid #ccc;
    border-left-color: #000;
    border-radius: 974px;
    -o-border-radius: 974px;
    -ms-border-radius: 974px;
    -webkit-border-radius: 974px;
    -moz-border-radius: 974px; }
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 1px solid #ccc;
    border-left-color: #000;
    border-radius: 974px;
    -o-border-radius: 974px;
    -ms-border-radius: 974px;
    -webkit-border-radius: 974px;
    -moz-border-radius: 974px;
    content: "";
    margin: -22px 0 0 -22px;
    height: 43px;
    width: 43px;
    animation: cssload-rotate 2.2s linear infinite;
    -o-animation: cssload-rotate 2.2s linear infinite;
    -ms-animation: cssload-rotate 2.2s linear infinite;
    -webkit-animation: cssload-rotate 2.2s linear infinite;
    -moz-animation: cssload-rotate 2.2s linear infinite; }
  margin: -24px 0 0 -24px;
  height: 49px;
  width: 49px;
  animation: cssload-rotate 2.2s linear infinite;
  -o-animation: cssload-rotate 2.2s linear infinite;
  -ms-animation: cssload-rotate 2.2s linear infinite;
  -webkit-animation: cssload-rotate 2.2s linear infinite;
  -moz-animation: cssload-rotate 2.2s linear infinite;
  &::after {
    content: "";
    margin: -28px 0 0 -28px;
    height: 55px;
    width: 55px;
    animation: cssload-rotate 4.4s linear infinite;
    -o-animation: cssload-rotate 4.4s linear infinite;
    -ms-animation: cssload-rotate 4.4s linear infinite;
    -webkit-animation: cssload-rotate 4.4s linear infinite;
    -moz-animation: cssload-rotate 4.4s linear infinite; } }

@keyframes cssload-rotate {
  100% {
    transform: rotate(360deg); } }


@-o-keyframes cssload-rotate {
  100% {
    -o-transform: rotate(360deg); } }


@-ms-keyframes cssload-rotate {
  100% {
    -ms-transform: rotate(360deg); } }


@-webkit-keyframes cssload-rotate {
  100% {
    -webkit-transform: rotate(360deg); } }


@-moz-keyframes cssload-rotate {
  100% {
    -moz-transform: rotate(360deg); } }

#imagezoom {
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 10px;
  height: auto !important; }

.image #imagezoom {
  margin-bottom: 0;
  margin-top: 0; }

#imagelightbox-overlay {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  z-index: 1998;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }


#imagelightbox-popup {
  z-index: 1999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.zoom-dialog {
  width: 980px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -490px;
  margin-top: -308px;
  z-index: 2000; }

.zoom-content {
  position: relative;
  background: #383839; }

.zoom-image {
  position: relative;
  width: 980px;
  height: 615px;
  background: #fff; }

.zoom-image img {
  max-width: 980px;
  max-height: 615px;
  cursor: pointer;
  height: auto;
  border: 0; }

.zoom-caption {
  border: 0;
  color: #fff;
  font-size: 13px;
  line-height: 15px;
  min-height: 51px; }

.zoom-label {
  padding: 18px 20px; }

#imagelightbox-close {
  position: absolute;
  z-index: 2001;
  top: 20px;
  right: 20px;
  background: none;
  border: none; }

/* NAVIGATION */

#imagelightbox-nav {
  position: absolute;
  z-index: 2001;
  bottom: 4.4em;
  padding: 0;
  width: 100%;
  text-align: center;
  button {
    margin: 0 2px;
    width: 19px;
    height: 19px;
    overflow: hidden;
    border: 2px solid #989898;
    background: transparent url(../img/basic/alpha.gif) repeat 0 0;
    text-indent: -3000px;
    vertical-align: middle;
    cursor: pointer;
    border-radius: 19px;
    padding: 0;
    display: inline-block;
    &.active {
      margin: 0 8px;
      width: 7px;
      height: 7px;
      border: 0;
      background: #e1e4e7;
      cursor: default; } } }

/* ARROWS */

.imagelightbox-arrow {
  vertical-align: middle;
  display: none;
  position: absolute;
  z-index: 2001;
  top: 278px;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  width: 44px;
  height: 44px;
  .btn {
    margin: 0; }
  &:hover, &:focus, &:active {
    background: none;
    border: none; } }

.imagelightbox-arrow-left {
  left: 2%;
  /* 40 */ }

.imagelightbox-arrow-right {
  right: 2%;
  /* 40 */ }

#imagelightbox-loading, #imagelightbox-overlay, #imagelightbox-popup, #imagelightbox-close, #imagelightbox-caption, #imagelightbox-nav, .imagelightbox-arrow {
  -webkit-animation: fade-in .25s linear;
  animation: fade-in .25s linear; }

@-webkit-keyframes fade-in {
  from {
    opacity: 0; }

  to {
    opacity: 1; } }


@keyframes fade-in {
  from {
    opacity: 0; }

  to {
    opacity: 1; } }
