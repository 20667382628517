$icons: (0:0);
$icons: map-merge($icons,(arrow-down-blue: (X: -315px, Y:-188px, W: 22px, H: 12px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(arrow-down-mob: (X: -315px, Y:-204px, W: 22px, H: 12px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(arrow-down-red-mob: (X: -315px, Y:-220px, W: 22px, H: 12px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(arrow-down-red: (X: -204px, Y:-124px, W: 9px, H: 6px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(arrow-right-black: (X: -292px, Y:0px, W: 19px, H: 32px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(arrow-right-blue: (X: -333px, Y:-75px, W: 4px, H: 7px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(arrow-right-gray: (X: -333px, Y:-97px, W: 3px, H: 5px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(arrow-right-mob: (X: -292px, Y:-36px, W: 18px, H: 30px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(arrow-right-red-mob: (X: -315px, Y:-236px, W: 12px, H: 22px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(arrow-right-red: (X: -174px, Y:-226px, W: 6px, H: 9px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(arrow-right-white: (X: -315px, Y:-262px, W: 12px, H: 22px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(arrow-top-gray: (X: 0px, Y:-281px, W: 32px, H: 18px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(back-mob: (X: -252px, Y:-152px, W: 23px, H: 38px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(btn-arrow-down: (X: -168px, Y:-158px, W: 7px, H: 4px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(btn-arrow-right: (X: -333px, Y:-86px, W: 4px, H: 7px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(car: (X: -292px, Y:-112px, W: 19px, H: 12px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(cart: (X: -223px, Y:-168px, W: 25px, H: 25px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(check: (X: -292px, Y:-232px, W: 13px, H: 10px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(close-mob: (X: -252px, Y:-80px, W: 34px, H: 34px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(compare: (X: -315px, Y:-168px, W: 21px, H: 16px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(delete: (X: -160px, Y:-226px, W: 10px, H: 10px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(delivery-mob: (X: -168px, Y:-135px, W: 45px, H: 19px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(delivery: (X: -292px, Y:-128px, W: 19px, H: 12px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(dreams: (X: -315px, Y:-75px, W: 14px, H: 27px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(eye: (X: -267px, Y:-227px, W: 14px, H: 8px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(facebook: (X: -292px, Y:-264px, W: 11px, H: 11px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(fav: (X: -292px, Y:-90px, W: 14px, H: 18px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(fb-mob: (X: 0px, Y:0px, W: 80px, H: 80px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(filters-mob: (X: -252px, Y:-118px, W: 32px, H: 30px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(gp-mob: (X: -84px, Y:0px, W: 80px, H: 80px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(in-trend: (X: -252px, Y:-194px, W: 29px, H: 29px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(logo-mob: (X: 0px, Y:-168px, W: 219px, H: 27px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(logo-white: (X: 0px, Y:-226px, W: 156px, H: 17px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(logo: (X: 0px, Y:-199px, W: 220px, H: 23px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(menu-arrow-down: (X: -331px, Y:-254px, W: 5px, H: 3px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(menu-arrow-left: (X: -292px, Y:-200px, W: 13px, H: 12px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(menu-arrow-right: (X: -292px, Y:-216px, W: 13px, H: 12px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(menu: (X: 0px, Y:-247px, W: 40px, H: 30px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(minus-mob: (X: -204px, Y:-84px, W: 36px, H: 36px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(minus-red-lg: (X: -224px, Y:-199px, W: 22px, H: 22px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(minus-red: (X: -292px, Y:-144px, W: 15px, H: 15px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(minus-simple: (X: -217px, Y:-124px, W: 14px, H: 2px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(ok-mob: (X: 0px, Y:-84px, W: 80px, H: 80px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(pg-arr-left-active: (X: -305px, Y:-246px, W: 5px, H: 13px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(pg-arr-left: (X: -331px, Y:-236px, W: 5px, H: 14px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(pg-arr-right-active: (X: -281px, Y:-247px, W: 5px, H: 13px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(pg-arr-right: (X: -331px, Y:-262px, W: 5px, H: 14px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(phone-gray: (X: -231px, Y:-135px, W: 10px, H: 11px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(plus-green-lg: (X: -315px, Y:0px, W: 22px, H: 22px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(plus-green: (X: -292px, Y:-163px, W: 15px, H: 15px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(plus-mob: (X: -252px, Y:0px, W: 36px, H: 36px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(plus-simple: (X: -292px, Y:-182px, W: 14px, H: 14px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(point: (X: -292px, Y:-246px, W: 9px, H: 14px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(search-mob: (X: -252px, Y:-40px, W: 36px, H: 36px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(search-sm: (X: -292px, Y:-70px, W: 16px, H: 16px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(search: (X: -315px, Y:-26px, W: 21px, H: 21px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(slider-arrow-next: (X: -315px, Y:-106px, W: 14px, H: 27px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(slider-arrow-prev: (X: -315px, Y:-137px, W: 14px, H: 27px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(sort-arrow-down: (X: -279px, Y:-152px, W: 7px, H: 10px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(sort-arrow-up: (X: -279px, Y:-166px, W: 7px, H: 10px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(star-blue-empty: (X: -44px, Y:-247px, W: 30px, H: 28px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(star-blue-full: (X: -78px, Y:-247px, W: 30px, H: 28px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(star-red-empty: (X: -112px, Y:-247px, W: 30px, H: 28px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(star-red-full: (X: -146px, Y:-247px, W: 30px, H: 28px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(star-yellow-empty: (X: -180px, Y:-247px, W: 30px, H: 28px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(star-yellow-full: (X: -214px, Y:-247px, W: 30px, H: 28px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(tablet: (X: -168px, Y:-84px, W: 32px, H: 47px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(tick-mob: (X: -248px, Y:-247px, W: 29px, H: 26px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(tick: (X: -279px, Y:-180px, W: 9px, H: 7px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(tw-mob: (X: -84px, Y:-84px, W: 80px, H: 80px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(twitter: (X: -315px, Y:-288px, W: 14px, H: 11px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(user: (X: -217px, Y:-135px, W: 10px, H: 12px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(vk-mob: (X: -168px, Y:0px, W: 80px, H: 80px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(vk: (X: -252px, Y:-227px, W: 11px, H: 11px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));
$icons: map-merge($icons,(zoom: (X: -315px, Y:-51px, W: 20px, H: 20px, TW: 337px, TH: 299px, IMG: '../img/icons-f0d765142895b1ad442bbd0f7e046dff.png')));


// Gets an attribute from the sass map
@function icon-attr($icon, $attr) {
	$icon: map-get($icons, $icon);
	@return map-get($icon, $attr); }

@mixin sprite($iconName) {
	background-image: url(icon-attr($iconName, IMG));
	width: icon-attr($iconName, W);
	height: icon-attr($iconName, H);
	background-position: icon-attr($iconName, X) icon-attr($iconName, Y); }
@mixin sprite-position($iconName) {
	background-position: icon-attr($iconName, X) icon-attr($iconName, Y); }
@mixin sprite-retina($iconName) {
	background-image: url(icon-attr($iconName, IMG));
	$width: icon-attr($iconName, W);
	$height: icon-attr($iconName, H);
	width: $width/2;
	height: $height/2;
	$x: icon-attr($iconName, X);
	$y: icon-attr($iconName, Y);
	background-position: $x/2 $y/2;
	$tw: icon-attr($iconName, TW);
	$th: icon-attr($iconName, TH);
	background-size: $tw/2 $th/2; }


@mixin s($i) {
	@include sprite($i); }
@mixin sp($i) {
	@include sprite-position($i); }
@mixin sr($i) {
	@include sprite-retina($i); }

.ico-arrow-down-blue {
	width: 22px;
	height: 12px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -315px -188px; }
.ico-arrow-down-blue-r {
  @include sr(arrow-down-blue); }
.ico-arrow-down-mob {
	width: 22px;
	height: 12px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -315px -204px; }
.ico-arrow-down-mob-r {
  @include sr(arrow-down-mob); }
.ico-arrow-down-red-mob {
	width: 22px;
	height: 12px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -315px -220px; }
.ico-arrow-down-red-mob-r {
  @include sr(arrow-down-red-mob); }
.ico-arrow-down-red {
	width: 9px;
	height: 6px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -204px -124px; }
.ico-arrow-down-red-r {
  @include sr(arrow-down-red); }
.ico-arrow-right-black {
	width: 19px;
	height: 32px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -292px 0px; }
.ico-arrow-right-black-r {
  @include sr(arrow-right-black); }
.ico-arrow-right-blue {
	width: 4px;
	height: 7px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -333px -75px; }
.ico-arrow-right-blue-r {
  @include sr(arrow-right-blue); }
.ico-arrow-right-gray {
	width: 3px;
	height: 5px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -333px -97px; }
.ico-arrow-right-gray-r {
  @include sr(arrow-right-gray); }
.ico-arrow-right-mob {
	width: 18px;
	height: 30px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -292px -36px; }
.ico-arrow-right-mob-r {
  @include sr(arrow-right-mob); }
.ico-arrow-right-red-mob {
	width: 12px;
	height: 22px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -315px -236px; }
.ico-arrow-right-red-mob-r {
  @include sr(arrow-right-red-mob); }
.ico-arrow-right-red {
	width: 6px;
	height: 9px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -174px -226px; }
.ico-arrow-right-red-r {
  @include sr(arrow-right-red); }
.ico-arrow-right-white {
	width: 12px;
	height: 22px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -315px -262px; }
.ico-arrow-right-white-r {
  @include sr(arrow-right-white); }
.ico-arrow-top-gray {
	width: 32px;
	height: 18px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: 0px -281px; }
.ico-arrow-top-gray-r {
  @include sr(arrow-top-gray); }
.ico-back-mob {
	width: 23px;
	height: 38px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -252px -152px; }
.ico-back-mob-r {
  @include sr(back-mob); }
.ico-btn-arrow-down {
	width: 7px;
	height: 4px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -168px -158px; }
.ico-btn-arrow-down-r {
  @include sr(btn-arrow-down); }
.ico-btn-arrow-right {
	width: 4px;
	height: 7px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -333px -86px; }
.ico-btn-arrow-right-r {
  @include sr(btn-arrow-right); }
.ico-car {
	width: 19px;
	height: 12px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -292px -112px; }
.ico-car-r {
  @include sr(car); }
.ico-cart {
	width: 25px;
	height: 25px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -223px -168px; }
.ico-cart-r {
  @include sr(cart); }
.ico-check {
	width: 13px;
	height: 10px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -292px -232px; }
.ico-check-r {
  @include sr(check); }
.ico-close-mob {
	width: 34px;
	height: 34px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -252px -80px; }
.ico-close-mob-r {
  @include sr(close-mob); }
.ico-compare {
	width: 21px;
	height: 16px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -315px -168px; }
.ico-compare-r {
  @include sr(compare); }
.ico-delete {
	width: 10px;
	height: 10px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -160px -226px; }
.ico-delete-r {
  @include sr(delete); }
.ico-delivery-mob {
	width: 45px;
	height: 19px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -168px -135px; }
.ico-delivery-mob-r {
  @include sr(delivery-mob); }
.ico-delivery {
	width: 19px;
	height: 12px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -292px -128px; }
.ico-delivery-r {
  @include sr(delivery); }
.ico-dreams {
	width: 14px;
	height: 27px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -315px -75px; }
.ico-dreams-r {
  @include sr(dreams); }
.ico-eye {
	width: 14px;
	height: 8px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -267px -227px; }
.ico-eye-r {
  @include sr(eye); }
.ico-facebook {
	width: 11px;
	height: 11px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -292px -264px; }
.ico-facebook-r {
  @include sr(facebook); }
.ico-fav {
	width: 14px;
	height: 18px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -292px -90px; }
.ico-fav-r {
  @include sr(fav); }
.ico-fb-mob {
	width: 80px;
	height: 80px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: 0px 0px; }
.ico-fb-mob-r {
  @include sr(fb-mob); }
.ico-filters-mob {
	width: 32px;
	height: 30px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -252px -118px; }
.ico-filters-mob-r {
  @include sr(filters-mob); }
.ico-gp-mob {
	width: 80px;
	height: 80px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -84px 0px; }
.ico-gp-mob-r {
  @include sr(gp-mob); }
.ico-in-trend {
	width: 29px;
	height: 29px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -252px -194px; }
.ico-in-trend-r {
  @include sr(in-trend); }
.ico-logo-mob {
	width: 219px;
	height: 27px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: 0px -168px; }
.ico-logo-mob-r {
  @include sr(logo-mob); }
.ico-logo-white {
	width: 156px;
	height: 17px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: 0px -226px; }
.ico-logo-white-r {
  @include sr(logo-white); }
.ico-logo {
	width: 220px;
	height: 23px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: 0px -199px; }
.ico-logo-r {
  @include sr(logo); }
.ico-menu-arrow-down {
	width: 5px;
	height: 3px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -331px -254px; }
.ico-menu-arrow-down-r {
  @include sr(menu-arrow-down); }
.ico-menu-arrow-left {
	width: 13px;
	height: 12px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -292px -200px; }
.ico-menu-arrow-left-r {
  @include sr(menu-arrow-left); }
.ico-menu-arrow-right {
	width: 13px;
	height: 12px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -292px -216px; }
.ico-menu-arrow-right-r {
  @include sr(menu-arrow-right); }
.ico-menu {
	width: 40px;
	height: 30px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: 0px -247px; }
.ico-menu-r {
  @include sr(menu); }
.ico-minus-mob {
	width: 36px;
	height: 36px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -204px -84px; }
.ico-minus-mob-r {
  @include sr(minus-mob); }
.ico-minus-red-lg {
	width: 22px;
	height: 22px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -224px -199px; }
.ico-minus-red-lg-r {
  @include sr(minus-red-lg); }
.ico-minus-red {
	width: 15px;
	height: 15px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -292px -144px; }
.ico-minus-red-r {
  @include sr(minus-red); }
.ico-minus-simple {
	width: 14px;
	height: 2px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -217px -124px; }
.ico-minus-simple-r {
  @include sr(minus-simple); }
.ico-ok-mob {
	width: 80px;
	height: 80px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: 0px -84px; }
.ico-ok-mob-r {
  @include sr(ok-mob); }
.ico-pg-arr-left-active {
	width: 5px;
	height: 13px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -305px -246px; }
.ico-pg-arr-left-active-r {
  @include sr(pg-arr-left-active); }
.ico-pg-arr-left {
	width: 5px;
	height: 14px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -331px -236px; }
.ico-pg-arr-left-r {
  @include sr(pg-arr-left); }
.ico-pg-arr-right-active {
	width: 5px;
	height: 13px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -281px -247px; }
.ico-pg-arr-right-active-r {
  @include sr(pg-arr-right-active); }
.ico-pg-arr-right {
	width: 5px;
	height: 14px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -331px -262px; }
.ico-pg-arr-right-r {
  @include sr(pg-arr-right); }
.ico-phone-gray {
	width: 10px;
	height: 11px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -231px -135px; }
.ico-phone-gray-r {
  @include sr(phone-gray); }
.ico-plus-green-lg {
	width: 22px;
	height: 22px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -315px 0px; }
.ico-plus-green-lg-r {
  @include sr(plus-green-lg); }
.ico-plus-green {
	width: 15px;
	height: 15px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -292px -163px; }
.ico-plus-green-r {
  @include sr(plus-green); }
.ico-plus-mob {
	width: 36px;
	height: 36px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -252px 0px; }
.ico-plus-mob-r {
  @include sr(plus-mob); }
.ico-plus-simple {
	width: 14px;
	height: 14px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -292px -182px; }
.ico-plus-simple-r {
  @include sr(plus-simple); }
.ico-point {
	width: 9px;
	height: 14px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -292px -246px; }
.ico-point-r {
  @include sr(point); }
.ico-search-mob {
	width: 36px;
	height: 36px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -252px -40px; }
.ico-search-mob-r {
  @include sr(search-mob); }
.ico-search-sm {
	width: 16px;
	height: 16px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -292px -70px; }
.ico-search-sm-r {
  @include sr(search-sm); }
.ico-search {
	width: 21px;
	height: 21px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -315px -26px; }
.ico-search-r {
  @include sr(search); }
.ico-slider-arrow-next {
	width: 14px;
	height: 27px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -315px -106px; }
.ico-slider-arrow-next-r {
  @include sr(slider-arrow-next); }
.ico-slider-arrow-prev {
	width: 14px;
	height: 27px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -315px -137px; }
.ico-slider-arrow-prev-r {
  @include sr(slider-arrow-prev); }
.ico-sort-arrow-down {
	width: 7px;
	height: 10px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -279px -152px; }
.ico-sort-arrow-down-r {
  @include sr(sort-arrow-down); }
.ico-sort-arrow-up {
	width: 7px;
	height: 10px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -279px -166px; }
.ico-sort-arrow-up-r {
  @include sr(sort-arrow-up); }
.ico-star-blue-empty {
	width: 30px;
	height: 28px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -44px -247px; }
.ico-star-blue-empty-r {
  @include sr(star-blue-empty); }
.ico-star-blue-full {
	width: 30px;
	height: 28px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -78px -247px; }
.ico-star-blue-full-r {
  @include sr(star-blue-full); }
.ico-star-red-empty {
	width: 30px;
	height: 28px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -112px -247px; }
.ico-star-red-empty-r {
  @include sr(star-red-empty); }
.ico-star-red-full {
	width: 30px;
	height: 28px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -146px -247px; }
.ico-star-red-full-r {
  @include sr(star-red-full); }
.ico-star-yellow-empty {
	width: 30px;
	height: 28px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -180px -247px; }
.ico-star-yellow-empty-r {
  @include sr(star-yellow-empty); }
.ico-star-yellow-full {
	width: 30px;
	height: 28px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -214px -247px; }
.ico-star-yellow-full-r {
  @include sr(star-yellow-full); }
.ico-tablet {
	width: 32px;
	height: 47px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -168px -84px; }
.ico-tablet-r {
  @include sr(tablet); }
.ico-tick-mob {
	width: 29px;
	height: 26px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -248px -247px; }
.ico-tick-mob-r {
  @include sr(tick-mob); }
.ico-tick {
	width: 9px;
	height: 7px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -279px -180px; }
.ico-tick-r {
  @include sr(tick); }
.ico-tw-mob {
	width: 80px;
	height: 80px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -84px -84px; }
.ico-tw-mob-r {
  @include sr(tw-mob); }
.ico-twitter {
	width: 14px;
	height: 11px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -315px -288px; }
.ico-twitter-r {
  @include sr(twitter); }
.ico-user {
	width: 10px;
	height: 12px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -217px -135px; }
.ico-user-r {
  @include sr(user); }
.ico-vk-mob {
	width: 80px;
	height: 80px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -168px 0px; }
.ico-vk-mob-r {
  @include sr(vk-mob); }
.ico-vk {
	width: 11px;
	height: 11px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -252px -227px; }
.ico-vk-r {
  @include sr(vk); }
.ico-zoom {
	width: 20px;
	height: 20px;
	background-image: url('../img/icons-f0d765142895b1ad442bbd0f7e046dff.png');
	background-position: -315px -51px; }
.ico-zoom-r {
  @include sr(zoom); }
