@mixin d {
  body:not(.no-media) & {
    @media only screen and (min-width: "741px") {
      @content; } } }

.prop-color {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 1px solid #b7b7b7;
  border-radius: 2px;
  vertical-align: middle;
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 1px solid #b7b7b7;
  border-radius: 2px;
  vertical-align: middle; }

.cselect {
  margin-bottom: 10px;
  position: relative;
  z-index: 5;
  &.expand {
    z-index: 6;
    &:before {
      content: '';
      background: rgba(0, 0, 0, 0);
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1; } } }

.cselect-label {
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle; }

.cselect-field {
  position: relative;
  border: 1px solid #cfcfcf;
  display: inline-block;
  background: #f7f7f7;
  height: 24px;
  border-radius: 13px;
  vertical-align: middle;
  padding: 2px 36px 0px 14px;
  cursor: pointer;
  &:after {
    content: "\25BC";
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #0071bb;
    position: absolute;
    top: 6px;
    right: 10px;
    font-size: 70%; } }

.cselect-drop-down {
  display: none;
  position: absolute;
  top: 23px;
  left: -1px;
  right: -1px;
  background: #f7f7f7;
  border: 1px solid #cfcfcf;
  border-top: none;
  padding: 2px 0px 2px 14px;
  border-radius: 0 0 13px 13px;
  > a {
    display: block;
    color: #000 !important;
    text-decoration: none !important; } }

.cselect.expand {
  .cselect-field {
    border-bottom: none;
    border-radius: 13px 13px 0 0;
    &:after {
      content: "\25B2"; } }
  .cselect-drop-down {
    display: block; } }

.cselect-blue {
  .cselect-field {
    border: none;
    background: #0071bb;
    color: #fff;
    padding-right: 15px; }
  .fa {
    opacity: 0.5;
    font-size: 80%; }
  .cselect-field:after {
    content: '\2193';
    position: static;
    font-size: 100%;
    color: #fff; }
  &.desc .cselect-field:after {
    content: '\2191'; }
  .cselect-drop-down {
    background: #0071bb;
    border: none;
    > a {
      color: #fff !important; } } }

.cselect.expand {
  &.cselect-blue .cselect-field, .cselect-field {
    z-index: 2; } }

.cselect-white {
  .cselect-field {
    border: 1px solid #ebebeb;
    background: #fff;
    border-radius: 3px;
    padding: 2px 14px 0px 14px;
    &:after {
      display: none; } }
  &.cselect.expand .cselect-field {
    border-bottom: none;
    border-radius: 3px 3px 0 0; }
  .cselect-drop-down {
    border-radius: 0 0 3px 3px;
    background: #fff; } }

.detail-filter {
  display: block;
  border: 1px solid #cfcfcf;
  border-radius: 2px;
  position: relative;
  height: 34px;
  background: #f8f8f8;
  padding: 1px 10px;
  margin-bottom: 15px;
  z-index: 5;
  width: 275px;
  &.expand {
    background: #fff; } }

.detail-filter-label {
  font-size: 22px;
  font-weight: 700;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  height: 31px;
  white-space: nowrap;
  padding-right: 20px; }


.detail-filter-label .icon-chevron-down {
  width: 16px;
  height: 29px;
  position: absolute;
  right: 0px;
  top: 1px; }


.detail-filter-label .icon-chevron-up {
  display: none;
  width: 16px;
  height: 29px;
  position: absolute;
  right: 0px;
  top: 1px; }

.detail-filter.expand .detail-filter-label .icon-chevron-down {
  display: none; }

.detail-filter.expand .detail-filter-label .icon-chevron-up {
  display: block; }

.detail-filter-dropdown {
  display: none;
  border: 1px solid #cfcfcf;
  position: absolute;
  top: 34px;
  left: -1px;
  right: -1px;
  background: #fff;
  z-index: 2; }

.detail-filter.expand .detail-filter-dropdown {
  display: block; }

.detail-filter-label2 {
  height: 32px;
  font-size: 16px;
  padding: 5px 20px;
  cursor: pointer;
  position: relative;
  &:hover {
    background: #f3f3f3; } }

.detail-filter-label2 .icon-chevron-right {
  width: 16px;
  height: 16px;
  fill: #8c8c8c;
  position: absolute;
  right: 6px;
  top: 6px; }

.detail-filter-dropdown2 {
  display: none;
  position: absolute;
  z-index: 3;
  background: #fff;
  left: 100%;
  top: -1px;
  border: 1px solid #cfcfcf;
  padding: 8px;
  width: 190px; }

.detail-filter-label2.expand + .detail-filter-dropdown2, .detail-filter-dropdown2 > label {
  display: block; }

.detail-filter input {
  &[type=checkbox], &[type=radio] {
    display: none; }
  &[type=checkbox] + label, &[type=radio] + label {
    position: relative;
    display: block;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    padding: 4px 20px; }
  &[type=checkbox] + label .icon-square, &[type=radio] + label .icon-square {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0px;
    top: 3px;
    fill: #bd0217; }
  &[type=checkbox] + label .icon-check-square, &[type=radio] + label .icon-check-square {
    display: none; }
  &[type=checkbox]:checked + label .icon-check-square, &[type=radio]:checked + label .icon-check-square {
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0px;
    top: 3px;
    fill: #bd0217; }
  &[type=checkbox]:checked + label .icon-square, &[type=radio]:checked + label .icon-square {
    display: none; }
  &[type=checkbox]:disabled + label .icon-square, &[type=radio]:disabled + label .icon-square {
    fill: #8c8c8c; } }

.detail-filter-label2 .value + .value:before, .detail-filter-label .value + .value:before {
  content: ', '; }

.detail-filter-label2 {
  .value:first-child:before {
    content: ': '; }
  &.active {
    font-weight: 700;
    &:before {
      content: "\25CF";
      padding-right: 0.5em;
      position: absolute;
      top: 7px;
      left: 8px;
      font-size: 12px;
      color: #000; } } }

.detail-filter-label.active > .detail-filter-label-placeholder {
  display: none; }

.color-yellow {
  background-color: #ffde00 !important; }

.color-black {
  background-color: #000 !important; }

.color-blue {
  background-color: #0000ff !important; }

.color-red {
  background-color: #ff0000 !important; }

.color-lilac {
  background-color: #81589f !important; }

.color-green {
  background-color: #52ac62 !important; }

.detail-duplex-filter {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 310px;
  margin-bottom: 15px;
  input[type=radio] {
    display: none;
    + label {
      display: inline-block;
      font-size: 16px;
      padding: 7px 10px;
      border: 1px solid #d7d7d7;
      border-radius: 2px;
      line-height: 1;
      cursor: pointer;
      margin-right: 7px;
      height: 32px; }
    &:checked {
      + label {
        border-color: #f57b8a;
        box-shadow: 0 0 6px rgba(189, 2, 23, 0.18);
        background: #faf1f2; }
      + label.filter-input-color {
        border-color: #fabdc4 !important; } }
    + label.filter-input-color {
      text-indent: 9999px;
      width: 32px;
      border: 2px solid #fff !important;
      box-shadow: 0 0 0 1px #fff inset; } } }

.detail-duplex-filter-label {
  margin-bottom: 3px;
  > .value {
    font-weight: 700;
    margin: 0; } }

.detail-duplex-filter-color .detail-duplex-filter-dropdown label > .prop-color + span {
  display: none; }

.detail-duplex-filter-item.detail-duplex-filter-color input[type=radio] + label {
  height: 32px;
  width: 32px;
  padding: 0;
  margin-right: 0; }

.detail-duplex-filter-color {
  label > .prop-color {
    height: 100%;
    width: 100%;
    border-color: #fff; }
  .detail-duplex-filter-label .prop-color {
    display: none; } }

.configuration-filter {
  @include d {
    max-width: 170px;
    float: left;
    label {
      min-width: 72px;
      margin-bottom: 9px; } } }

.detail-duplex-filter-color {
  @include d {
    max-width: 139px;
    float: right;
    label {
      margin-bottom: 4px; } } }

@media (max-width: 767px) {
  .cselect, .cselect-switch {
    margin: 0 -15px;
    padding: 0px;
    background: #f0f7fb;
    border-top: 1px solid #0071bb;
    text-align: center; }
  div:last-child > .cselect {
    border-bottom: 1px solid #0071bb; }
  .cselect-label {
    display: none; }
  .cselect-blue .cselect-field {
    background: transparent;
    border: none;
    color: #000;
    display: block;
    padding: 10px;
    height: 40px;
    border-radius: 0;
    background: #f0f7fb;
    border-radius: 0 !important; }
  .cselect-field {
    background: transparent;
    border: none;
    color: #000;
    display: block;
    padding: 10px;
    height: 40px;
    border-radius: 0;
    background: #f0f7fb;
    border-radius: 0 !important;
    &:after {
      position: static; } }
  .cselect-drop-down {
    z-index: 9;
    top: 41px;
    background: none;
    border: none !important;
    border-radius: 0;
    padding: 0;
    > a {
      z-index: 9;
      background: #f0f7fb;
      border-bottom: 1px solid #0071bb;
      height: 41px;
      padding: 10px;
      color: #000 !important; } }
  .cselect-blue .cselect-drop-down > a {
    z-index: 9;
    background: #f0f7fb;
    border-bottom: 1px solid #0071bb;
    height: 41px;
    padding: 10px;
    color: #000 !important; }
  .cselect.expand:before {
    background: rgba(0, 0, 0, 0.2); }
  .detail-filter {
    height: auto;
    padding: 4px 10px;
    width: 95%;
    margin: 0 auto 15px; }
  .detail-filter-dropdown {
    position: relative;
    top: 5px;
    left: auto;
    right: auto;
    margin: 0 -10px;
    border-left: none;
    border-right: none; }
  .detail-filter-label2 {
    border-top: 1px solid #cfcfcf;
    padding-top: 9px;
    height: 40px;
    &:first-child {
      border-top: none; }
    &.expand {
      background: #f3f3f3; } }
  .detail-filter-dropdown2 {
    position: relative;
    left: auto;
    right: auto;
    width: 100%;
    border: none; }
  .detail-filter-label2 {
    &:after {
      content: '';
      width: 16px;
      height: 19px;
      background-repeat: no-repeat;
      background-position: center; } }
  .detail-filter input {
    &[type=checkbox]:checked + label:before, &[type=radio]:checked + label:before {
      background-color: transparent;
      opacity: 0.2;
      box-shadow: none;
      width: 14px;
      height: 14px;
      opacity: 0.2;
      margin: 0px 5px -2px 0; } }
  .detail-filter-label2.active:before {
    top: 15px; }
  .cselect.cselect-white, .cselect-switch.cselect-white {
    border: 1px solid #e1e1e1 !important; }
  .cselect-white .cselect-field {
    background: #edeff6;
    padding-top: 9px;
    &:after {
      content: "\f160";
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit; } }
  .detail-duplex-filter {
    width: 100%;
    .detail-duplex-filter-item {
      width: 50%;
      border: 1px solid #e1e1e1;
      + .detail-duplex-filter-item {
        border-left: none; } }
    .detail-duplex-filter-color {
      width: 100%; } }
  .detail-duplex-filter-label.active > .name, .detail-duplex-filter-dropdown {
    display: none; }
  .detail-duplex-filter-label {
    text-align: center;
    margin: 0;
    font-size: 17px;
    height: 40px;
    line-height: 40px;
    background: #f8f9fd;
    cursor: pointer;
    &:after {
      content: "";
      font-style: normal;
      font-weight: normal;
      display: inline-block;
      margin: 0 0 0 6px;
      font-size: 80%;
      line-height: 24px;
      width: 16px;
      height: 17px;
      background-repeat: no-repeat;
      background-position: center;
      vertical-align: middle; } }
  .detail-duplex-filter-item.expand {
    &:before {
      content: '';
      background: rgba(66, 66, 66, 0.3);
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 6; }
    position: relative;
    .detail-duplex-filter-label {}
    .detail-duplex-filter-dropdown {
      display: inline-block; }
    .detail-duplex-filter-label {
      z-index: 8;
      position: relative; }
    .detail-duplex-filter-dropdown {
      display: block;
      position: absolute;
      top: auto;
      z-index: 8;
      background: #fff;
      left: 0;
      right: 0; } }
  .detail-duplex-filter-color {
    .detail-duplex-filter-dropdown label > .prop-color + span {
      display: inline-block; }
    label > .prop-color {
      width: 16px;
      height: 16px;
      margin: -3px 4px 0 0; } }
  .detail-duplex-filter-item {
    &.detail-duplex-filter-color input[type=radio] + label, input[type=radio] + label {
      display: block;
      width: auto;
      border: none;
      padding: 7px 15px;
      margin: 0;
      font-weight: normal; } }
  .detail-duplex-filter-color .detail-duplex-filter-label .prop-color {
    display: inline-block; }
  #offersMetaData {
    width: 50%; } }
