.loading-overlay {
  display: none; }

.loading .loading-overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  background: hsla(0, 0%, 100%, 0.6);
  border-radius: 5px;
  z-index: 10000;
  .spinner {
    -webkit-animation: a .8s infinite linear;
    animation: a .8s infinite linear;
    border: 8px solid #000028;
    border-right-color: transparent;
    border-radius: 50%;
    position: fixed;
    left: 50%;
    top: 50%;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    margin-top: -25px; } }

.aside_container .loading .loading-overlay {
  background: rgba(69, 69, 69, 0.45);
  .spinner {
    border: 8px solid #e7e7e7;
    border-right-color: transparent; } }

@keyframes a {
  0% {
    transform: rotate(0deg); }

  to {
    transform: rotate(1turn); } }

.cssload-container {
  position: relative;
  display: none;
  height: 136px; }

.cssload-whirlpool {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 1px solid rgb(204, 204, 204);
  border-left-color: rgb(0, 0, 0);
  border-radius: 974px;
  -o-border-radius: 974px;
  -ms-border-radius: 974px;
  -webkit-border-radius: 974px;
  -moz-border-radius: 974px;
  &::before, &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 1px solid rgb(204, 204, 204);
    border-left-color: rgb(0, 0, 0);
    border-radius: 974px;
    -o-border-radius: 974px;
    -ms-border-radius: 974px;
    -webkit-border-radius: 974px;
    -moz-border-radius: 974px; }
  margin: -24px 0 0 -24px;
  height: 49px;
  width: 49px;
  animation: cssload-rotate 2.2s linear infinite;
  -o-animation: cssload-rotate 2.2s linear infinite;
  -ms-animation: cssload-rotate 2.2s linear infinite;
  -webkit-animation: cssload-rotate 2.2s linear infinite;
  -moz-animation: cssload-rotate 2.2s linear infinite;
  &::before {
    content: "";
    margin: -22px 0 0 -22px;
    height: 43px;
    width: 43px;
    animation: cssload-rotate 2.2s linear infinite;
    -o-animation: cssload-rotate 2.2s linear infinite;
    -ms-animation: cssload-rotate 2.2s linear infinite;
    -webkit-animation: cssload-rotate 2.2s linear infinite;
    -moz-animation: cssload-rotate 2.2s linear infinite; }
  &::after {
    content: "";
    margin: -28px 0 0 -28px;
    height: 55px;
    width: 55px;
    animation: cssload-rotate 4.4s linear infinite;
    -o-animation: cssload-rotate 4.4s linear infinite;
    -ms-animation: cssload-rotate 4.4s linear infinite;
    -webkit-animation: cssload-rotate 4.4s linear infinite;
    -moz-animation: cssload-rotate 4.4s linear infinite; } }

@keyframes cssload-rotate {
  100% {
    transform: rotate(360deg); } }


@-o-keyframes cssload-rotate {
  100% {
    -o-transform: rotate(360deg); } }


@-ms-keyframes cssload-rotate {
  100% {
    -ms-transform: rotate(360deg); } }


@-webkit-keyframes cssload-rotate {
  100% {
    -webkit-transform: rotate(360deg); } }


@-moz-keyframes cssload-rotate {
  100% {
    -moz-transform: rotate(360deg); } }

.cssload-button {
  width: 100%;
  height: 100%;
  text-align: center;
  display: inline-block;
  padding-top: 3px; }

.cssload-zenith {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  border-radius: 50%;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  box-shadow: 2px 2px 1px rgb(255, 255, 255);
  animation: cssload-spin 1050ms infinite linear;
  -o-animation: cssload-spin 1050ms infinite linear;
  -ms-animation: cssload-spin 1050ms infinite linear;
  -webkit-animation: cssload-spin 1050ms infinite linear;
  -moz-animation: cssload-spin 1050ms infinite linear; }

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg); } }


@-o-keyframes cssload-spin {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }


@-ms-keyframes cssload-spin {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }


@-webkit-keyframes cssload-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }


@-moz-keyframes cssload-spin {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg); } }
