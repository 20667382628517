@-webkit-keyframes cm-spin {
  0% {
    -webkit-transform: translateY(-50%) rotate(0deg);
    transform: translateY(-50%) rotate(0deg); }

  100% {
    -webkit-transform: translateY(-50%) rotate(359deg);
    transform: translateY(-50%) rotate(359deg); } }


@-o-keyframes cm-spin {
  0% {
    -webkit-transform: translateY(-50%) rotate(0deg);
    -o-transform: translateY(-50%) rotate(0deg);
    transform: translateY(-50%) rotate(0deg); }

  100% {
    -webkit-transform: translateY(-50%) rotate(359deg);
    -o-transform: translateY(-50%) rotate(359deg);
    transform: translateY(-50%) rotate(359deg); } }


@keyframes cm-spin {
  0% {
    -webkit-transform: translateY(-50%) rotate(0deg);
    -o-transform: translateY(-50%) rotate(0deg);
    transform: translateY(-50%) rotate(0deg); }

  100% {
    -webkit-transform: translateY(-50%) rotate(359deg);
    -o-transform: translateY(-50%) rotate(359deg);
    transform: translateY(-50%) rotate(359deg); } }


.context-menu-list {
  position: absolute;
  display: inline-block;
  min-width: 13em;
  max-width: 26em;
  padding: .25em 0;
  margin: .3em;
  font-family: inherit;
  font-size: inherit;
  list-style-type: none;
  background: #fff;
  border: 1px solid #bebebe;
  border-radius: .2em;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5); }

.context-menu-item {
  position: relative;
  padding: .2em 2em;
  color: #2f2f2f;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff; }

.context-menu-separator {
  padding: 0;
  margin: .35em 0;
  border-bottom: 1px solid #e6e6e6; }

.context-menu-item {
  &.context-menu-hover {
    color: #fff;
    cursor: pointer;
    background-color: #2980b9; }
  &.context-menu-disabled {
    color: #bbb;
    cursor: default;
    background-color: #fff; } }

/* vertically align inside labels */

.context-menu-input > label {
  > * {
    vertical-align: top; }
  margin: 0;
  display: block;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.context-menu-item {
  > .context-menu-list {
    top: .3em;
    /* re-positioned by js */
    right: -.3em;
    display: none; }
  &.context-menu-visible > .context-menu-list {
    display: block; } }

.context-menu-accesskey {
  text-decoration: underline; }

.firefox {
  .context-menu-list {
    background: #f0f0f0;
    border: 1px solid #cccccc;
    border-radius: 0;
    -webkit-box-shadow: 5px 5px 4px -4px rgba(0, 0, 0, 0.715);
    -moz-box-shadow: 5px 5px 4px -4px rgba(0, 0, 0, 0.715);
    box-shadow: 5px 5px 4px -4px rgba(0, 0, 0, 0.715); }
  .context-menu-item {
    padding: .2em 2.2em;
    font-family: "Segoe UI", Arial, Calibri, "MS Sans Serif";
    font-size: 9pt;
    line-height: 13pt;
    background-color: #f0f0f0; }
  .context-menu-separator {
    margin: .35em .1em .35em 1.9em;
    border-bottom: 1px solid #d7d7d7; }
  .context-menu-item {
    &.context-menu-hover {
      color: #2f2f2f;
      background-color: #90c8f6; }
    &.context-menu-disabled {
      color: #6d6d6d;
      background-color: #f0f0f0; } } }

.chrome {
  .context-menu-list {
    background: #fff;
    border: 1px solid #bababa;
    border-radius: 0;
    -webkit-box-shadow: 5px 5px 4px -4px rgba(0, 0, 0, 0.715);
    -moz-box-shadow: 5px 5px 4px -4px rgba(0, 0, 0, 0.715);
    box-shadow: 5px 5px 4px -4px rgba(0, 0, 0, 0.715); }
  .context-menu-item {
    color: #000000;
    padding: 5px 2.2em;
    font-family: "Segoe UI", Arial, Calibri, "MS Sans Serif";
    font-size: 9pt;
    line-height: 11pt;
    background-color: #fff; }
  .context-menu-separator {
    margin: 0 .1em .35em .1em;
    border-bottom: 1px solid #e9e9e9; }
  .context-menu-item {
    &.context-menu-hover {
      color: #000000;
      background-color: #ebebeb; }
    &.context-menu-disabled {
      color: #a1a192;
      background-color: #fff; } } }

.safari {
  .context-menu-list {
    background: transparent;
    border: 1px solid #bebebe transparent;
    border-radius: 10px;
    -webkit-box-shadow: 0px 4px 13px 0px rgba(171, 171, 171, 0.86);
    -moz-box-shadow: 0px 4px 13px 0px rgba(171, 171, 171, 0.86);
    box-shadow: 0px 4px 13px 0px rgba(171, 171, 171, 0.86); }
  .context-menu-item {
    color: #000000;
    padding: 5px 2.2em;
    font-family: "Segoe UI", Arial, Calibri, "MS Sans Serif";
    font-size: 9pt;
    line-height: 11pt;
    background-color: transparent;
    z-index: 10; }
  .context-menu-separator {
    margin: 0 0 .35em 0;
    border-bottom: 2px solid #dfdfdf; }
  .context-menu-item {
    &.context-menu-hover {
      color: #ffffff;
      background-color: #4093f5; }
    &.context-menu-disabled {
      color: #a1a192;
      background-color: #fff; } } }

.android {
  .context-menu-list {
    padding-bottom: 15px;
    left: 50% !important;
    -ms-transform: translateX(-50%);
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    z-index: 99999!important;
    width: 80% !important; }
  .context-menu-separator {
    margin: 0 0 0 0;
    border-bottom: 0px; }
  #context-menu-layer {
    opacity: 0.7 !important;
    z-index: 99999 !important; }
  .context-menu-link {
    display: block;
    font-family: "Segoe UI", Arial, Calibri, "MS Sans Serif";
    font-size: 12px !important;
    max-height: 48px;
    overflow-x: hidden;
    overflow-y: scroll;
    overflow-wrap: break-word;
    padding: 0px 0em !important;
    margin: 0px -10px 0px !important; }
  .context-menu-item {
    color: #000000;
    padding: 5px 20px 5px;
    font-family: "Segoe UI", Arial, Calibri, "MS Sans Serif";
    font-size: 11pt;
    background-color: transparent; } }

.ios {
  .context-menu-list {
    background: transparent;
    border: 0px solid transparent !important;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 0px 0px rgba(171, 171, 171, 0.86);
    -moz-box-shadow: 0px 0px 0px 0px rgba(171, 171, 171, 0.86);
    box-shadow: 0px 0px 0px 0px rgba(171, 171, 171, 0.86);
    left: 50% !important;
    -ms-transform: translateX(-50%);
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    z-index: 99999 !important;
    width: 90% !important;
    min-height: 146px; }
  .context-menu-item {
    color: #007cff;
    padding: 5px 20px 5px;
    font-family: "Segoe UI", Arial, Calibri, "MS Sans Serif";
    font-size: 11pt;
    background-color: transparent;
    marging-bottom: 15px;
    z-index: 99999 !important; }
  .context-menu-link {
    display: block;
    color: #8b8b8b;
    font-family: "Segoe UI", Arial, Calibri, "MS Sans Serif";
    font-size: 12px !important;
    max-height: 48px;
    overflow-x: hidden;
    overflow-y: scroll;
    overflow-wrap: break-word;
    padding: 0px 0em !important;
    margin: 0px -10px 0px !important; }
  #context-menu-layer {
    opacity: 0.4 !important;
    z-index: 99999 !important; }
  .context-menu-separator {
    margin: 0 0 .35em 0;
    border-bottom: 1px solid #afafaf  transparent; } }
