﻿#pagination {
  min-width: 250px; }

ul.simple-pagination {
  list-style: none; }

.simple-pagination {
  display: block;
  overflow: hidden;
  padding: 0 5px 5px 0;
  float: left;
  z-index: 2;
  margin: 6px 0 0 0;
  ul {
    list-style: none;
    padding: 0;
    margin: 0; }
  li {
    list-style: none;
    padding: 0;
    float: left;
    background: white;
    z-index: 2; } }

span.ellipse.clickable {
  cursor: pointer; }

.ellipse input {
  width: 3em; }

.light-theme {
  span {
    cursor: pointer; }
  a, span {
    float: left;
    color: #666;
    font-size: 14px;
    line-height: 19px;
    font-weight: normal;
    text-align: center;
    border: 1px solid #BBB;
    min-width: 14px;
    padding: 0 6px; }
  ul {
     :first-child {
      a, span {
        margin-top: 2px;
        margin-right: 15px;
        min-width: 6px;
        padding: 0;
        background-color: transparent !important; } }
     :last-child {
      a, span {
        margin-top: 2px;
        margin-left: 15px;
        margin-right: 15px;
        min-width: 6px;
        padding: 0;
        background-color: transparent !important; } }
     :first-child {
      a, span {
        color: black;
        border: 1px solid transparent; } }
     :last-child {
      a, span {
        color: black;
        border: 1px solid transparent; } }
     :first-child {
      a:hover, span:hover {
        color: #bd0217; } }
     :last-child {
      a:hover, span:hover {
        color: #bd0217; } } }
  a:hover, li:not(.disabled):not(.active) span:hover {
    color: #bd0217; }
  .current {
    background-color: #bd0217;
    color: #FFF;
    border-color: #bd0217;
    cursor: default; }
  .ellipse {
    background: none;
    border: none;
    border-radius: 0;
    box-shadow: none;
    font-weight: bold;
    cursor: default; } }
