// ## use cycles
//	 @for $i from 1 through 3
//	   .item-#{$i}
//			width: 2em * $i
//	 @each $s in a,b,c
// 			#{$s}
//				display: none

// ## fonts
// Save fonts to /css/fonts, after that write (eot, woff, ttf):
// +font(book, cytiapro-black-webfont)
// =book
// 	font-family: "book", Arial, sans-serif

@mixin m {
  body:not(.no-media) & {
    @media only screen and (max-width: "740px") {
      @content; } } }
@mixin hm {
  html:not(.no-media) & {
    @media only screen and (max-width: "740px") {
      @content; } } }
@mixin d {
  body:not(.no-media) & {
    @media only screen and (min-width: "741px") {
      @content; } } }
@mixin ch {
  @media only screen and (-webkit-min-device-pixel-ratio:0) {
    @content; } }


$red: #bd0217;
$blue: #287c9f;
$green: #006f00;
$border: #D2D2D2;
$m-border: #e1e1e1;
$gray: #7c7c7c;
$bg: #f8f9fd;
$cl: #2b2828;

@mixin f {
  font-family: 'proxima-nova', Arial, sans-serif; }

@mixin underline($color) {
  background-position: 0 95%;
  background-size: 1px 1px;
  // background-image: linear-gradient(rgba($color,.5) 50%, #fff 50%)
  background-repeat: repeat-x;
  &:hover {
    background: none; } }

body, html {
  height: 100%; }

body {
  overflow-y: scroll;
  @include m {
    overflow-y: auto; } }

.html-adaptive {
  @include r(740) {
    box-sizing: border-box; } }

*,
*:before,
*:after {
  @include r(740) {
    box-sizing: inherit;
 } }    // box-shadow: 0 0 0 1px red

input {
  &[type="color"], &[type="date"], &[type="datetime"], &[type="datetime-local"], &[type="email"], &[type="month"], &[type="number"], &[type="password"], &[type="search"], &[type="tel"], &[type="text"], &[type="time"], &[type="url"], &[type="week"] {
    @include m {
      font-size: inherit; } } }

select:focus, textarea {
    @include m {
      font-size: inherit; } }

a {
  color: $red; }

.link-arrow {
  font-size: 12px;
  color: $red;
  text-decoration: none;
  &:after {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    background: transparent url(../img/sprite.png) no-repeat -204px -96px;
    vertical-align: middle; } }

.link-blue {
  color: $blue;
  text-decoration: underline;
  @include r(740) {
    text-decoration: none; } }

.hidden {
  display: none !important; }

.teasers {
  padding-top: 40px !important; }

.field {
  &__textarea {
    display: block;
    width: 100%;
    box-sizing: border-box; } }

.divider {
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
  border-bottom: 1px solid #e1e4e7;
  vertical-align: top;
  clear: both; }

.button {
  display: inline-block;
  height: 32px;
  padding: 0 10px;
  background-color: $red !important;
  border-radius: 2px;
  border: 1px solid $red;
  font-size: 13px;
  font-weight: 500;
  color: #FFF;
  text-align: center;
  box-sizing: border-box;
  line-height: 30px;
  @include m {
    font-size: 17px; }
  .ico {
    margin-left: 10px;
    &_before {
      margin: 0 10px 0 0;
      @include m {
        display: none; } } }
  .ico-arrow-right-red {
    @include m {
      margin-bottom: 2px;
      @include sr(arrow-right-red-mob); } }
  .ico-arrow-down-red {
    @include m {
      margin-bottom: 2px;
      @include sr(arrow-down-red-mob); } }
  .ico-arrow-up-red {
    @include m {
      transform: rotate(180deg); } }
  &_stroke {
    padding: 0 20px;
    background: transparent;
    color: $red; }
  .m-prod__list &_stroke {
    background-color: white !important; }
  &_xl {
    width: 100%;
    height: 40px;
    line-height: 38px; }
  &_lg {
    height: 34px;
    padding: 0 20px;
    font-weight: 700;
    line-height: 32px; }
  &_sm {
    height: 28px;
    line-height: 26px; } }

.ico {
  display: inline-block;
  vertical-align: middle; }


.top-page-button {
  position: fixed;
  z-index: 3;
  right: 0;
  bottom: 102px;
  display: none;
  width: 120px;
  height: 82px;
  background: rgba(230, 230, 230, 0.6);
  cursor: pointer;
  text-align: center;
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0px;
    height: 100%; }
  @include m {
    bottom: 141px;
    width: 46px;
    height: 46px; } }

.m-load-more {
  @include m {
    padding: 0 10px; } }

.load-more {
  position: relative;
  text-align: center;
  float: left;
  z-index: 2;
  margin-left: 50px;
  &:before {
    content: '';
    position: absolute;
    top: 16px;
    left: 0;
    right: 0;
    height: 1px;
    background: #DADADA;
    z-index: -1;
    @include m {
      display: none; } }
  &__btn {
    &:first-child {
      min-width: 185px;
      box-shadow: -15px 0 0 0 #fff, 15px 0 0 0 #fff;
      background: #FFF !important;
      @include m {
        box-shadow: none;
        background: transparent; } }
    @include m {
      width: 100%;
      height: 40px;
      line-height: 38px; }
    & + & {
      @include m {
        margin-top: 10px; } } }
  &__link {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 13px;
    color: rgba(#000,.4);
    line-height: 1;
    @include m {
      display: none; } }
  &_mob {
    @include m {
      margin: 10px 10px 0; } }
  &_no-paging {
    float: none;
    margin-left: auto; } }

.l {
  position: relative;
  &,
  &__row {
    @include clr; }
  &__section {
    float: left;
    width: 728px;
    &_row {
      display: flex;
      justify-content: space-between; }
    @include m {
      float: none;
      width: 100%; } }
  .offers-sort {
    width: 547px; }
  &__sidebar {
    float: right;
    width: 244px;
    @include m {
      float: none;
      width: 100%; } }
  &__left {
    float: left;
    width: 490px;
    @include m {
      float: none;
      width: 100%; } }
  &__right {
    float: right;
    width: 420px;
    @include m {
      float: none;
      width: 100%; } }
  &__col {
    float: left;
    box-sizing: border-box; }
  &_default &__section {
    width: 720px;
    @include m {
      width: auto; } }
  &_default &__sidebar {
    width: 240px; }
  &_default &__col {
    width: 502px;
    @include m {
      float: none;
      width: auto; }
    &:last-child {
      width: 198px;
      margin-left: 20px;
      padding: 0 20px 30px;
      border: dotted #e1e4e7;
      border-width: 0 1px; } }
  &_sidebar_bigger &__section {
    width: 700px; }
  &_sidebar_bigger &__sidebar {
    width: 262px; } }
.offers-sort {
  display: flex; }

.page-wrap {
  min-height: 100%;
  margin-bottom: 0;
  &:after {
    content: '';
    display: block; }
  @include m {
    min-height: calc(100vh - 180px);
    margin-bottom: 0px;
    &:after {
      height: 0px; } } }

.soc {
  font-size: 0;
  float: right;
  width: 255px;
  text-align: right;
  a {
    float: none!important; }
  &__item {
    display: inline-block;
    width: 19px;
    height: 17px;
    margin-right: 14px;
    border: 1px solid #484848;
    vertical-align: top;
    @include vertical;
    .ico {
      vertical-align: middle; } } }

.prod {
  margin-bottom: 25px;
  @include m {
    margin: 0 -10px 0;
    background: $bg; }
  &__item {
    position: relative;
    margin-bottom: 10px;
    padding: 20px;
    border: 1px solid $border;
    line-height: 1.3;
    @include clr;
    @include m {
      padding: 10px 0;
      background: #FFF;
      margin-bottom: 4px;
      border: 1px solid $m-border;
      border-width: 1px 0; } }
  &__cell {
    float: left;
    &_left {
      width: 170px;
      padding-right: 20px;
      @include m {
        width: 34%;
        padding: 0 10px; } }
    &_center {
      width: 305px;
      padding-right: 20px;
      @include m {
        float: right;
        width: 66%;
        padding-right: 10px; } }
    &_right {
      width: 170px;
      padding-bottom: 80px;
      @include m {
        display: flex;
        float: right;
        width: 66%;
        padding: 0;
        flex-direction: column; } } }
  &__preview {
    display: block;
    height: 160px;
    margin-bottom: 17px;
    @include vertical;
    @include m {
      max-height: 160px;
      margin: 0; }
    &-img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: top; } }
  &__rating {
    @include m {
      display: none; } }
  &__title {
    margin-bottom: 11px;
    padding-top: 3px;
    line-height: 1.2;
    @include m {
      margin: 0; }
    &-link {
      font-size: 21px;
      font-weight: 700;
      text-decoration: underline;
      color: #000;
      @include m {
        font-size: 17px; } } }
  &__params {
    margin-bottom: 12px;
    @include m {
      margin: 0;
      font-size: 14px; }
    &-item {
      margin-bottom: 4px;
      @include m {
        display: inline;
        margin: 0; }
      &:not(:last-child) {
        @include m {
          span {
            display: inline; } } }
      span {
        display: none; } }
    &-link {
      font-size: 12px;
      font-weight: 700;
      @include underline($red);
      @include m {
        display: none; } } }
  &__review {
    @include m {
      display: none; }
    &-item {
      margin-bottom: 6px;
      @include clr; }
    &-ico {
      float: left;
      margin-right: 7px; }
    &-content {
      display: table;
      padding-top: 2px; } }
  &__price {
    margin-bottom: 17px;
    padding-top: 3px;
    @include m {
      display: table;
      margin: 0;
      order: 2; }
    &-value {
      //+underline($gray)
      color: $red;
      font-size: 21px;
      font-weight: 700;
      i {
        display: none; }
      @include m {
        display: block;
        min-width: 165px;
        height: 32px;
        padding: 0 15px;
        background-color: $red;
        border-radius: 2px;
        font-size: 17px;
        text-align: center;
        font-weight: 400;
        color: #FFF;
        line-height: 32px;
        span {
          display: none; }
        i {
          display: inline; } } }
    &-offers {
      padding-top: 2px;
      font-size: 12px;
      @include m {
        padding-top: 5px;
        font-size: 14px;
        color: #808080;
        text-align: center; } } }
  &__rate {
    @include m {
      order: 1; }
    &-mobile {
      font-size: 14px;
      padding: 7px 0; } }
  &__actions {
    position: absolute;
    bottom: 40px;
    right: 20px;
    width: 170px;
    font-size: 0;
    @include m {
      display: none; }
    &-btn {
      width: 34px;
      height: 34px;
      margin-right: 5px;
      background-color: #f8f8f8;
      border: 1px solid #cfcfcf;
      border-radius: 3px; } }
  &__foot {
    padding: 6px 10px 15px 0;
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      position: absolute;
      top: 22px;
      left: 0;
      right: 0;
      height: 1px;
      background: #DADADA;
      z-index: -1;
      @include m {
        display: none; } } } }
.rate_prod {
  display: block; }

.stars {
  text-align: center;
  &__list {
    margin-bottom: 3px;
    font-size: 0;
    .ico {
      margin: 0 1px; } }
  &__link {
    font-weight: 700;
    font-size: 14px;
    text-decoration: underline; } }

.star {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 3px;
  background-size: 14px 14px;
  vertical-align: top;
  &_red-full {
    background-image: url('../img/stars/star-red-full.svg'); }
  &_red-half {
    background-image: url('../img/stars/star-red-half.svg'); }
  &_red-empty {
    background-image: url('../img/stars/star-red-empty.svg'); }
  &_yellow-full {
    background-image: url('../img/stars/star-yellow-full.svg'); }
  &_yellow-half {
    background-image: url('../img/stars/star-yellow-half.svg'); }
  &_yellow-empty {
    background-image: url('../img/stars/star-yellow-empty.svg'); }
  &_black-full {
    background-image: url('../img/rating/star-black-full.svg'); }
  &_black-half {
    background-image: url('../img/rating/star-black-half.svg'); }
  &_black-empty {
    background-image: url('../img/rating/star-black-empty.svg'); } }

.rate {
  font-size: 14px;
  line-height: 1.3;
  @include m {
    font-size: 17px; }
  &__wrap {
    padding-top: 4px;
    font-size: 0; }
  &__stars,
  &__value {
    display: inline-block;
    vertical-align: top; }
  &__value {
    margin-left: 8px;
    font-size: 14px;
    color: $red;
    &_black {
      color: #000; } }
  &_table &__link,
  &_table &__wrap {
    display: table-cell;
    padding-bottom: 7px;
    vertical-align: middle;
    @include m {
      display: inline-block;
      padding-bottom: 2px; } }
  &_table &__wrap {
    padding-left: 10px;
    @include m {
      padding: 0; } }
  &_table:first-child &__link {
    @include m {
      padding-right: 40px; } }
  &_expert &__value {
    @include m {
      font-size: 17px;
      color: #383838; } }
  &_expert &__stars {
    @include m {
      padding-top: 2px; } }
  &_prod {
    @include m {
      display: none; } }
  &_buy &__wrap {
    padding: 0; }
  &_buy &__value {
    color: #9D9D9D; } }

.check {
  display: block;
  margin-bottom: 9px;
  cursor: pointer;
  user-select: none;
  &__input, &__input__instock {
    display: none; }
  &__input__instock:checked + &__wrap &__tick,
  &__input:checked + &__wrap &__tick {
    background: $red;
    border-color: $red;
    .ico {
      display: inline-block; } }
  &__wrap {
    display: block;
    overflow: hidden; }
  &__tick {
    float: left;
    min-width: 13px;
    height: 13px;
    border: 1px solid #e1e1e1;
    font-size: 0;
    .ico {
      margin-right: 2px;
      vertical-align: top; }
    .ico-tick {
      display: none;
      margin: 3px 0 0 2px; }
    .ico-check {
      margin: 3px 0 0 2px; } }
  &__tick + &__content {
    padding-left: 6px; }
  &__content {
    display: block;
    overflow: hidden;
    &.bold {
      font-weight: 700; } }
  &__counter {
    margin-left: 5px;
    color: #9e9e9e; }
  &_radio &__tick {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-sizing: border-box; }
  &_radio &__input:checked + &__wrap &__tick {
    background: #FFF;
    border: 5px solid $red; }
  &_stars &__tick {
    border: none;
    opacity: .5; }
  &_stars &__input:checked + &__wrap &__tick {
    background: none;
    opacity: 1; }
  &_lg &__tick {
    width: 16px;
    height: 16px;
    margin-right: 9px;
    border-radius: 3px; }
  &_lg &__content {
    padding-top: 1px;
    font-size: 14px; } }

.filters {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid $border;
  &__item {
    margin-bottom: 25px;
    &:last-child {
      margin: 0; }
    &_brand {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 25px;
        height: 50px;
        background: linear-gradient(rgba(#fff,0), rgba(#fff,1));
        pointer-events: none; } }
    &_brand-top {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 55px;
        height: 50px;
        background: linear-gradient(rgba(#fff,1), rgba(#fff,0));
        pointer-events: none;
        z-index: 3; } } }
  &__title {
    margin-bottom: 10px;
    font-weight: 700; }
  &__wrap {
    &_cols {
      columns: 2; }
    &_scroll {
      position: relative;
      max-height: 400px;
      overflow-y: hidden;
      &-yes {
        overflow-y: auto; } } }
  &__range {
    margin: 0 -5px;
    font-size: 0; }
  &__brand {
    margin: 7px 0 9px;
    &__field {
      display: inline-block;
      width: 100%;
      padding: 0 0; } }
  &__field {
    display: inline-block;
    width: 50%;
    padding: 0 5px;
    box-sizing: border-box;
    vertical-align: top; }
  &__input[type='number'],
  &__input[type='text'] {
    width: 100%;
    height: 22px;
    padding: 0 5px;
    border: 1px solid #e1e1e1;
    box-shadow: none;
    box-sizing: border-box;
    border-radius: 0;
    font-size: 14px;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
	    -webkit-appearance: none {
      margin: 0; } }
    &.hasError {
      border: 1px solid $red; } }
  &__more {
    margin-top: 10px;
    &-link {
      font-size: 12px;
      font-weight: 700; } }
  &__delivery {
    margin-top: 10px; } }

.filters__check.check_active > .check__wrap .check__tick {
  background: 0 0;
  opacity: 1; }

.bc {
  margin-bottom: 25px;
  padding-top: 15px;
  font-size: 0;
  @include m {
    display: none; }
  &__item {
    display: inline-block;
    vertical-align: top;
    font-size: 11px;
    font-weight: 700;
    color: $red;
    &:before {
      content: '/';
      margin: 0 10px;
      color: #a3a3a3; }
    &:first-child {
      &:before {
        display: none; } } }
  &__link {
    color: #a3a3a3; } }

.head {
  position: relative;
  margin-bottom: 30px;
  @include clr;
  @include m {
    display: flex;
    margin-bottom: 14px;
    padding-top: 14px;
    flex-direction: column; }
  &__menu {
    display: none;
    position: absolute;
    top: 16px;
    left: 0;
    cursor: pointer;
    @include m {
      z-index: 2;
      display: block; } }
  &__title {
    display: table;
    margin: 0;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.2;
    @include m {
      display: block;
      margin-right: -10px;
      padding-left: 30px;
      order: 1;
      font-size: 23px;
      white-space: nowrap;
      overflow: hidden; }
    &-gray {
      margin-left: 5px;
      color: #9e9e9e; }
    &_simple {
      @include m {
        padding: 0;
        white-space: normal; } } }
  &__describe {
    font-size: 13px;
    color: #696969;
    @include m {
      padding-top: 5px;
      order: 2; } }
  &__button {
    display: none;
    @include m {
      display: block;
      margin-top: 10px;
      order: 3; } }
  &__wrap {
    margin-top: -62px;
    float: right;
    margin-left: 20px;
    @include m {
      display: none; } }
  &__col {
    float: left;
    margin: 3px 0 0 10px; }
  &__label {
    float: left;
    margin-right: 10px;
    font-size: 12px;
    color: #989898;
    line-height: 31px; }
  &__select {
    float: left;
    position: relative;
    width: auto;
    min-width: 166px;
    max-width: 240px;
    height: 31px;
    padding: 0 16px 0 12px;
    background: url(../img/arrows-select.png) 100% 50% no-repeat #f7f7f7;
    box-shadow: none;
    border: 1px solid #cfcfcf;
    border-radius: 4px;
    font-size: 17px;
    -webkit-appearance: none;
    &_sm {
      min-width: 60px;
      font-size: 12px; } }
  &__cat {
    float: left;
    width: 21%;
    padding-top: 7px;
    box-sizing: border-box;
    font-size: 0;
    @include m {
      display: none; }
    &-list {
      display: flex;
      margin-right: -5px;
      flex-wrap: wrap;
      align-items: stretch; }
    &-item {
      display: flex;
      position: relative;
      width: 100%;
      position: relative;
      padding: 0 5px 2px 0;
      box-sizing: border-box;
      &:hover {
        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 3px;
          left: -10px;
          @include arr(10,20,#d2d2d2,l); }
        &:after {
          left: -9px;
          border-color: transparent #fff transparent transparent; } } }
    &-item:hover &-link {
      background: #FFF;
      box-shadow: 2px 2px 0 #d7d7d7;
      color: $red;
      &:after {
        background: linear-gradient(to right, rgba(#fff,0), rgba(#fff,1) 70%); } }
    &-link {
      display: block;
      position: relative;
      width: 100%;
      max-height: 37px;
      padding: 4px 10px;
      background: #f8f8f8;
      border: 1px solid #d2d2d2;
      font-size: 15px;
      color: #000;
      font-weight: 700;
      line-height: 1.3;
      overflow: hidden;
      box-sizing: unset;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 15px;
        background: linear-gradient(to right, rgba(#f8f8f8,0), rgba(#f8f8f8,1) 70%); } } }
  &_catalog &__title {
    float: left;
    max-width: 79%;
    padding-right: 35px;
    box-sizing: border-box;
    @include m {
      float: none;
      max-width: none; } }
  &_catalog_more &__title {
    width: 37%;
    max-width: 37%;
    @include m {
      width: auto;
      max-width: none; } }
  &_catalog_more &__cat {
    width: 63%; }
  &_catalog_more &__cat-item {
    width: 33.33%; }
  &__button {
    background-color: transparent !important; } }

.tabs {
  margin-bottom: 30px;
  padding-bottom: 11px;
  border-bottom: 1px solid $border;
  font-size: 0;
  @include clr;
  &__item {
    display: inline-block;
    position: relative;
    margin-right: 30px;
    &.active {
      &:before,
      &:after {
        content: '';
        position: absolute;
        bottom: -11px;
        left: 50%;
        margin-left: -15px;
        @include arr(30,8,$border,t); }
      &:after {
        bottom: -12px;
        border-color: transparent transparent #fff transparent; } } }
  &__item.active {
    cursor: default; }
  &__item.active &__title,
  &__item.active &__counter {
    color: $gray; }
  &__item.active &__title {
    background: none;
    text-decoration: none; }
  &__link {
    display: block; }
  &__title,
  &__counter {
    display: inline-block;
    font-size: 14px;
    font-weight: 700; }
  &__title {
    text-decoration: underline;
    color: $blue; }
  &__counter {
    padding-left: 5px;
    color: #9e9e9e; }
  &__b {
    float: right;
    height: 30px;
    margin: -6px 0;
    padding: 0 10px 2px; } }

.sort {
  margin-bottom: 15px;
  padding: 0 25px;
  @include clr;
  &__wrap {
    float: left; }
  &__title,
  &__list {
    float: left; }
  &__title {
    margin: 4px 10px 0 0;
    font-size: 14px;
    font-weight: 700; }
  &__btn,
  &__select {
    float: left;
    height: 25px;
    margin-left: 5px;
    border: 1px solid $border;
    background: #FFF;
    border-radius: 4px;
    font-weight: 700; }
  &__btn {
    padding: 0 7px;
    background: #FFF;
    font-size: 0;
    &-title,
    &-ico {
      vertical-align: middle; }
    &-title {
      font-family: Arial;
      display: inline-block;
      padding-bottom: 1px;
      font-size: 13px; }
    &-ico {
      display: none;
      margin-left: 8px;
      padding-top: 1px;
      .ico-sort-arrow-up {
        display: none; } }
    &.active {
      background: #383839;
      border-color: #383839;
      color: #fff; }
    &.active &-ico {
      display: inline-block; }
    &.active-up &-ico {
      .ico-sort-arrow-up {
        display: inline-block; }
      .ico-sort-arrow-down {
        display: none; } } }
  &__select {
    width: auto;
    padding: 0 23px 0 10px;
    background: url(../img/arrows-select.png) 100% 50% no-repeat #fff;
    box-shadow: none;
    -webkit-appearance: none;
    line-height: 23px; }
  &__check {
    float: left;
    margin: 3px 0 0 30px;
    &_right {
      float: right; } }
  &_opinions {
    margin-bottom: 25px; }
  &_sm {
    padding: 0; }
  &_sm &__title {
    font-weight: 400; }
  &_catalog {
    padding: 0; }
  &_catalog &__check {
    float: right;
    margin-left: 0; } }

.load {
  position: relative;
  text-align: center;
  overflow: hidden;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 15px;
    height: 1px;
    background: $border;
    @include m {
      display: none; } }
  &:before {
    left: 0;
    right: 50%;
    margin-right: 102px; }
  &:after {
    right: 0;
    left: 50%;
    margin-left: 102px; }
  &__btn {
    min-width: 192px;
    @include m {
      display: none; } }
  &_m {
    @include m {
      padding: 6px 10px 10px; } } }

.buy-page-sort .sort__wrap {
  margin-top: 23px; }

.desc {
  margin-bottom: 35px;
  font-size: 14px;
  line-height: 1.3;
  @include clr;
  @include m {
    margin-bottom: 0;
    font-size: 17px; }
  &__col {
    float: left;
    min-height: 2px;
    @include m {
      float: none; }
    &_left {
      width: 46%;
      padding-right: 20px;
      box-sizing: border-box;
      @include m {
        width: 100%;
        padding-right: 0px;
        margin-bottom: 0; } }
    &_right {
      width: 54%;
      @include m {
        display: flex;
        width: 100%;
        flex-direction: column; } }
    &_offers {
      display: flex;
      flex-direction: column;
      @include d {
        min-height: 740px; } } }
  .pc65 {
    width: 65%; }
  .pc35 {
    width: 35%; }
  .pc67 {
    width: 67%; }
  .pc33 {
    width: 33%; }
  &__gallery {
    position: relative;
    text-align: center;
    &_reverse {
      display: flex;
      flex-direction: row-reverse; }
    &_reverse &-thumbs {
      margin-right: 0px !important;
      margin-left: 30px !important; }
    &_reverse &-container {
      flex: 60%; }
    &-zoom,
    &-prev,
    &-next {
      position: absolute;
      padding: 5px;
      background: none;
      border: none;
      font-size: 0;
      z-index: 4;
      @include m {
        display: none; } }
    &-zoom {
      top: 10px;
      left: -5px; }
    &-prev,
    &-next {
      top: 50%;
      margin-top: -19px; }
    &-prev {
      left: -5px; }
    &-next {
      right: -5px; }
    &-wrap {
      position: relative;
      min-height: 260px;
      margin-bottom: 10px;
      padding: 35px 30px;
      z-index: 2;
      @include m {
        min-height: 220px;
        padding: 15px 30px; } }
    &-item {
      @include vert; }
    &-img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle; }
    &-link {
      font-size: 14px;
      color: $blue;
      text-decoration: underline; }
    &-more {
      @include m {
        display: none; } }
    &-dots {
      display: none;
      font-size: 0;
      text-align: center;
      @include m {
        display: block; } }
    &-dot {
      display: inline-block;
      width: 5px;
      height: 5px;
      margin: 0 3px;
      background-color: #ebebeb;
      border-radius: 50%;
      vertical-align: top;
      &.active {
        background-color: #ccc; } }
    &-scroll {
      height: 200px;
      overflow: hidden; }
    &-thumbs {
      position: relative;
      float: left;
      width: 44px;
      height: 100%;
      margin-right: 50px;
      &-right {
        position: relative;
        width: 44px;
        height: 100%;
        float: right;
        margin-left: 20px; }
      @include m {
        margin-right: 20px; }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -1px;
        height: 50px;
        background: linear-gradient(rgba(#fff,0), rgba(#fff,1));
        pointer-events: none; } }
    &-thumb {
      display: block;
      height: 46px;
      margin-bottom: 5px;
      padding: 2px;
      border: 1px solid transparent;
      box-sizing: border-box;
      @include vertical;
      cursor: pointer;
      &.active {
        border-color: $border; } }
    &-pic {
      display: inline-block;
      max-width: 100%;
      vertical-align: middle; }
    &-container {
      position: relative;
      height: 100%;
      overflow: hidden; }
    &_shops {
      padding-right: 30px;
      @include clr;
      @include m {
        margin-right: -20px;
        padding: 0; } }
    &_shops {
      min-height: 200px; }
    &_shops &-wrap {
      height: 100%;
      min-height: 0;
      margin: 0;
      padding: 0 40px;
      @include m {
        padding: 0 0 10px; } }
    &_shops &-item {
      height: 100%; }
    &_shops &-zoom {
      top: 0;
      right: 0;
      left: auto;
      padding: 0; }
    &_shops &-img {
      @include m {
        max-height: 200px; } }
    &_shops_right {
      padding-right: 0 !important; } }
  &__row {
    @include clr;
    &:first-child {
      @include m {
        margin-bottom: 10px; } } }
  &__cell {
    float: left;
    @include m {
      float: none; }
    &_left {
      width: 280px;
      margin-right: 30px;
      @include m {
        width: 100%;
        margin: 0; } }
    &_right {
      width: 220px;
      @include m {
        width: 100%; } } }
  &__flex-row {
    display: flex;
    align-items: flex-end;
    position: relative;
    @include m {
      margin:  0 -15px; }
    &_space-between {
      justify-content: space-between; } }
  .sm-description {
    padding-top: 10px;
    padding-left: 30px; }
  &__cell_right &__review-item {
    &:before {
      background: $red; } }
  &__params {
    margin-bottom: 27px;
    padding-top: 8px;
    @include m {
      display: none; }
    &-list {
      margin-bottom: 7px; }
    &-item {
      position: relative;
      padding-left: 14px;
      &:before {
        content: '';
        position: absolute;
        top: 6px;
        left: 0;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #b2b2b2; } } }
  &__price {
    margin-bottom: 27px;
    padding-top: 10px;
    text-align: right;
    @include m {
      margin-bottom: 5px;
      padding: 0; }
    &-button {
      min-width: 190px;
      font-size: 16px;
      @include m {
        width: 100%;
        height: 40px;
        line-height: 38px;
        font-weight: 600; } }
    &-offers {
      margin-bottom: 20px;
      padding-top: 5px;
      @include m {
        display: none; } }
    &-link {
      display: block;
      margin-top: 20px;
      font-size: 13px;
      color: $blue;
      text-decoration: underline;
      @include m {
        display: none; } }
    &-mob {
      display: none;
      @include m {
        display: inline; } } }
  &__city {
    display: none;
    margin-bottom: 20px;
    @include m {
        display: block; } }
  &__rate {
    display: table;
    margin-bottom: 21px;
    @include m {
      width: 100vw;
      margin: 10px -10px;
      padding: 6px 0;
      border: 1px solid $m-border;
      border-width: 1px 0;
      order: 3;
      @include clr; }
    &-item {
      display: table-row;
      margin-bottom: 10px;
      @include m {
        float: left;
        width: 100%;
        margin: 0;
        padding: 0 10px;
        vertical-align: top; } } }
  &__review {
    margin-bottom: 4px;
    &-head {
      display: table;
      margin-bottom: 5px; }
    &-ico,
    &-title {
      display: table-cell;
      vertical-align: middle; }
    &-ico {
      padding-right: 7px;
      font-size: 0;
      @include m {
        padding-right: 10px; }
      .ico-plus-green {
        @include s(plus-green);
        @include m {
          @include sr(plus-mob); } }
      .ico-minus-red {
        @include s(minus-red);
        @include m {
          @include sr(minus-mob); } } }
    &-title {
      padding-top: 3px;
      font-weight: 700; }
    &-content {
      padding-left: 22px;
      @include m {
        padding-left: 28px; } }
    &-item {
      position: relative;
      margin-bottom: 2px;
      padding-left: 26px;
      @include m {
        margin-left: 23px;
        font-size: 17px; }
      &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 7px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #7fb77f;
        @include m {
          top: 7px; } } }
    &_red &-item {
      &:before {
        background: #db9695; } } }
  &__preview {
    height: 100px;
    padding: 0 15px;
    @include vertical;
    @include m {
      padding: 0; }
    &-img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
      @include m {
        vertical-align: top; } } }
  &_margin_sm {
    @include d {
      margin-bottom: 30px; } }
  &_sm {
    margin-bottom: 30px;
    @include m {
      margin-bottom: 10px; } }
  &_sm &__col {
    @include m {
      float: left; } }
  &_sm &__col_left {
    width: 120px;
    margin-right: 20px; }
  &_sm &__col_right {
    width: 842px;
    @include m {
      width: 70%; } }
  &_sm &__row {
    @include m {
      display: flex;
      flex-wrap: wrap; } }
  &_sm &__cell_left {
    width: 331px;
    margin-right: 20px;
    @include m {
      width: auto;
      padding-top: 10px;
      order: 2; } }
  &_sm &__cell_center {
    width: 271px;
    margin-right: 20px; }
  &_sm &__cell_right {
    width: 200px;
    @include m {
      order: 1; } }
  &_sm &__rate {
    margin: 0;
    padding-top: 20px; }
  &_sm &__preview {
    padding: 0; }
  &_sm &__params {
    margin: 0;
    padding-top: 20px; }
  &_sm &__params-list {
    margin-bottom: 24px; }
  &_sm &__params-more {
    margin: 10px 0 0 14px; }
  &_sm &__price {
    margin: 0;
    padding-top: 20px;
    @include m {
      padding: 0; } }
  &_sm &__price-offers {
    margin-bottom: 5px; }
  &_sm &__price-value {
    font-size: 18px; }
  &_review {
    border: 1px solid $border; }
  &_review &__review {
    padding: 20px 15px;
    &:first-child {
      border-bottom: 1px solid $border; } }
  &_border {
    padding-bottom: 30px;
    border-bottom: 1px solid $border;
    @include m {
      padding: 0;
      border: none; } }
  &_lite {
    @include m {
      margin-bottom: 10px; } }
  &_plus_minus {
    position: relative;
    margin-bottom: 20px;
    &:before {
      content: '';
      position: absolute;
      top: 10px;
      right: -10px;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 1px solid #d2d2d2;
      z-index: -1; } }
  &_plus_minus &__wrap {
    padding: 20px 15px 25px;
    border: 1px solid #d2d2d2;
    background: #FFF;
    z-index: 2; }
  &_plus_minus &__review {
    &:not(:last-child) {
      margin-bottom: 20px; }
    &-item {
      margin-bottom: 4px;
      font-size: 13px;
      line-height: 1.38; } }
  &_m {
    &__full {
      @include m {
        width: 100% !important;
        padding-top: 20px; } } } }

.slick-dots {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  margin: 10px 0 0 0;
  list-style: none;
  text-align: center;
  li {
    position: relative;
    display: inline-block;
    width: 5px;
    height: 5px;
    margin: 0 3px;
    padding: 0;
    cursor: pointer;
    button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 5px;
      height: 5px;
      padding: 1px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;
      margin: 0 3px;
      background-color: #ebebeb;
      border-radius: 50%;
      vertical-align: top;
      &:hover, &:focus {
        background-color: #ccc; } }
    &.slick-active button {
      background-color: #ccc; } } }

.buy {
  font-size: 14px;
  line-height: 1.3;
  &__head {
    margin-bottom: 14px;
    @include clr;
    @include m {
      background: #FFF; }
    &-ico,
    &-title {
      float: left; }
    &-ico {
      margin-right: 25px;
      @include m {
        display: none; } }
    &-title {
      font-size: 26px;
      font-weight: 700;
      line-height: 1.1;
      @include m {
        font-size: 21px;
        line-height: 1.3; } }
    &-mob {
      display: none; } }
  &__params {
    padding-bottom: 14px;
    border-bottom: 1px solid #E5E5E5;
    @include clr;
    @include m {
      display: none; } }
  &__sort {
    float: left;
    margin: 0; }
  &__city {
    position: relative;
    float: right;
    padding-top: 6px;
    line-height: 1;
    &-title,
    &-link {
      float: left; }
    &-title {
      margin-right: 10px; }
    &-link {
      border-bottom: 1px dashed;
      color: $red;
      &:hover {
        border-color: transparent; }
      &_blue {
        color: $blue; } } }
  &__drop {
    display: none;
    position: absolute;
    top: -10px;
    left: -20px;
    min-height: 155px;
    padding: 50px 30px 30px;
    border: 1px solid $border;
    background: #fff;
    z-index: 1011 {
      left: -20px !important; } }
  &__cities {
    margin-bottom: 10px;
    @include clr;
    &-col {
      float: left;
      width: 20%;
      padding-right: 20px;
      box-sizing: border-box; }
    &-item {
      margin-bottom: 10px; }
    &-link {
      font-size: 16px;
      color: #000;
      &:hover {
        background-position: 0 95%;
        background-size: 1px 1px;
        background-image: linear-gradient(rgba($blue,.5) 50%, #fff 50%);
        background-repeat: repeat-x;
        color: $blue;
        text-decoration: underline; }
      &.active {
        font-weight: 700; } } }
  &__search {
    position: relative;
    z-index: 4;
    &-input[type='text'] {
      width: 100%;
      height: 31px;
      padding: 0 10px 0 39px;
      box-shadow: none;
      background-color: #f7f7f7;
      border: 1px solid #e1e1e1;
      box-sizing: border-box; }
    &-ico {
      position: absolute;
      top: 7px;
      left: 9px;
      pointer-events: none; }
    &-del {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      width: 31px;
      height: 31px;
      border: none;
      background: none;
      font-size: 0; }
    &-autocomplete {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      padding: 3px 0;
      border: solid #e1e1e1;
      border-width: 0 1px 1px;
      background: #fff; }
    &-option {
      padding: 5px 39px;
      cursor: pointer; }
    &-query {
      font-weight: 700; } }
  &__body {
    overflow: hidden;
    @include m {
      margin: 0 -10px;
      &_bg {
        background: #f8f9fd; } } }
  &__list {
    padding-top: 21px;
    @include clr;
    @include m {
      margin: 0 0 10px;
      padding: 0; } }
  &__item {
    display: none;
    float: left;
    width: 50%;
    margin-bottom: 26px;
    padding: 0 50px;
    box-sizing: border-box;
    @include m {
      width: 100%;
      padding: 20px 10px 13px;
      border: 1px solid $m-border;
      border-width: 1px 0;
      background: #fff;
      margin-bottom: 4px; } }
  &__col {
    float: left;
    width: 53%;
    box-sizing: border-box;
    @include m {
      width: 38%; }
    &:first-child {
      width: 47%;
      padding-right: 10px;
      @include m {
        width: 62%; } } }
  &__preview {
    height: 20px;
    @include m {
      height: 30px; } }
  &__logo {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%; }
  &__name {
    position: relative;
    padding-top: 1px;
    font-size: 20px;
    color: rgba(#000,.8);
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 20px;
      background: linear-gradient(to right, rgba(#fff,.0), rgba(#fff,1)); }
    a {
      color: rgba(#000,.8); } }
  &__button {
    float: right;
    width: 50%;
    font-size: 15px!important;
    @include m {
      float: none;
      width: 100%;
      height: 32px;
      line-height: 30px;
      &:after {
        content: '';
        display: inline-block;
        margin: 0 0 2px 5px;
        @include sr(arrow-right-white);
        vertical-align: middle; } } }
  &__delivery {
    position: relative;
    float: left;
    width: 50%;
    margin-top: 7px;
    padding-left: 27px;
    box-sizing: border-box;
    color: gray;
    &__norating {
      margin-left: -75px !important;
      margin-top: 7px !important; }
    @include m {
      float: none;
      width: 100%; }
    .ico {
      position: absolute; }
    .ico-delivery {
      top: 2px;
      left: 0;
      @include m {
        top: 4px;
        @include sr(delivery-mob); } }
    .ico-point {
      top: 0;
      left: 8px;
      transform: scale(.8); } }
  &.expanded &__city {
    z-index: 1030; }
  &.expanded &__drop {
    display: block;
    z-index: 1020; }
  &_content {
    margin: 10px 0 30px; }
  &_content &__col {
    width: 44%;
    &:first-child {
      width: 56%; } }
  &_content &__sort {
    float: none;
    width: 100%; }
  &_content &__city {
    position: absolute;
    top: 0;
    right: 0; }
  &_content &__button,
  &_content &__delivery {
    float: none;
    width: 100%; }
  &_content &__drop {
    top: -10px; }
  &_simple &__city {
    float: left;
    padding-top: 6px; }
  &_simple &__drop {
    width: 1020px;
    padding: 64px 40px 32px;
    border: 1px solid #d2d2d2;
    box-sizing: border-box;
    &:after {
      content: '';
      position: absolute;
      left: -1px;
      right: -1px;
      bottom: -1px;
      height: 4px;
      background: $red; } }
  &_shops {
    min-height: 645px;
    @include m {
      min-height: 0px;
      padding-bottom: 0; } }
  &_shops &__head {
    @include m {
      margin-top: -10px; } }
  &_shops &__list {
    @include d {
      margin: 0 0 20px;
      padding: 0;
      border-bottom: 1px solid $border; } }
  &_shops &__item {
    @include d {
      width: 100%;
      margin: 0;
      padding: 25px 25px 25px 20px;
      border: solid $border;
      border-width: 1px 1px 0; } }
  &_shops &__col {
    @include d {
      width: 50%;
      &:first-child {
        width: 50%; } } }
  &_shops &__preview {
    @include d {
      float: left;
      width: 63%;
      height: 36px;
      margin: -7px 0;
      padding-right: 20px;
      box-sizing: border-box;
      @include vert; } }
  &_shops &__logo {
    @include d {
      display: inline-block;
      vertical-align: middle; } }
  &_shops &__name {
    @include d {
      display: inline-block;
      width: 100%;
      font-size: 28px;
      line-height: 36px;
      vertical-align: middle; } }
  &_shops &__rate {
    @include d {
      float: left;
      width: 37%; } }
  &_shops &__button {
    @include d {
      width: 41%; } }
  &_shops &__delivery {
    @include d {
      width: 59%;
      margin: 0;
      padding-left: 28px;
      font-size: 12px; }
    .ico-delivery {
      @include d {
        @include sr(delivery-mob); } } } }

.section {
  margin-bottom: 50px;
  @include m {
    margin: 0 -10px;
    padding: 15px 10px;
    background: #f8f9fd; }
  &__head {
    margin-bottom: 30px;
    @include clr;
    @include m {
      margin-bottom: 10px; } }
  &__title {
    float: left;
    margin-right: 35px;
    font-size: 26px;
    font-weight: 700;
    line-height: 1.1;
    @include m {
      margin: 0;
      font-size: 21px; } }
  &__rate {
    float: left;
    margin: 8px 20px 0 0;
    font-size: 20px;
    color: #505050;
    &-value {
      font-weight: 700;
      color: $red; }
    &-text {
      margin: 0 4px;
      font-size: 14px;
      color: #505050; }
    &-link {
      font-size: 14px; } }
  &__button {
    float: right;
    margin: -2px 0 -2px 20px;
    @include m {
      display: none; } }
  &_border {
    padding-bottom: 35px;
    border-bottom: 1px solid #e5e5e5;
    @include m {
      padding-bottom: 15px;
      border: none; } }
  &_simple {
    @include m {
      padding-top: 0;
      padding-bottom: 20px;
      background: #fff; } } }

.buy__drop_center {
  top: 35px;
  width: 1020px;
  padding: 32px 40px;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  &:after {
    content: '';
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: -1px;
    height: 4px;
    background: $red; } }

.buy__cities-item {
  margin-bottom: 0px; }

.buy__cities-link {
  font-weight: normal;
  line-height: 13px; }

.buy__search-ico {
  top: 3px; }

.buy__search-input[type='text'] {
  font-size: 14px; }

.help {
  display: inline-block;
  width: 15px;
  height: 15px;
  padding-left: 1px;
  border: 1px solid #c9c9c9;
  border-radius: 50%;
  vertical-align: middle;
  font-size: 12px;
  color: #c9c9c9;
  text-align: center; }

.expert {
  font-size: 14px;
  line-height: 1.3;
  &__row {
    margin-bottom: 25px;
    @include clr; }
  &__col {
    float: left;
    @include m {
      float: none; }
    &_left {
      width: 215px;
      margin-right: 50px;
      @include m {
        display: none; } }
    &_center {
      width: 593px;
      margin-right: 40px;
      @include m {
        width: 100%; } }
    &_right {
      width: 84px;
      @include m {
        display: none; } } }
  &__rate {
    &-head {
      margin-bottom: 10px;
      padding: 0 5px 10px 0;
      border-bottom: 3px solid $red;
      @include clr; }
    &-help {
      float: right;
      margin-left: 10px; }
    &-title {
      display: table;
      padding-top: 1px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700; }
    &-total {
      font-size: 14px;
      color: $red; }
    &-item {
      display: table;
      width: 100%;
      border-bottom: 1px solid #e5e5e5; }
    &-value,
    &-type {
      display: table-cell;
      padding: 5px 5px 5px 0;
      vertical-align: top; }
    &-value {
      color: $red;
      font-weight: 700;
      text-align: right; }
    &-type {
      font-weight: 700; }
    &-link {
      border-bottom: 1px dashed;
      color: #000; } }
  &__gallery {
    &-wrap {
      margin-bottom: 10px; }
    &-item {
      margin-bottom: 7px; }
    &-img {
      display: block;
      width: 100%; }
    &-link {
      position: relative;
      &:before {
        content: '+';
        position: absolute;
        top: 1px;
        left: -10px; } }
    &-text {
      font-size: 13px; } }
  &_narrow {
    margin-bottom: 20px;
    padding: 20px 15px 25px;
    background-color: #fdfcfc;
    border: 1px solid #fbf6f6; }
  &_narrow &__rate-wrap {
    margin: 0; } }

.content {
  line-height: 1.3;
  a {
    @include underline($red); }
  &_simple {
    p,
    ul,
    li,
    table {
      margin: 0; } } }

.review {
  font-size: 14px;
  line-height: 1.3;
  @include m {
    font-size: 17px; }
  &__sort {
    margin-bottom: 30px;
    @include clr;
    @include m {
      display: none; }
    &-left {
      float: left;
      margin: 0; }
    &-right {
      float: right;
      margin: 0; }
    &-item {
      display: table;
      margin-bottom: 12px; }
    &-filter {
      margin-bottom: 0; }
    &-btn {
      float: right;
      margin-left: 20px; } }
  &__top {
    margin: 0 0 30px;
    padding: 20px 20px 30px;
    background: #f7f7f7;
    @include clr;
    @include m {
      margin: 0 -10px 5px;
      padding: 0 10px;
      background: none; }
    &-col {
      float: left;
      width: 50%;
      padding-right: 20px;
      box-sizing: border-box;
      @include m {
        float: none;
        width: 100%;
        padding: 0;
        &:first-child {
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid $m-border; } } }
    &-head {
      margin-bottom: 5px;
      @include clr; }
    &-ico {
      float: left;
      margin-right: 10px;
      @include m {
        display: none; } }
    &-title {
      display: table;
      padding-top: 1px;
      font-size: 18px;
      font-weight: 700;
      color: $green;
      @include m {
        padding: 0;
        font-size: 17px; }
      &_red {
        color: $red; } }
    &-counter {
      margin-bottom: 20px;
      font-size: 13px;
      color: #7E7E7E;
      @include m {
        display: none; } }
    &-bold {
      margin-right: 4px;
      font-weight: 700; }
    &_white {
      background: #FFF;
      border: 1px solid $border;
      box-sizing: border-box; } }
  &__item {
    margin-bottom: 30px;
    @include m {
      margin: 0;
      padding: 15px 0 25px; } }
  &__item + &__item {
    padding-top: 25px;
    border-top: 1px solid $border; }
  &__item.open &__content_toggle {
    height: auto; }
  &__item.open &__more {
    .ico {
      transform: rotate(180deg); } }
  &__meta {
    position: relative;
    margin-bottom: 15px;
    @include m {
      display: flex;
      flex-wrap: wrap; } }
  &__meta_lg {
    margin-bottom: 25px;
    @include m {
      margin-bottom: 10px; } }
  &__meta_lg &__stars {
    display: block;
    margin-bottom: 5px; }
  &__stars,
  &__user,
  &__dot,
  &__params {
    display: inline-block;
    vertical-align: middle; }
  &__stars {
    font-size: 13px;
    text-transform: uppercase;
    @include m {
      width: 100%;
      order: 4; }
    &-title,
    &-list,
    &-value {
      display: inline-block;
      vertical-align: middle; }
    &-title,
    &-value {
      @include m {
        display: none; } }
    &-title {
      font-weight: 700; }
    &-list {
      margin: -1px 5px 0;
      font-size: 0;
      @include m {
        margin: 0; } } }
  &__user {
    &-ico,
    &-title {
      display: inline-block;
      vertical-align: top; }
    &-title {
      padding-top: 1px;
      font-size: 13px;
      @include m {
        margin-right: 5px;
        padding: 0;
        font-size: 14px;
        font-weight: 700; } }
    &-ico {
      margin-right: 2px;
      font-size: 0;
      @include m {
        display: none; } } }
  &__date {
    display: none;
    @include m {
      display: block;
      font-size: 14px;
      color: #868686; } }
  &__poll {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0;
    @include m {
      display: block; }
    &-minus,
    &-plus {
      min-width: 49px;
      height: 32px;
      margin-left: 3px;
      padding: 0 10px;
      background: none;
      border-radius: 3px;
      font-size: 14px;
      line-height: 1; }
    &-minus {
      border: 1px solid rgba($red,.5);
      color: $red; }
    &-plus {
      border: 1px solid rgba($green,.5);
      color: $green; } }
  &__dot {
    width: 4px;
    height: 4px;
    margin: 0 10px;
    background: #c9c9c9;
    border-radius: 50%;
    @include m {
      display: none; } }
  &__content {
    margin-bottom: 10px;
    &_toggle {
      @include m {
        height: 220px;
        padding-bottom: 10px;
        font-size: 17px;
        overflow: hidden; } } }
  &__link {
    @include m {
      display: none; } }
  &__params {
    font-size: 13px;
    color: #7E7E7E;
    @include m {
      font-size: 14px;
      &:last-child {
        display: none; } } }
  &__foot {
    position: relative;
    @include clr;
    @include m {
      &:before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 0;
        right: 0;
        height: 30px;
        background-image: linear-gradient(rgba($bg,0), rgba($bg,1) 70%); } } }
  &__foot &__link {
    float: left;
    margin: 9px 30px 0 0; }
  &__vote {
    float: right;
    font-size: 0;
    font-weight: 700;
    @include m {
      float: left; }
    &-title,
    &-btn,
    &-counter {
      display: inline-block;
      vertical-align: middle; }
    &-btn {
      width: 34px;
      height: 34px;
      margin-right: 5px;
      background-color: #f8f8f8;
      border: 1px solid #cfcfcf;
      box-shadow: none;
      border-radius: 3px;
      font-size: 0;
      @include m {
        min-width: 49px;
        padding: 0 10px;
        background: none;
        border-color: $red;
        color: $red; }
      .ico {
        @include m {
          display: none; } } }
    &-btn + &-btn {
      @include m {
        border-color: $green;
        color: $green; } }
    &-title {
      margin-right: 20px;
      font-size: 14px;
      @include m {
        display: none; } }
    &-counter {
      margin-left: 10px;
      font-size: 16px;
      color: $red;
      @include m {
        display: none; }
      &.active {
        color: $green; } }
    &-value {
      display: none;
      font-size: 17px;
      @include m {
        display: inline; } } }
  &__more {
    display: none;
    float: right;
    font-size: 17px;
    color: $blue;
    @include m {
      display: block; }
    .ico {
      margin-left: 5px; } }
  &__comm {
    margin: 25px 0 0 20px;
    padding-left: 25px;
    border-left: 3px solid #95bbce;
    @include m {
      display: none; }
    &-item {
      margin-bottom: 25px; } }
  &__comm &__meta,
  &__comm &__content {
    margin-bottom: 5px; }
  &__l-section {
    width: 714px; }
  &__l-sidebar {
    width: 248px; } }

.opinions {
  font-size: 14px;
  line-height: 1.3;
  &__list {
    margin-bottom: 5px;
    font-size: 0;
    @include m {
      margin: 0 -10px 10px; } }
  &__item {
    display: inline-block;
    position: relative;
    width: 50%;
    margin-bottom: 25px;
    padding-right: 60px;
    box-sizing: border-box;
    font-size: 14px;
    vertical-align: top;
    @include m {
      display: block;
      width: 100%;
      margin-bottom: 4px;
      padding: 10px;
      border: 1px solid $m-border;
      border-width: 1px 0;
      background: #fff;
      font-size: 17px;
      &:after {
        content: '';
        position: absolute;
        top: 15px;
        right: 13px;
        @include sr(arrow-right-black); } } }
  &__meta {
    margin-bottom: 5px;
    @include m {
      padding-right: 20px; } }
  &__title,
  &__rate,
  &__date,
  &__dot {
    display: inline-block;
    vertical-align: middle; }
  &__title {
    font-weight: 700;
    @include m {
      font-size: 17px; } }
  &__rate {
    @include m {
      display: none; }
    &-value {
      margin-left: 4px;
      color: $red;
      font-weight: 700; } }
  &__date {
    color: #7E7E7E;
    @include m {
      margin-left: 4px;
      padding-top: 2px;
      font-size: 14px; } }
  &__dot {
    width: 4px;
    height: 4px;
    margin: 0 10px;
    background: #c9c9c9;
    border-radius: 50%;
    @include m {
      display: none; } }
  &__content {
    margin-bottom: 3px; }
  &__link {
    @include m {
      display: none; }
    .ico {
      display: none;
      @include m {
        display: inline-block; } } } }

.params {
  font-size: 14px;
  line-height: 1.3;
  &__wrap {
    margin-bottom: 20px;
    font-size: 0; }
  &__col {
    display: inline-block;
    width: 50%;
    padding-right: 60px;
    box-sizing: border-box;
    font-size: 14px;
    vertical-align: top;
    @include m {
      display: block;
      width: 100%;
      padding-right: 10px; } }
  &__row {
    margin-bottom: 10px;
    @include clr;
    @include m {
      margin-bottom: 0; } }
  &__type {
    float: left;
    max-width: 180px;
    margin-right: 3px;
    font-weight: 700;
    @include m {
      width: 50%;
      max-width: 50%;
      margin: 0;
      padding-top: 2px; } }
  &__desc {
    float: right;
    width: 220px;
    margin-left: 3px;
    @include m {
      width: 50%;
      margin: 0;
      font-size: 17px; } }
  &__dots {
    padding-top: 11px;
    border-bottom: 1px dotted #afafb0;
    overflow: hidden;
    @include m {
      display: none; } }
  &__group {
    margin-bottom: 30px;
    @include m {
      margin-bottom: 15px; }
    &-title {
      margin-bottom: 15px;
      font-size: 21px;
      font-weight: 700;
      line-height: 1.2;
      @include m {
        margin-bottom: 8px;
        padding-bottom: 4px;
        border-bottom: 1px solid $m-border;
        font-size: 17px; } }
    &-wrap {
      font-size: 14px; } }
  &__group &__desc {
    width: 260px; }
  &__b {
    @include m {
      display: none; } } }

.compare {
  font-size: 14px;
  line-height: 1.3;
  @include m {
    font-size: 17px; }
  &__wrap {
    margin: 0 -20px 25px;
    @include m {
      margin: 0 -10px;
      overflow: auto; } }
  &__table {
    width: 100%;
    margin: 0;
    table-layout: fixed;
    @include m {
      margin-bottom: 5px;
      width: auto; }
    td,
    th {
      padding: 11px 20px;
      border-bottom: 1px solid #E5E5E5;
      vertical-align: top;
      @include m {
        padding: 5px;
        border: none; }
      &:first-child {
        width: 160px; }
      &:nth-child(2) {
        background: #f8f8f8;
        @include m {
          background: none; } } }
    th {
      border: 1px solid #e5e5e5; }
    td {
      @include m {
        font-size: 17px; }
      &:first-child {
        font-weight: 700;
        @include m {
          padding: 8px 5px 5px 10px;
          font-size: 14px; } }
      &:not(:first-child) {
        font-size: 13px; }
      &:nth-child(2) {
        border: 1px solid #e5e5e5; } }
    thead {
      tr:first-child {
        th {
          border: none;
          &:nth-child(2) {
            border: 1px solid #e5e5e5;
            border-top: 3px solid $red;
            border-bottom: none;
            @include m {
              border: none; } } } }
      tr:last-child {
        th {
          border-width: 0 0 1px;
          @include m {
            background: #fff;
            border: 1px solid $m-border;
            border-width: 1px 0; }
          &:nth-child(2) {
            border-width: 0 1px; } } } }
    tbody {
      tr {
        &:last-child {
          @include m {
            display: none; }
          td {
            padding-top: 15px;
            padding-bottom: 0;
            background: none;
            border: none;
            vertical-align: middle; } } } } }
  &__preview {
    height: 140px;
    @include vertical;
    @include m {
      width: 100px;
      height: 70px;
      padding-bottom: 10px; }
    &-img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle; } }
  &__title {
    font-weight: 700;
    &.active {
     color: #000;
     text-decoration: none; } }
  &__red {
    color: $red; }
  &__buy {
    margin-left: 4px;
    text-decoration: underline;
    @include m {
      display: table;
      margin: 5px 0 0 0;
      color: $blue;
      font-weight: 700; } }
  &_white &__table {
    thead {
      tr:last-child {
        th {
          @include m {
            background: $bg; } } } } } }

.questions {
  font-size: 14px;
  line-height: 1.3;
  &__container {
    margin-bottom: 20px;
    @include m {
      margin-bottom: 10px;
      background-color: white;
      border: 1px solid #ebebeb;
      border-radius: 4px 4px 0px 0px; } }
  &__list > &__item {
    margin-bottom: 20px;
    @include m {
      margin: 0 0 0 10px;
      padding: 10px 10px 10px 0;
      border-bottom: 1px solid $m-border; } }
  &__item {
    position: relative;
    @include clr;
    @include m {
      font-size: 17px; } }
  &__ico {
    float: left;
    width: 38px;
    height: 38px;
    margin-right: 10px;
    background-color: #f8f8f8;
    border: 1px solid #cfcfcf;
    border-radius: 50%;
    font-size: 24px;
    font-weight: 700;
    line-height: 38px;
    text-align: center;
    @include m {
      display: none; } }
  &__wrap {
    display: table;
    padding-top: 14px;
    @include m {
      padding: 0; } }
  &__title {
    font-weight: 700;
    @include m {
      padding-right: 20px;
      margin-bottom: 2px;
      &:before {
        content: 'В: '; }
      &:after {
        content: '';
        position: absolute;
        top: 15px;
        right: 13px;
        @include sr(arrow-right-black); } } }
  &__text {
    @include m {
      &:before {
        content: 'О: '; } } }
  &__author {
    margin-bottom: 7px;
    color: #7e7e7e;
    @include m {
      display: none; } }
  &__foot {
    @include m {
      display: none; }
    &-ico,
    &-wrap {
      display: inline-block;
      vertical-align: top; }
    &-ico {
      margin-right: 17px;
      font-size: 0; }
    &-wrap {
      padding-top: 20px; }
    &-title {
      font-weight: 700; } }
  &__more {
    @include m {
      font-size: 15px;
      color: $blue; } }
  &__link {
    display: none;
    position: relative;
    margin-top: -1px;
    padding: 10px 30px 10px 10px;
    border-top: 1px solid $m-border;
    font-size: 17px;
    color: $blue;
    @include m {
      display: block; }
    .ico {
      position: absolute;
      top: 12px;
      right: 12px; } } }

.fresh {
  font-size: 0;
  line-height: 1.3;
  @include m {
    padding: 10px 0;
    background-color: white;
    border: 1px solid $m-border;
    border-radius: 4px; }
  &__item {
    display: inline-block;
    width: 50%;
    margin-bottom: 50px;
    padding-right: 40px;
    box-sizing: border-box;
    font-size: 14px;
    vertical-align: top;
    @include clr;
    @include m {
      display: flex;
      position: relative;
      width: 100%;
      margin: 0;
      padding: 5px 0 10px 10px;
      &:after {
        content: '';
        position: absolute;
        top: 8px;
        right: 13px;
        @include sr(arrow-right-black); } } }
  &__item:last-child {
    @include m {
      padding-bottom: 0; } }
  &__item:last-child &__wrap {
    @include m {
      border: none; } }
  &__preview {
    float: left;
    width: 130px;
    height: 130px;
    padding: 0 20px;
    @include vertical;
    @include m {
      width: 75px;
      height: auto;
      padding: 0 25px 0 0;
      text-align: left;
      flex: 0 0 75px; }
    &-img {
      max-width: 100%;
      max-height: 100%; } }
  &__wrap {
    overflow: hidden;
    @include m {
      padding-right: 30px;
      border-bottom: 1px solid $m-border; } }
  &__title {
    margin-bottom: 3px; }
  &__link {
    font-size: 18px;
    font-weight: 700;
    text-decoration: underline;
    line-height: 1.2;
    @include m {
      display: block;
      color: $blue; } }
  &__content {
    padding-top: 5px;
    @include m {
      padding: 0 0 10px 0;
      font-size: 17px; } } }

.shop {
  &__item {
    margin-bottom: 10px;
    padding: 20px;
    border: 1px solid $border;
    @include clr; }
  &__col {
    float: left;
    &_left {
      width: 90px;
      margin-right: 20px; }
    &_center {
      width: 395px;
      margin-right: 20px; }
    &_right {
      width: 160px; } }
  &__preview {
    height: 100px;
    @include vertical;
    &-img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle; } }
  &__prod {
    margin-bottom: 15px;
    padding-top: 5px;
    font-size: 18px;
    font-weight: 700;
    &-link {
      @include underline(#000);
      color: #000;
      word-wrap: break-word; } }
  &__meta {
    margin-bottom: 5px; }
  &__stars,
  &__dot,
  &__title {
    display: inline-block;
    vertical-align: middle; }
  &__title {
    text-transform: uppercase; }
  &__stars {
    font-size: 13px;
    &-title,
    &-list,
    &-value {
      display: inline-block;
      vertical-align: middle; }
    &-list {
      margin: -1px 5px 0;
      font-size: 0; }
    &-value {
      font-weight: 700;
      text-transform: uppercase; } }
  &__dot {
    width: 4px;
    height: 4px;
    margin: 0 10px;
    background: #c9c9c9;
    border-radius: 50%; }
  &__guarantee {
    margin-bottom: 15px; }
  &__fav {
    &-ico,
    &-title {
      display: inline-block;
      vertical-align: middle; }
    &-ico {
      margin-right: 10px; }
    &-title {
      @include underline($blue);
      color: $blue; } }
  &__price {
    margin-bottom: 10px;
    padding-top: 3px;
    font-size: 20px;
    font-weight: 700;
    &-link {
      @include underline($gray); } }
  &__button {
    margin-bottom: 10px; }
  &__details {
    display: table;
    &-item {
      display: table-row; }
    &-ico,
    &-title {
      display: table-cell;
      padding-bottom: 5px;
      vertical-align: middle; }
    &-ico {
      padding-right: 8px;
      text-align: center;
      font-size: 0; }
    &-title {
      font-size: 13px;
      color: $gray; } } }

.location {
  margin-bottom: 20px;
  padding: 0 25px;
  line-height: 1.1;
  &__title,
  &__city {
    display: inline-block; }
  &__title {
    margin-right: 10px; }
  &__city {
    border-bottom: 1px dashed;
    &:hover {
      border-color: transparent; } } }

.offers {
  margin-bottom: 20px;
  .load-more {
    margin-top: 10px; }
  &__head {
    margin-bottom: 10px;
    @include clr;
    &-link {
      float: right;
      margin-top: 2px;
      @include underline($red); }
    &-title {
      display: table;
      font-size: 21px;
      font-weight: 700; } }
  &__body {
    background: #fcfcfc;
    border: 1px solid $border; }
  &__item {
    display: none;
    padding: 20px;
    border-bottom: 1px solid $border;
    &:last-child {
      border: none; } }
  &__preview {
    float: left;
    width: 120px;
    height: 80px;
    margin-right: 20px;
    @include vertical;
    &-img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
      font-size: 14px; } }
  &__name {
    position: relative;
    padding-top: 1px;
    font-size: 20px;
    color: rgba(#000,.8);
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    margin-top: -60px;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 20px;
      background: linear-gradient(to right, rgba(#fff,.0), rgba(#fff,1)); }
    a {
      color: rgba(#000,.8); } }
  &__wrap {
    overflow: hidden; }
  &__title {
    margin-bottom: 7px;
    font-weight: 700;
    &-link {
      @include underline($gray);
      color: #000; } }
  &__shop {
    margin-bottom: 3px;
    font-weight: 700; }
  &__details {
    margin-bottom: 7px;
    &-ico {
      float: left;
      margin: 6px 7px 0 -7px;
      font-size: 0; }
    &-text {
      display: table;
      padding-top: 1px;
      font-weight: 700;
      color: $gray; } }
  &__foot {
    @include clr; }
  &__button {
    float: right;
    margin-left: 20px; }
  &__price {
    display: table;
    padding-top: 4px;
    font-size: 19px;
    color: $red;
    &-value {
      font-weight: 700; } } }

.row {
  margin: 0 -10px;
  @include clr;
  &__col {
    float: left;
    width: 50%;
    padding: 0 10px;
    box-sizing: border-box; } }

.post {
  min-height: 120px;
  margin-bottom: 15px;
  padding-bottom: 16px;
  border: solid #e1e4e7;
  border-width: 0 0 1px;
  box-sizing: border-box;
  line-height: 1.3;
  @include clr;
  @include m {
    min-height: auto; }
  &__preview {
    display: block;
    float: left;
    width: 160px;
    text-align: center; }
  &__pic {
    display: block;
    max-width: 100%;
    margin: 0 auto;
    border-radius: 2px;
    text-align: center; }
  &__wrap {
    margin-left: 180px; }
  &__title {
    margin-bottom: 7px;
    font-size: 14px;
    line-height: 1.3; }
  &__text {
    font-size: 12px; }
  &__meta {
    margin: 7px 0 0;
    padding: 7px 0 0;
    border-top: 1px dotted #e1e4e7;
    font-size: 11px;
    color: #808080;
    @include clr; }
  &__link {
    color: #7e7e7e; }
  &__time {
    color: #383839; }
  &__year {
    color: #989898; }
  &__category {
    color: #4f4f4f; }
  &__views {
    display: inline-block;
    position: relative;
    padding-left: 18px;
    color: #989898;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 14px;
      height: 14px;
      background: transparent url(../img/sprite.png) no-repeat -220px 0; } }
  &__more {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #e1e4e7;
    box-shadow: 0 1px 0 0 #fff inset; }
  &_narrow {
    margin: 15px 0 0;
    padding: 16px 0 0;
    border-width: 1px 0 0; }
  &_narrow &__preview {
    float: none;
    width: 100%;
    margin-bottom: 15px; }
  &_narrow &__wrap {
    margin: 0; }
  &_narrow &__category {
    float: left;
    width: 50%; }
  &_narrow &__views {
    float: right;
    max-width: 50%;
    text-align: right; }
  &_bg {
    padding: 20px;
    margin: 0 0 20px;
    background: #f2f2f2;
    border: none; }
  &_bg &__meta {
    border-color: #c5cbd0; }
  &_border {
    min-height: 130px;
    padding: 15px;
    border: 1px solid #e1e4e7; }
  &_border &__preview {
    width: 90px; }
  &_border &__wrap {
    margin-left: 110px; }
  &_news {
    &:last-child {
      padding-bottom: 0;
      border-width: 0 0 0; }
    @include m {
      margin-bottom: 4px;
      padding: 20px 10px;
      background: #FFF;
      border: solid $m-border;
      border-width: 1px 0; } }
  &_news &__preview {
    @include m {
      width: 110px; } }
  &_news &__wrap {
    @include m {
      margin-left: 125px; } }
  &_news &__title {
    @include m {
      margin: -3px 0 4px;
      font-size: 17px;
      line-height: 1.2;
      a {
        color: #000;
        text-decoration: underline; } } }
  &_news &__text {
    @include m {
      font-size: 14px; } }
  &_news &__meta {
    @include m {
      padding: 0;
      border: none;
      font-size: 14px; } }
  &_news &__time,
  &_news &__year {
    @include m {
      color: #808080; } } }

.pager {
  @include clr;
  &__list {
    float: left;
    margin-right: 28px;
    font-size: 0; }
  &__link,
  &__prev,
  &__next {
    display: inline-block;
    vertical-align: middle; }
  &__link {
    min-width: 12px;
    height: 16px;
    margin: 0 3px;
    padding: 0 2px;
    border: 2px solid #e1e4e7;
    border-radius: 50%;
    color: #989898;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-decoration: none;
    &:hover,
    &.active {
      border-color: #bd0217;
      background-color: #bd0217;
      color: #fff; } }
  &__prev,
  &__next {
    padding: 1px 2px;
    &:before {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      background-image: url(../img/sprite.png);
      background-repeat: no-repeat; }
    &:hover {
      opacity: .6; } }
  &__prev {
    margin-right: 9px;
    &:before {
      background-position: -236px -32px; }
    &:hover {
      &:before {
        background-position: -236px -48px; } } }
  &__next {
    margin-left: 9px;
    &:before {
      background-position: -252px -32px; }
    &:hover {
      &:before {
        background-position: -252px -48px; } } }
  &__all {
    float: left;
    padding: 2px 0; } }

.switch {
  display: block;
  &__input {
    display: none; }
  &__input:checked + &__content {
    &:after {
      display: block; } }
  &__content {
    display: block;
    position: relative;
    padding: 2px 0 1px 29px;
    font-size: 12px;
    color: #989898;
    &:before,
    &:after {
      content: '';
      position: absolute; }
    &:before {
      top: 0;
      left: 0;
      width: 19px;
      height: 19px;
      border: 2px solid #e1e4e7;
      border-radius: 50%; }
    &:after {
      display: none;
      top: 6px;
      left: 6px;
      width: 7px;
      height: 7px;
      background: #e1e4e7;
      border-radius: 50%; } }
  &_red &__input:checked + &__content {
    color: $red;
    &:before {
      border-color: $red; }
    &:after {
      background: $red; } }
  &_purple &__input:checked + &__content {
    color: #606b84;
    &:before {
      border-color: #606b84; }
    &:after {
      background: #606b84; } }
  &_pink &__input:checked + &__content {
    color: #df7b81;
    &:before {
      border-color: #df7b81; }
    &:after {
      background: #df7b81; } }
  &_blue &__input:checked + &__content {
    color: #63a7d7;
    &:before {
      border-color: #63a7d7; }
    &:after {
      background: #63a7d7; } }
  &_brown &__input:checked + &__content {
    color: #ac9371;
    &:before {
      border-color: #ac9371; }
    &:after {
      background: #ac9371; } } }

.sort-categories {
  margin-bottom: 40px;
  .switch {
    margin-bottom: 15px;
    &:first-child {
      padding-bottom: 15px;
      border-bottom: 1px solid #e1e4e7; } } }

.title-border {
  padding: 10px 0;
  border-bottom: 4px solid #750308;
  font-family: 'proxima-nova', sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  color: #343434;
  letter-spacing: 0.5pt; }

.social-block {
  &__facebook,
  &__vkontakte,
  &__twitter {
    border: 1px solid #d2d2d2;
    border-top: none; }
  &__facebook {
    height: 172px;
    overflow: hidden; }
  &__vkontakte {
    height: 187px;
    padding-top: 10px; }
  &__twitter {
    height: 30px;
    padding-top: 10px;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.06); } }

.article {
  max-width: 700px;
  margin-bottom: 40px;
  line-height: 1.43;
  @include clr;
  h2 {
    border: 0;
    margin-bottom: 10px;
    padding-bottom: 0;
    clear: none; }
  h3 {
    margin-bottom: 7px;
    font-size: 17px;
    line-height: 1.2;
    clear: left;
    @include m {
      margin-bottom: 7px; } }
  p + h3 {
    margin-top: 25px; }
  p {
    line-height: 1.43;
    clear: left;
    margin-bottom: 10px;
    @include m {
      font-size: 17px;
      &:after {
        content: " ";
        display: table;
        clear: both; } } }
  ul {
    clear: left; }
  ul li {
    position: relative;
    margin: 0;
    padding: 0 0 6px 24px;
    &:before {
      content: '';
      position: absolute;
      top: 8px;
      left: 0;
      width: 11px;
      height: 1px;
      background: #000;
      opacity: .5;
      @include m {
        top: 10px; } } }
  li {
    margin-left: 0;
    padding-bottom: 1px;
    text-indent: 0; }
  a {
    color: #bd0217;
    text-decoration: underline;
    &:hover {
      color: #bd0217;
      text-decoration: none; }
    &:visited {
      color: #3432d1; } }
  table {
    font-size: 80%;
    padding: 8px;
    border-collapse: collapse;
    @include m {
      overflow-x: auto;
      display: block;
      width: 100% !important; }
    &.custom-table {
      border: 0;
      font-weight: 700;
      tr {
        > td {
          border: 0;
          border-top: 1px solid #e2e2e2;
          background: #fff;
          padding: 6px; }

        &:first-child {
          border: 0;
          border-top: 1px solid #cdcdcd; }

        &:hover td {
          border: 0;
          background-color: #f8f6ed; } } }
    tr {
      > td {
        border: 1px solid #558fa3;
        padding: 6px;
        &:nth-child(odd) {
          background: #98d0f1; }
        &:nth-child(even) {
          background: #c3e0f2; } }
      > th {
        &:nth-child(odd) {
          background: #98d0f1; }
        &:nth-child(even) {
          background: #c3e0f2; } } } }
  img:not(.after_before) {
    @include m {
      float: none !important;
      width: 100% !important;
      height: auto !important;
      margin-left: 0 !important; } }
  .thumbnail-image {
    @include m {
      float: none !important;
      width: 100% !important;
      height: auto !important; } }
  iframe {
    max-width: 100%; }
  &-characteristic {
    color: $red; }
  .thumbnail {
    padding-bottom: 0;
    cursor: pointer;
    &:before {
      content: "";
      display: block;
      position: absolute;
      right: 10px;
      bottom: 30px;
      width: 17px;
      height: 17px;
      background: transparent url(../img/sprite.png) no-repeat 0 0;
      background-position: -90px -25px;
      cursor: pointer; }
    &-image {
      display: block;
      position: relative;
      width: auto;
      border-radius: 2px;
      overflow: hidden;
      img {
        display: block;
        margin: 10px 0 20px;
        border-radius: 2px;
        @include m {
          margin: 10px 0!important; } } } } }

.article + .buy {
  margin-top: -20px; }

.title-middle,
.title-small {
  color: #222;
  line-height: 1.2;
  sup {
    margin-left: 4px; } }

.title-middle {
  font-size: 20px; }

.title-small {
  font-size: 16px; }

.wrap-post {
  &_news,
  &_wide_m {
    @include m {
      margin: 0 -10px;
      padding-bottom: 10px;
      background: $bg; } } }

.delimiter-title {
  border-top: 1px solid #D2D2D2;
  float: left;
  width: 100%;
  padding: 14px 0 10px;
  @include m {
    border: none; } }

.wrap-borders {
  position: relative;
  margin: 0 -20px 60px;
  padding: 15px 20px 20px;
  border: solid #e1e4e7;
  border-width: 1px 0;
  background: #fff;
  @include m {
    margin: 0 -10px;
    padding: 0 10px 30px;
    border: none; }
  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: -10px;
    width: 0;
    height: 0;
    border: solid;
    border-color: #989898 transparent transparent;
    @include m {
      display: none; } }
  &:before {
    left: 0;
    border-width: 10px 0 0 10px; }
  &:after {
    right: 0;
    border-width: 10px 10px 0 0; }
  .title-middle {
    margin-bottom: 30px;
    @include m {
      margin-bottom: 10px; } } }

.comments {
  margin: 0 0 40px;
  border: 1px solid #e1e4e7;
  font-size: 13px;
  line-height: 1.3;
  &__wrap {
    position: relative;
    padding: 16px 20px 20px;
    box-shadow: inset 0 90px 60px -60px rgba(0,0,0,0.03);
    color: #4f4f4f;
    font-size: 12px;
    line-height: 1.2; }
  &__add {
    max-width: 460px;
    &_reply {
      display: none;
      padding: 16px 20px 20px 0; } }
  &__field {
    margin-bottom: 20px; }
  &__list {
    margin-top: 30px; }
  &__item {
    padding-top: 27px;
    border-top: 1px solid #e1e4e7;
    @include clr;
    &:first-child {
      padding: 0;
      border: none; } }
  &__author {
    float: right;
    width: 180px; }
  &__ava {
    float: left;
    width: 30px;
    height: 30px;
    margin-right: 20px; }
  &__pic {
    max-width: 100%; }
  &__name {
    display: table; }
  &__msg {
    margin-right: 200px;
    padding: 0 0 7px; }
  &__meta {
    margin-right: 200px;
    padding: 7px 0 25px;
    border-top: 1px dotted #e1e4e7;
    font-family: Georgia, Times New Roman, Times, serif;
    font-size: 11px; }
  &__time,
  &__date {
    &:after {
      content: '/';
      margin: 0 10px; } }
  &__reply {
    padding: 27px 0 0 40px;
    border-top: 1px solid #e1e4e7; }
  &__pager {
    margin-top: 20px; } }

.offers-message {
  display: none;
  padding: 21px;
  text-align: center;
  font-weight: bold;
  border-top: 1px solid #D2D2D2;
  border-left: 1px solid #D2D2D2;
  border-right: 1px solid #D2D2D2;
  border-bottom: 1px solid #D2D2D2;
  @include m {
    font-weight: normal !important;
    border: 0 !important; } }

.autocomplete-suggestions {
    border: 1px solid #D2D2D2;
    background: #FFF;
    overflow: auto; }

.autocomplete-suggestion {
    padding: 8px 5px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden; }

.autocomplete-selected {
    background: #f8f8f8; }

.autocomplete-suggestions strong {
    font-weight: normal;
    color: $red; }

.autocomplete-group {
    padding: 2px 5px;
    strong {
        display: block;
        border-bottom: 1px solid #D2D2D2; } }

.head__select {
  float: left;
  position: relative;
  width: auto;
  min-width: 166px;
  max-width: 240px;
  height: 31px;
  padding: 0 16px 0 12px;
  background: url(../img/arrows-select.png) 100% 50% no-repeat #f7f7f7;
  box-shadow: none;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  font-size: 17px;
  -webkit-appearance: none;
  -moz-appearance: none; }

.bannerCharacteristicSide {
  float: right; }

.bannerSide {
  margin-top: 15px;
  text-align: right; }

.bannerBottom {
  margin-top: auto; }

.bannerTopCommon {
  text-align: center; }

.bannerBuySide {
  margin-top: 15px;
  display: none; }
.rigth {
  display: flex;
  flex-direction: row-reverse;
  margin-left: 30px; }

.banner_middle_overview {
  margin-bottom: 25px; }

.cssload-button {
  @include m {
    padding-top: 6px; } }

.cursor-default {
  &:link {
    cursor: default; } }

.stars__out {
  position: relative;
  z-index: 1; }

.stars__in {
  position: absolute;
  z-index: 2;
  display: block;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  width: 0; }

.without-breadcrumbs {
  margin-top: 25px;
  @include m {
    margin-top: 0px; } }

.grayscale {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='saturate' values='0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  filter: gray;
  &.grayscale-fade {
    transition: filter .5s; }
  &.grayscale-off, &.grayscale-fade:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0); }
  &.grayscale-replaced {
    -webkit-filter: none;
    filter: none;
    > svg {
      -webkit-transition: opacity .5s ease;
      transition: opacity .5s ease;
      opacity: 1; }
    &.grayscale-off > svg, &.grayscale-fade:hover > svg {
      opacity: 0; } } }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .grayscale.grayscale-fade {
    -webkit-transition: -webkit-filter .5s;
    transition: -webkit-filter .5s; } }

.ask {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: #e1e4e7;
  border-radius: 50%;
  font-family: Arial;
  font-size: 10px;
  color: #989898;
  line-height: 14px;
  text-align: center;
  &:hover {
    color: $red; } }

.ttip {
  display: inline-block;
  position: relative;
  cursor: pointer;
  &:before {
    display: none;
    content: attr(data-tooltip);
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 10px;
    padding: 3px 5px;
    border: 1px solid #e1e4e7;
    background: #f2f2f2;
    box-shadow: 0 2px 2px rgba(#000,.1);
    font-size: 12px;
    font-weight: 400;
    color: #000;
    white-space: nowrap;
    text-align: left;
    z-index: 99; }
  &:hover {
    &:before {
      display: block; } }
  &_lg {
    &:before {
      width: 170px;
      white-space: normal; } } }

.rating {
  margin-bottom: 60px;
  font-size: 12px;
  line-height: 1.3;
  &__subсategory {
    padding-left: 0;
    font-weight: 700;
    font-size: 110%;
    cursor: pointer; }
  &__labels {
    margin-bottom: 20px;
    &-item,
    &-title {
      display: inline-block;
      vertical-align: middle; }
    &-item {
      margin-right: 20px; } }
  &__container {
    position: relative; }
  &__head {
    margin-bottom: -1px;
    background: #FFF; }
  &__head &__table {
    position: relative;
    z-index: 2; }
  &__head.fixed &__table {
    position: fixed;
    top: 0;
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      height: 10px;
      background: linear-gradient(to bottom, rgba(0,0,0,.15), rgba(0,0,0,0));
      pointer-events: none; } }
  &__head.abs {
    position: static; }
  &__head.abs &__table {
    position: absolute;
    top: auto;
    bottom: 0; }
  &__table {
    width: 720px;
    margin: 0;
    background: #FFF;
    th,
    td {
      border: 1px solid #e1e4e7;
      box-sizing: border-box; }
    th {
      padding: 5px;
      cursor: pointer;
      &:first-child {
        cursor: default; } }
    th[rowspan] {
      min-width: 19px;
      padding: 10px 2px 2px;
      vertical-align: bottom;
      text-align: center; }
    td {
      padding: 10px 5px;
      vertical-align: middle;
      text-align: center;
      &:nth-child(1) {
        width: 195px;
        text-align: left; }
      &:nth-child(2) {
        width: 60px;
        text-align: right; }
      &:nth-child(3) {
        width: 90px; } }
    &_fixed {
      table-layout: fixed; } }
  &__option {
    display: block;
    writing-mode: vertical-rl;
    white-space: nowrap;
    transform: rotate(180deg);
    margin: 0 auto 6px; }
  &__gray {
    background: #f2f2f2; }
  &__form {
    padding: 5px;
    box-sizing: border-box;
    font-weight: 400; }
  &__row {
    margin-bottom: 10px;
    @include clr; }
  &__select {
    float: right;
    width: auto;
    height: 30px;
    margin-left: 10px;
    background: #FFF;
    box-shadow: none;
    border: 1px solid #e1e4e7;
    box-sizing: border-box;
    font-family: Helvetica Neue,Segoe UI,serif; }
  &__search {
    position: relative;
    overflow: hidden; }
  &__search &__input {
    padding-right: 35px; }
  &__input[type='text'] {
    width: 100%;
    height: 30px;
    box-shadow: none;
    box-sizing: border-box;
    border: 1px solid #e1e4e7;
    border-radius: 5px;
    padding: 3px 10px;
    font-family: Helvetica Neue,Segoe UI,serif;
    font-size: 13px; }
  &__btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    background: none;
    border: none;
    outline: none;
    font-size: 0;
    &:before {
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      background: url(../img/sprite.png) no-repeat -188px -16px; } }
  &__type {
    margin-bottom: 5px;
    font-weight: 700; }
  &__range {
    float: right;
    font-size: 0;
    white-space: nowrap;
    &-label,
    &-field {
      display: inline-block;
      margin-left: 10px;
      vertical-align: middle; }
    &-label {
      font-size: 12px; } }
  &__range &__input {
    width: 65px;
    text-align: center; }
  &__slider {
    position: relative;
    padding: 11px 6px 0;
    overflow: hidden;
    .slider {
      display: block;
      position: relative;
      height: 6px;
      border: 1px solid #e1e4e7;
      background: #FFF;
      border-radius: 2px; }
    a {
      position: absolute;
      top: -3px;
      width: 12px;
      height: 12px;
      margin-left: -6px;
      background: $red !important;
      border-radius: 2px;
      cursor: pointer;
      div {
        width: 12px;
        height: 12px; } }
    &-labels {
      padding-top: 5px;
      @include clr; }
    &-start {
      float: left; }
    &-finish {
      float: right; } }
  &__foot {
    margin: 0 -10px;
    padding: 10px 10px 0;
    border-top: 1px solid #e1e4e7;
    text-align: right;
    display: flex;
    justify-content: space-between; }
  &__filter {
    user-select: none;
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    cursor: pointer; }
  &__refresh {
    height: 30px;
    padding-right: 10px;
    background: #fff;
    border: 1px solid #e1e4e7;
    border-radius: 5px;
    outline: none;
    font-family: Helvetica Neue,Segoe UI,serif;
    font-size: 100%;
    color: $red;
    padding-left: 6px;
    &:before {
      content: '';
      display: inline-block;
      position: relative;
      top: -1px;
      width: 14px;
      height: 14px;
      margin-right: 5px;
      background: url(../img/sprite.png) no-repeat -312px -80px;
      vertical-align: middle; } }
  &__check {
    float: left;
    margin: 0 10px 0 0; }
  &__product {
    display: block;
    max-width: 170px;
    padding-top: 1px;
    overflow: hidden;
    &:hover {
      text-decoration: underline; } }
  &__percent {
    position: relative;
    height: 12px;
    border: 1px solid #989898;
    background: #e1e4e7;
    border-radius: 2px; }
  &__percent_black &__progress {
    border-color: #383839;
    background: #383839; }
  &__progress {
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    padding: 0 2px;
    border: 1px solid #bd0217;
    background: $red;
    box-sizing: border-box;
    border-radius: 2px;
    color: #FFF;
    font-size: 10px;
    text-align: right;
    line-height: 12px; }
  &__star {
    margin: 0; }
  &__left {
    text-align: left; }
  &__right {
    text-align: right; }
  &__center {
    text-align: center; }
  &__compare {
    display: inline-block;
    border-bottom: 1px dashed #989898;
    color: #989898;
    line-height: 1;
    &:hover {
      border: none; } }
  &__ttip {
    display: block;
    margin: 0 auto 2px; }
  &__static {
    display: table;
    width: 345px;
    margin: -1px -1px 0 -1px;
    @include ch {
      margin: -1px -1px -1px 0; }
    th {
      &:nth-child(2) {
        width: 60px; }
      &:nth-child(3) {
        width: 90px; } } } }

.juxtapose {
  position: relative;
  overflow: hidden;
  line-height: 0;
  margin: 0;
  padding: 0;
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;
  max-width: 100%;
  * {
    box-sizing: inherit;
    &:before, &:after {
      box-sizing: inherit; } }
  img {
    max-width: none; }
  > {
    img {
      width: 100%!important;
      display: block; }
    div:first-child {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 50%;
      overflow: hidden;
      z-index: 3; } } }

.cocoen__drag {
  &:before, &:after {
    content: " ";
    display: block;
    background: white;
    position: absolute;
    z-index: 30;
    -webkit-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
    -moz-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
    box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5); }
  &:before, &:after {
    width: 3px;
    height: 9999px;
    left: 50%;
    margin-left: -1.5px; }
  height: 38px;
  width: 38px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -22px;
  margin-top: -22px;
  border: 3px solid white;
  -webkit-border-radius: 1000px;
  -moz-border-radius: 1000px;
  border-radius: 1000px;
  -webkit-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  z-index: 40;
  cursor: pointer;
  &:before {
    bottom: 50%;
    margin-bottom: 22px;
    -webkit-box-shadow: 0 3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
    -moz-box-shadow: 0 3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
    box-shadow: 0 3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5); }
  &:after {
    top: 50%;
    margin-top: 22px;
    -webkit-box-shadow: 0 -3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
    -moz-box-shadow: 0 -3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
    box-shadow: 0 -3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5); } }

.cocoen__left-arrow, .cocoen__right-arrow {
  width: 0;
  height: 0;
  border: 6px inset transparent;
  position: absolute;
  top: 50%;
  margin-top: -6px; }

.cocoen__left-arrow {
  border-right: 6px solid white;
  left: 50%;
  margin-left: -17px; }

.cocoen__right-arrow {
  border-left: 6px solid white;
  right: 50%;
  margin-right: -17px; }

.cocoen__label-first, .cocoen__label-last {
  font-size: 1em;
  padding: .25em .75em;
  position: absolute;
  display: inline-block;
  top: 0;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  white-space: nowrap;
  line-height: 18px;
  vertical-align: middle;
  top: 0; }

.cocoen__label-first {
  left: 0; }

.cocoen__label-last {
  right: 0; }

.rate__text {
  margin-top: 4px;
  @include m {
    display: inline-block;
    padding-right: 40px; } }
.rate__text__catalog {
  margin-bottom: auto; }
.red-text {
  color: $red; }

.ymarket-affiliate-widget {
  min-height: 136px;
  margin-bottom: 15px;
  @include d {
    border: 1px solid #ffdb4d; }
  position: relative;
  .cssload-container {
    position: absolute;
    display: block;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%); }
  iframe {
    position: relative;
    z-index: 2;
    background: #ffffff; } }

.contacts {
  .grid4 {
    min-height: auto !important; }
  .clmnDotted {
    height: auto !important; } }

.center {
  &.content {
    &-wrapper {
      width: 1022px;
      padding: 0 20px;
      @include m {
        width: auto;
        padding: 0 10px; } } } }

h2.title {
  margin: 0 0 15px; }

.btn {
  &__old {
    display: inline-block;
    margin: 0 7px 10px 0;
    background: none;
    font-family: Open Sans Bold,Arial,serif;
    letter-spacing: 0;
    vertical-align: baseline;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
    border-radius: 2px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0 20px;
    font-size: 10px;
    line-height: 29px; }
  &__slider {
    display: inline-block; } }

input.gsc-search-button-v2 {
  width: auto !important;
  height: auto !important; }

.pl10 {
  @include m {
    padding-left: 10px; } }

.static-content {
  @include m {
    margin-top: 25px; } }

.m {
  &__flex-column {
    @include m {
      display: flex;
      flex-direction: column; } } }
